<template>
    <div
    :style="verticalHeight"
    class="scrollable"
    >
        <!-- <div v-if="maintenance" class="maintenance-section">
            <div>
                <div>
                    <font-awesome-icon :icon="['fas', 'tools']"/>
                </div>
                <div>
                    Due to maintenance this indicator is not available right now, service will resume on the 28th Feb 2023. We apologise for the inconvenience.
                </div>
            </div>
        </div> -->
        <div>
        <table class="irpt">
            <thead>
                <tr>
                    <th>
                    </th>
                    <th>
                        <a href="javascript:void(0);" @click="sortData('central_bank')"> Central Bank
                            <span v-if="sorting.label === 'central_bank'">
                                <font-awesome-icon v-if="sorting.orderBy === 'desc'" :icon="['fa', 'chevron-down']"/>
                                <font-awesome-icon v-else :icon="['fa', 'chevron-up']"/>
                            </span>
                        </a>
                    </th>
                    <th class="from-xs">
                        <a href="javascript:void(0);" @click="sortData('next_expected_move')">Next Move
                            <span v-if="sorting.label === 'next_expected_move'">
                                <font-awesome-icon v-if="sorting.orderBy === 'desc'" :icon="['fa', 'chevron-down']"/>
                                <font-awesome-icon v-else :icon="['fa', 'chevron-up']"/>
                            </span>
                        </a>
                    </th>
                    <th class="from-sm">
                        <a href="javascript:void(0);" @click="sortData('change_by')">Change By
                            <span v-if="sorting.label === 'change_by'">
                                <font-awesome-icon v-if="sorting.orderBy === 'desc'" :icon="['fa', 'chevron-down']"/>
                                <font-awesome-icon v-else :icon="['fa', 'chevron-up']"/>
                            </span>
                        </a>
                    </th>
                    <th class="from-xs">
                        <a href="javascript:void(0);" @click="sortData('probability')">Probability
                            <span v-if="sorting.label === 'probability'">
                                <font-awesome-icon v-if="sorting.orderBy === 'desc'" :icon="['fa', 'chevron-down']"/>
                                <font-awesome-icon v-else :icon="['fa', 'chevron-up']"/>
                            </span>
                        </a>
                    </th>
                    <th class="from-xs">
                        <a href="javascript:void(0);" @click="sortData('next_meeting_date')">Next Meeting
                            <span v-if="sorting.label === 'next_meeting_date'">
                                <font-awesome-icon v-if="sorting.orderBy === 'desc'" :icon="['fa', 'chevron-down']"/>
                                <font-awesome-icon v-else :icon="['fa', 'chevron-up']"/>
                            </span>
                        </a>
                    </th>
                    <th class="from-md">
                        <a href="javascript:void(0);" @click="sortData('current_rate')">Current Rate
                            <span v-if="sorting.label === 'current_rate'">
                                <font-awesome-icon v-if="sorting.orderBy === 'desc'" :icon="['fa', 'chevron-down']"/>
                                <font-awesome-icon v-else :icon="['fa', 'chevron-up']"/>
                            </span>
                        </a>
                    </th>
                </tr>
            </thead>
            <tbody>
                <interestrate-row
                :styleData="styleData"
                v-for="bank in banks"
                :key="bank.id"
                :bank="bank"
                :lastUpdatedAt="updatedAt"
                :isLocked="isLocked"
                :impID="details.implementation_id"
                ></interestrate-row>
            </tbody>
        </table>
        <div class="last-updated-at">Last updated: <span>{{ humanizedUpdatedAt }}</span></div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import InterestrateRow from './components/InterestRate/InterestrateRow.vue';
import ContentLockedMixin from '../../mixins/contentLockedMixin';
import IrptMixin from '../../mixins/irptMixin';


const dashboards = createNamespacedHelpers('dashboard');

export default {
    props: ["details"],
    mixins: [IrptMixin, ContentLockedMixin],
    components: {
        InterestrateRow,
    },
    computed:{
        ...dashboards.mapState(['fullscreenId']),
    },
    
    
}
</script>

<style lang="scss" scoped>
    .scrollable {
        overflow-y: auto;

        @include scrollbarDark;
    }
</style>

<style lang="scss">
    .data-table {

        &.interest-rate-table {

            & .data-table-body {

                &.main-body {
                    border: none;
                }
            
                & .data-table-row {
                    background-color: $widget-faded-background;
                }
            }

            & .data-table--cell {
                border-right: none;
            }

            & .data-table--cell a {
               
                color: $input-color-filter;
                text-decoration: none;
            }

            // .main-body {
            //     min-height: 350px;
            // }

            & .data-table-title {
                display: none;
            }
            &.desktop {

                & .data-table-header {
                    border: none;
                    @include H7;
                    color: $input-color-filter;

                    & .data-table-row {
                        background-color: transparent; //!important;
                        
                    }
                }

                & .data-table-row {
                    display: flex;

                    &-subrows--row__header {


                        & > div {

                            &:nth-child(2) {
                                text-align: left;
                                padding-left: 7.5px;
                            }

                            &:nth-child(3),
                            &:nth-child(4) {
                                text-align: right;
                                padding-right: 10px;
                            }
                        }
                    }



                }

                // & .subtable {

                //     & .data-table-row {
                //         display: flex;
                //     }
                //     & .data-table-row-main {
                //         display: grid;
                //         grid-template-columns: 3fr 3fr 2fr 2fr 2fr;
                //     }
                // }


            }

            & .row-title-label {
                text-decoration: underline;
            }

            // &.mobile {
            //     & .data-table-row {
            //         display: flex;

            //         &-subrows--row__header {
            //             // display: grid;
            //             // grid-template-columns: 1.5fr 3fr 3fr 1.5fr;

            //             & .data-table--cell {
            //                 border-right: none;
            //                 // text-align: center;
            //             }
            //         }

            //     }
            // }

            & .data-table--cell:last-child {
                border-right: none!important;
            }
        }

    }

    .irpt {
        width: 100%;
        & thead {

            & th {
                line-height: 10px;
                text-align: left;
                 display: flex;

                & a {
                    font-size: 10px;
                    color: $input-color-filter!important;
                    text-decoration: none!important;
                    text-transform: uppercase;
                    letter-spacing: 0.1px!important;
                    
                    
                }
            }
            
            
        }

        & tbody {

            & tr {
                line-height: 10px;
                background-color: $widget-faded-background;
                border-bottom: 1px solid $separator-line-color;

                & td {
                    padding: 0;
                    align-items: center;
                    font-size: 12px;
                    color: $input-color;
                    display: flex;
                }
            }
            
            
        }

        & .row-title-label {
            cursor: pointer;
            width: 100%;
            border: none;
            outline: none;
            background-color: transparent;
            color: $input-color;
            display: flex;
            text-align: left;
            padding: 0;
            font-size: 12px;
            font-family: Poppins;
            
            &-name {
                text-decoration: underline;
            }
        }
    }

    .irpt .flag {
        position: absolute;
        left: 50%;
        transform: translateX(-50%) scale(0.4)!important;
    }
    .irpt .flag.small-flag {
        transform: translateX(-50%) scale(0.25)!important;
    }
    .small-flag {
        margin: 0!important;
    }

    .row-title-label-name {
        width: 100%;
    }

    .irpt thead tr,
    .irpt tbody tr {
        display: grid;
        grid-template-columns: 15% auto;
    }

    .size-xs .irpt thead tr,
    .size-xs .irpt tbody tr {
        display: grid;
        grid-template-columns: 10% auto 25% 20% 25%;
    }

    .size-sm .irpt thead tr,
    .size-sm .irpt tbody tr {
        display: grid;
        grid-template-columns: 10% auto 16% 16% 16% 22%;
    }

    .size-md .irpt thead tr,
    .size-md .irpt tbody tr {
        display: grid;
        grid-template-columns: 80px auto 110px 100px 100px 140px 120px;
    }

    .last-updated-at {
        padding: 0 5px;
        font-size: 12px;
        color: $input-color-filter;

        & span {
            font-weight: 300;
        }
    }

    .size-sm .last-updated-at {
        text-align: right;
    }

    .irpt .from-xs,
    .irpt .from-sm,
    .irpt .from-md,
    .irpt .from-lg {
        display: none!important;
    }
    .size-xs .irpt .from-xs {
        display: flex!important;
        justify-content: center;
    }
    .size-sm .irpt .from-sm {
        display: flex!important;
        justify-content: center;
    }
    .size-sm .before-sm {
        display: none!important;
    }
    .size-md .irpt .from-md {
        display: flex!important;
        justify-content: center;
    }
    .size-md .before-md {
        display: none!important;
    }
    .size-lg .irpt .from-lg {
        display: flex!important;
        justify-content: center;
    }
    .size-lg .before-lg {
        display: none!important;
    }

    
    
</style>

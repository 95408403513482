import { createNamespacedHelpers } from 'vuex';
import { dateTimedifference, usersTime } from '../utility/datetime';
import { fixDecimals } from '../utility/helper-functions';
import { eventBus } from '../main';
// import * as workerTimers from 'worker-timers';

const ecocalendarNS = createNamespacedHelpers('calendar');
const general = createNamespacedHelpers('general');

const maxDecimals = 3;

export default {
    props: ["nextTimeStamp", "details"],
    created() {

        this.handleNextUpcoming();
        
    },
    computed: {
        ...ecocalendarNS.mapGetters(['getCountryAdjective', 'majorCountries']),
        ...general.mapGetters(['user']),
        timezone() {
            return this.user.timezone;
        },
        isNextUpcoming() {
            return this.nextTimeStamp === this.calEvent.timestamp;
        },
        isScrollTarget() {
            return this.calEvent.id === this.nextEventIndex;
        },
        formattedDate() {
            return usersTime(this.calEvent.timestamp, true, false, false, { dateFormat : "D js M" }, false, this.timezone);
        },
        formattedTime() {
            if(this.calEvent.type.toLowerCase() === "holiday") {
                if(this.details.isSmWidget)
                    return "All Day";

                return "";

            }
                
                
            return usersTime(this.calEvent.timestamp, false, true, false, { timeFormat : "HH:mm" }, false, this.timezone);
        },
        currency() {
            if(this.calEvent.country === "EMU")
                return "EUR";
            else if(this.calEvent.country === "Hong Kong" || this.calEvent.country === "HKG")
                return "HKD";
            else if(this.calEvent.country === "South Korea")
                return "KRW";
            else if(this.calEvent.country === "Taiwan" || this.calEvent.country === "TWN")
                return "TWD";
            else if(this.calEvent.country === "Macau")
                return "MOP";
            else if(this.calEvent.country === "Moldova")
                return "MDL";
            else if(this.calEvent.country === "Czech Republic" || this.calEvent.country === "CZE")
                return "CZK";
            else if(this.calEvent.country === "Palestine" ||
            this.calEvent.country === "East Timor")
                return "N/A";
            
            return this.calEvent.currency;
        },
        eventName() {
            const adj = this.getCountryAdjective(this.calEvent.country)
            if(this.isHoliday) {
                return `${adj} Public Holiday`;
            }

            return `${adj} ${this.calEvent.name} ${this.period}`; 
        },
        impactClassSpecifier() {
            if(!this.isHoliday) {
                if(this.calEvent.impact === 1)
                    return "low";
                else if(this.calEvent.impact === 2) 
                    return "medium";
                else if(this.calEvent.impact === 3)
                    return "high";
                else
                    return "no";
            } 
            else
                return "no";
        },
        actualSemantics() {

            const actual = this.calEvent.actual;
            const forecast = this.forecastNumber;

            if(isNaN(actual) || actual === null || (isNaN(forecast) || !forecast) && forecast !== 0)
                return "";

            return this.processSemantics(forecast, actual);
            
        },
        isActualExtreme() {
            const actual = this.calEvent.actual;
            const min = this.calEvent.min;
            const max = this.calEvent.max;

            if(isNaN(actual) || actual === null || min === null || max === null)
                return false;

            if(actual > max || actual < min)
                return true;
            
            return false;

        },
        actualValue() {

            // if(this.isHoliday)
            //     return "Holiday";

            const now = usersTime(new Date(), true, true, true, {
                dateFormat: "y-m-d",
                timeFormat: "24hr"
            }, "UTC");

            if(!this.calEvent.actual && this.calEvent.actual !== 0 )
                 if(now <= this.calEvent.timestamp)
                    return '';
                else
                    return '-';
           

            const number = fixDecimals(this.calEvent.actual, maxDecimals);

            return number + this.calEvent.suffix;
        },
        actualHigherOrLower() {
            const actual = this.calEvent.actual;
            const forecast = this.forecastNumber;
            if((actual || actual === 0) && (forecast || forecast === 0)) {
                if(actual > forecast) {
                    return 'higher';
                }  
                else if(actual < forecast) {
                    return 'lower';
                }
                    
            }
                
            return "";
            
        },
        actualArrow() {
            if(!this.actualHigherOrLower)
                return '';

            if(this.actualHigherOrLower === 'higher')
                return "long-arrow-alt-up";
            else if(this.actualHigherOrLower === 'lower')
                return "long-arrow-alt-down";
            else
                return '';
        },
        actualTooltipText() {
            if(!this.actualHigherOrLower)
                return '';

            if(this.actualHigherOrLower === 'lower')
                return `Actual lower than forecast`;
            else if(this.actualHigherOrLower === 'higher')
                return `Actual higher than forecast`;
            else
                return "";
            
        },
        actualExtremeTooltipText() {
            if(!this.actualHigherOrLower || !this.isActualExtreme)
                return '';

            if(this.actualHigherOrLower === 'lower')
                return `Actual lower than minimum forecast`;
            else if(this.actualHigherOrLower === 'higher')
                return `Actual higher than maximum forecast`;
            else
                return "";
            
        },
        forecastNumber() {
            if(this.calEvent.forecast || this.calEvent.forecast === 0 || this.calEvent.consensus || this.calEvent.consensus === 0) {

                let number = this.calEvent.forecast;
                if(this.calEvent.consensus || this.calEvent.consensus === 0)
                    number = this.calEvent.consensus;

                if((this.calEvent.max || this.calEvent.max === 0) && (number || number === 0) > this.calEvent.max)
                    number = this.calEvent.max;
                if((this.calEvent.min || this.calEvent.min === 0) && (number || number === 0) < this.calEvent.min)
                    number = this.calEvent.min;
                
                number = fixDecimals(number, maxDecimals);


                return number;
            }
                
            return '-';
            
        },
        forecastValue() {
            if(this.forecastNumber === "-")
                return '';
            
            return this.forecastNumber + this.calEvent.suffix;
        },
        minValue() {
            if(this.calEvent.min || this.calEvent.min === 0) {
                const number = fixDecimals(this.calEvent.min, maxDecimals);

                return number + this.calEvent.suffix;
            }
                
            return '-';
        },
        maxValue() {
            if(this.calEvent.max || this.calEvent.max === 0) {
                const number = fixDecimals(this.calEvent.max, maxDecimals);

                return number + this.calEvent.suffix;
            }
                
            return '-';
            
        },
        previous() {
            return this.revised ? this.revised : this.calEvent.previous;
        },
        previousNumber() { 
            if(this.previous || this.previous === 0) {
                const number = fixDecimals(this.previous, maxDecimals);
                return number;
            }

            return '-';

        },
        previousValue() {
            if(this.previousNumber === "-")
                return '-';

            return fixDecimals(this.previousNumber, maxDecimals) + this.calEvent.suffix;
        },
        forecastHigherOrLower() {
            if(this.calEvent.actual && this.calEvent.actual !== 0 || !this.forecastNumber || !this.previousNumber)
                return '';
            
            if(this.previousNumber > this.forecastNumber)
                return 'expected lower';
            else if(this.previousNumber < this.forecastNumber)
                return 'expected higher';
            else 
                return 'expected match';
            
        },
        forecastTooltipText() {
            if(!this.forecastHigherOrLower)
                return '';

            if(this.forecastHigherOrLower === 'expected lower')
                return `Expected lower than previous reading`;
            else if(this.forecastHigherOrLower === 'expected higher')
                return `Expected higher than previous reading`;
            else
                return `Expected matches previous reading`
            
        },
        forecastArrow() {
            if(!this.forecastHigherOrLower)
                return '';

            if(this.forecastHigherOrLower === 'expected higher')
                return "long-arrow-alt-up";
            else if(this.forecastHigherOrLower === 'expected lower')
                return "long-arrow-alt-down";
            else
                return '';
        },
        revised(){
            return this.calEvent.revised;
        },
        revisedFrom() {
            return this.calEvent.revised ? this.calEvent.previous : 0;
        },
        revisedValue() {
            if(!this.revised)
                return '';

            const number = fixDecimals(this.calEvent.previous, maxDecimals);

            return number + this.calEvent.suffix;
        },
        revisedHigherOrLower() {
            if(!this.revised)
                return '';

            if(this.revised > this.revisedFrom)
                return 'revised higher';
            else if(this.revised < this.revisedFrom)
                return 'revised lower';
        },

        revisedTooltipText() {
            if(!this.revised)
                return '';

            if(this.revisedHigherOrLower === 'revised lower')
                return `Revised down from ${this.revisedValue}`;
            else if(this.revisedHigherOrLower === 'revised higher')
                return `Revised up from ${this.revisedValue}`;
            
        },
        revisedSemantics() {

            if(!this.revised)
                return '';

            return this.processSemantics(this.revisedFrom, this.previous);

        },
        // extr
        revisedArrow() {
            if(!this.revised)
                return '';

            if(this.revisedHigherOrLower === 'revised higher')
                return "long-arrow-alt-up";
            else if(this.revisedHigherOrLower === 'revised lower')
                return "long-arrow-alt-down";
        },
        isHoliday() {
            return this.calEvent.type === 'Holiday';
        },
        isSpeech() {
            return this.calEvent.name.toLowerCase().includes('speech') || this.calEvent.type?.toLowerCase() === 'speech';
        }
        ,
        period() {
            if(this.calEvent.reference)
                return '(' + this.calEvent.reference + ')';

            return '';
        }
    },
    data() {
        return {
            countdown: '',
            countdownFinished: false
        }
    },
    methods: {
        ...ecocalendarNS.mapActions(["updateMissingEvents"]),
        handleNextUpcoming() {
            // console.log(this.nextTimeStamp, this.calEvent.timestamp)
            if(this.isNextUpcoming || this.isNext) {
                if(this.interval) {
                    clearInterval(this.interval);
                    this.interval = null;
                }

                const timestamp = this.calEvent.timestamp;
                
                this.interval = setInterval(() => {
                    // console.log(this.calEvent.country);
                    // console.log(fullSeconds)
                    
                    const now = usersTime(new Date(), true, true, true, { dateFormat : "y-m-d", timeFormat: "24hr" }, "UTC");

                    let fullSeconds = dateTimedifference(now, timestamp, "seconds");
            
                    let fullHours = Math.trunc(fullSeconds / 3600);
                    let fullMinutes = Math.trunc(fullSeconds % 3600 / 60);
                    let secondsLeftInMinute = Math.trunc(fullSeconds % 60);

                    if(fullSeconds >= 3600) {
                        this.countdown = `${fullHours}h ${fullMinutes >= 10 ? fullMinutes : '0' + fullMinutes}m`;
                    }
                    else if(fullSeconds >= 60) {
                        this.countdown = `${fullMinutes}m ${secondsLeftInMinute >= 10 ? secondsLeftInMinute : '0' + secondsLeftInMinute}s`;
                    }
                    else if(fullSeconds < 60 && fullSeconds >= 0) {
                        this.countdown = `${secondsLeftInMinute}s`;
                    }
                    else{
                        this.countdown = "";
                        this.countdownFinished = true;
                        if(this.interval) {
                            clearInterval(this.interval);
                            this.interval = null;
                            // console.log("cleared interval");
                        }
                        setTimeout(() => {
                            this.$emit("check-next-event");
                            // this.handleNextUpcoming();
                        }, 1000);

                        //retries if event is not fetched
                        const majorCountries = [...this.majorCountries]; //
                        if(majorCountries.includes(this.calEvent.country) && !this.isSpeech && !this.isHoliday) {

                            setTimeout(() => {
                                if(!this.calEvent.actual) {
                                    this.updateMissingEvents(this.calEvent.id);
                                    eventBus.$emit("event-backup-fetch", {
                                        instanceID: this.$root.instanceID,
                                        timestamp: this.calEvent.timestamp,
                                        country: this.calEvent.country
                                    });
                                }

                            }, 1000);

                            

                        }

                        
                    }
                        
                },1000);
            }
        },
        processSemantics(val1, val2) {
            const semantics = this.calEvent.semantics;
            
            if(semantics === "HIGHERISBETTER") {
                if(val1 < val2)
                    return "u-better-than";
                else if(val1 > val2)
                    return "u-worse-than";
            }else if(semantics === "LOWERISBETTER") {
                if(val1 > val2)
                    return "u-better-than";
                else if(val1 < val2)
                    return "u-worse-than";
            }
        }
    },
    watch: {
        nextTimeStamp(nVal) {
            if(nVal)
                this.handleNextUpcoming();
        }
    },
    beforeDestroy() {
        if(this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }
    }
    
}
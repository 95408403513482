<template>
    <div :id="instanceID" class="vld-parent">
        <loading v-model="showSpinner" :active="showSpinner"
                 :can-cancel="true"
                 :is-full-page="false">
            Please wait, while the action is being performed...
        </loading>

        <transition
                mode="out-in"
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="animate__animated animate__fadeOut"
                duration="200">
            <base-modal v-if="modalIsOpen"
                        :header="modal.header"
                        :mode="modal.mode"
                        :title="modal.title"
                        :classes="modal.classes"
                        :footer="modal.footer"
                        :countdown="modal.countdown"
                        :close="true"
                        @close-modal="closeModal">
                <template #default>
                    <component :is="modal.componentInModal" :panel="modal.panel" :modal="modal"></component>
                </template>
            </base-modal>
        </transition>

        <!-- <transition
                mode="out-in"
                enter-active-class="animate__animated animate__fadeInRight"
                leave-active-class="animate__animated animate__fadeOutRight"
                duration="500"> -->
            <side-modal 
                        v-if="sideModalIsOpen"
                        :modal="sideModal"
                        @close-modal="closeModal">
                <template #default>
                    <component :is="modal.componentInModal" :panel="modal.panel" :modal="modal"></component>
                </template>
            </side-modal>
        <!-- </transition> -->


        <transition
        v-if="!autoLogin"
        mode="out-in"
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
        >

            <broker-funnel
            v-if="isBrokerLogin && loggedOut"
            @register-login-successful="processLogin"
            ></broker-funnel>

            <router-view v-else-if="loggedOut && isExternalClientMode"
            @register-login-successful="processLogin"
            ></router-view>

            <unplugged-notice 
            v-if="loggedOut && !isExternalClientMode && !isBrokerLogin && isAutoPilotMode"
            :inactiveMessage="'The analyst desk has not been set up.'"
            ></unplugged-notice>

            <div  v-else-if="loggedOut && !isExternalClientMode && !isBrokerLogin && !isAutoPilotMode">
                <onboard-funnel
                :promoID="promoID"
                :specialLink="specialLink"
                @register-login-successful="processLogin"
                :continueOnboardingState="continueOnboardingState"
                @onboard-skipped="onboardSkipped"
                ></onboard-funnel>
            </div>

        </transition>
        <login-component v-if="autoLogin && loggedOut"></login-component>
        <div v-if="!loggedOut && readyForWidgets" class="platform-background" :class="{'no-overflow': requestOn}">
            <div class="e-client"
            style="position: absolute; height: 100vh;"
            v-if="requestOn"
            >
                <external-register
                
                @back-to-home="requestOn = false"
                ></external-register>
            </div>
            <div 
            v-if="remoteAccess"
            style="position: absolute; width: 100%; padding: 2px 0; background-color: #B33C22; color: #FFFFFF; font-size: 10px; text-align: center; z-index: 3;">ADMIN MODE or NOT LOGGED OUT since last admin mode. Please ensure to log out when finished the session.</div>
            <the-header
                v-if="!singleWidgetMode"
                :currentComp="componentToBeRendered"
            ></the-header>
            <top-ribbon
            v-if="!demoPageMode"
            :ribbonType="ribbonType"></top-ribbon>
            <unplugged-notice 
            v-if="isAutoPilotMode && (!entity.autopilot_mode || entity.ksa)"
            :inactiveMessage="'The analyst desk has not been set up.'"
            ></unplugged-notice>
            <component v-else :is="componentToBeRendered" :details="componentDetails" :specialLink="specialLink" id="main-terminal-window"
            :class="{'no-overflow': sideModalIsOpen}"></component>
            <div class="footer" v-if="fullscreenId > 0">
                <p>
                    <font-awesome-icon
                            :icon="['fa', 'copyright']"/>
                    {{ currentYear }} Financial Source v3.0{{displayVersionID}}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex';

import Pusher from './mixins/pusher';
import Squawk from './mixins/squawk';
import Intercom from './mixins/intercomMixin';
import findComponent from './mixins/componentToBeRendered';
import Constants from "@/utility/constants"
const dashboards = createNamespacedHelpers('dashboard');
const general = createNamespacedHelpers('general');
const payments = createNamespacedHelpers('payments');
const headlines = createNamespacedHelpers('headlines');
const widgets = createNamespacedHelpers('widget');
const squawk = createNamespacedHelpers('squawk');
const webinars = createNamespacedHelpers('webinars');
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
// import AudioSquawk from './components/widgets/AudioSquawk';
// import BreakingHeadlines from './components/widgets/BreakingHeadlines';
import * as workerTimers from 'worker-timers';
import AccountSettings from './components/ui/AccountSettings.vue';
import AccountOverview from './components/ui/account/AccountOverview.vue';
import BugReport from './components/ui/forms/BugReport';
import BrokerRequest from './components/ui/forms/BrokerRequest.vue';
import ChangePassword from './components/ui/ChangePassword.vue'
import EcocalModal from './components/widgets/components/EcoCalendar/EcocalModal';
import EditDashboard from './components/ui/forms/EditDashboard';
// import EntityComponent from './components/EntityComponent';
import GlossaryApp from './components/widgets/GlossaryApp';
import LoginComponent from "./components/ui/onboard/LoginComponent.vue";
import ImageViewer from './components/widgets/components/ImageViewer';
import InterestrateModal from './components/widgets/components/InterestRate/InterestrateModal.vue';
import PdfViewer from './components/widgets/components/PdfViewer';
// import PlatformLogin from './components/ui/forms/PlatformLogin';
import TheHeader from './components/ui/TheHeader.vue';
import DashboardComponent from './components/DashboardComponent.vue';
import VideoComponent from './components/VideoComponent.vue';
import HomeComponent from './components/HomeComponent.vue';
import ResearchComponent from './components/ResearchComponent.vue';
import CheckoutComponent from './components/CheckoutComponent.vue';
import StreamReminder from './components/widgets/components/StreamReminder';
import SubmitRating from './components/widgets/components/SubmitRating';
import TerminalMoved from './components/ui/TerminalMoved.vue';
import TerminalSupport from './components/ui/forms/TerminalSupport';
import WidgetShop from './components/ui/shop/WidgetShop';
import TerminalPlanSelector from './components/ui/TerminalPlanSelector.vue';
import WelcomePopup from './components/ui/WelcomePopup.vue';
import TutorialModal from './components/widgets/components/TutorialModal.vue';
import CardDetailsModal from './components/ui/cardmodal/CardDetailsModal.vue';
import SunsetPopup from './components/widgets/components/VideoPlayers/SunsetPopup.vue';
import { diffArray, getParameterByName, getPromoID, getServerEnv, initialisePusherWithAuth, isBrokerDemo, isExternalClientMode, isLiveServer, isMobileForDashboards, talkToServer, isAutoPilotMode } from './utility/helper-functions';
// import { rightNowUTCWithFormatting } from './utility/datetime';
import { eventBus, tabCommReceiver, tabCommSender } from './main';
import AppService from './services/app.service';
import { setupCacheCallBlocker } from './data/localStorage';
// import VidCom from './components/widgets/VidCom';
import TrackingService from './services/tracking/tracking.service';
import _ from "lodash";
import BootUpMixin from "./mixins/bootUpMixin";
import OnboardFunnel from './components/ui/OnboardFunnel.vue';
import BrokerFunnel from './components/ui/BrokerFunnel.vue';
import ExternalClient from './components/ui/ExternalClient.vue';
import TermsConditions from './components/ui/onboard/components/TermsConditions.vue'
import TopRibbon from './components/ui/TopRibbon.vue';
import EcocalFilter from './components/widgets/components/EcoCalendar/EcocalFilter.vue';
import EcresearchFilter from './components/widgets/components/EconomicResearch/EcresearchFilter.vue';
import FrontendControls from './components/ui/FrontendControls.vue';
import BrokerSetup from './components/ui/broker/BrokerSetup.vue';
import ClientProfiles from './components/ui/broker/ClientProfiles.vue';
import TeamPage from './components/ui/broker/TeamPage.vue';
import AutoPilot from './components/ui/broker/AutoPilot.vue';
import Swal from "sweetalert2";
import PaymentCardModal from "./components/ui/payments/PaymentCardModal";
import dayjs from 'dayjs';
import accountService from './services/account/account.service';
import { addTime, usersTime } from './utility/datetime';
import ExternalRegister from './components/ui/external/ExternalRegister.vue';
import UnpluggedNotice from './components/ui/broker/components/UnpluggedNotice.vue';
import SquawkModal from './components/ui/notice/SquawkModal.vue'

const PAYMENT_LIMIT = 86400000;
export default {
    components: {
        Loading,
        AccountOverview,
        AccountSettings,
        AutoPilot,
        BrokerRequest,
        BrokerSetup,
        BugReport,
        ChangePassword,
        CheckoutComponent,
        ClientProfiles,
        EcocalModal,
        EcresearchFilter,
        EditDashboard,
        EcocalFilter,
        ExternalClient,
        ExternalRegister,
        // EntityComponent,
        FrontendControls,
        CardDetailsModal,
        GlossaryApp,
        ImageViewer,
        InterestrateModal,
        PdfViewer,
        LoginComponent,
        DashboardComponent,
        HomeComponent,
        VideoComponent,
        ResearchComponent,
        TerminalSupport,
        WidgetShop,
        StreamReminder,
        SubmitRating,
        SunsetPopup,
        TerminalPlanSelector,
        WelcomePopup,
        OnboardFunnel,
        BrokerFunnel,
        SquawkModal,
        TeamPage,
        TermsConditions,
        TheHeader,
        TopRibbon,
        TutorialModal,
        UnpluggedNotice,
        TerminalMoved
    },
    mixins: [Intercom,Pusher, Squawk, BootUpMixin, findComponent],
    data() {
        return {
            autoLogin: false,
            loggedOut: false,
            componentToBeRendered: null,
            componentDetails: null,
            modalIsOpen: false,
            sideModalIsOpen: false,
            versionChanged: false,
            formObj: {
                id: null,
                mode: ''
            },
            modal: {},
            sideModal: {},
            currentYear: new Date().getFullYear(),
            readyForWidgets: true,
            isPopOut: getParameterByName('popout'),
            watchToRemoveShowcaseAnalChat: false,
            continueOnboardingState: {},
            ribbonType: '',
            allSettings: [],
            remoteAccess: window.bootUp.remoteAccess || window.localStorage.getItem("remoteAccess"),
            promoID: false,
            specialLink: '',
            pusherSwal: null,
            pusherOn: true,
            requestOn: false
        }
    },
    provide() {
        return {
            formObj: this.formObj
        }
    },
    computed: {
        ...general.mapState(['showSpinner', 'versionId', 'demoPageMode', 'externalUser', 'checkoutLoginMode', 'singleWidgetMode']),
        ...general.mapGetters(['user', 'hasRenewFails', 'componentAfterRefresh', 'isUserBm', 'userQnaOn', 'entityLocked', 'externalUserAutoLogin', 'entity']),
        ...dashboards.mapState(['poppedoutDashboards', 'fullscreenId']),
        ...payments.mapState(['paymentMethod', 'paymentHistory', 'activeSubscriptions', 'currentProducts']),
        ...dashboards.mapGetters(['activeDashboardByInstanceID', 'activeDashboardInfoByInstanceID', 'layouts', 'systemDashboards']),
        ...payments.mapGetters(['userHasPurchasedProducts', 'isCurrentCardValid', 'isCurrentCardExpiring', 'allWidgets','availableWidgets', 'activePlan', 'promotionalProducts', 'mergedPlans', 'currentAddons', 'availableProducts', 'historicProductAccess']),
        ...headlines.mapGetters(['readyForCategoryProcessing']),
        ...widgets.mapGetters(['pusherChannels', 'getCodeByImplementationId', 'hasTCWidgets']),
        ...squawk.mapGetters(['subscribedToSquawk']),
        ...webinars.mapGetters(['isCurrentlyLive']),
        isExternalClientMode() {
            return isExternalClientMode();
        },
        isAutoPilotMode() {
            return isAutoPilotMode();
        },
        instanceID() {
            return this.$root.instanceID
        },
        activeDashboard() {
            return this.activeDashboardByInstanceID(this.instanceID, "dashboard");
        },
        activeDashboardInfo() {
            return this.activeDashboardInfoByInstanceID(this.instanceID, "dashboard");
        },
        activeVideoPage() {
            return this.activeDashboardByInstanceID(this.instanceID, "videopage");
        },
        activeVideoPageInfo() {
            return this.activeDashboardInfoByInstanceID(this.instanceID, "videopage");
        },
        loginFormTitle() {
            let formMode = this.$store.getters['auth/getLoginFormMode'];
            return formMode === 'register' ? 'Create your free account': formMode;
        },
        displayVersionID() {
            if(window.bootUp.domain.indexOf('dev') > -1)
                return '.0.'+this.versionId;

            return '';
        },
        displayTC() {
            return this.hasTCWidgets;
        },
        isBrokerLogin() {
            return window.location.href.indexOf('partner') > -1;
        }
    },
    created() {
        this.autoLogin = this.externalUserAutoLogin || window.bootUp.remoteAccess
        window.addEventListener("resize", () => eventBus.$emit("window-resize", {
            instanceID: this.$root.instanceID,
        }));
        const track = isLiveServer();
        TrackingService.initializeTracking(track);
        TrackingService.trackUser();
        // console.log(window.dataLayer);
        const promoID = getPromoID();
        if(promoID)
            this.promoID = +promoID;

        const specialLink = localStorage.getItem("specialLink");
            if(specialLink)
                this.specialLink = specialLink;

        this.setupNonAuthPusherChannels();

        this.registerCloseWindowEvent();
        this.registerAnalchatCheckEvent();
        this.$store.dispatch('widget/setWidgets', _.cloneDeep(window.bootUp.widgets));

        eventBus.$on("request-on", this.makeRequestOn);

        eventBus.$on("nav-change", obj => {
            if(obj.instanceID !== this.$root.instanceID)
                return;

            this.componentDetails = obj.details;
            if(obj.comp !== 'checkout') {
                this.setActiveNavItem(obj.comp);
                this.setComponentAfterRefresh(obj.comp);
            }
                
            this.componentToBeRendered = Constants.COMPONENTS[obj.comp];
            
        });

        eventBus.$on("pusher-unavailable", () => this.pusherUnavailable());
        eventBus.$on("pusher-available", () => this.pusherAvailable());
        // console.log('get widgets', this.$store.getters['widget/widgets']);

        // if(!localStorage.getItem('token') &&
        // this.$router.currentRoute.path !== '/pwr-rst' &&
        // this.$router.currentRoute.path !== '/pwr',
        // this.$router.currentRoute.path !== '/start-here') {
        //     // let query;
        //     if(!this.$router.currentRoute.query) {
        //         this.$router.push('/login');
        //     }
        //         // query = this.$router.currentRoute.query;

        //     // console.log("pushing to login", this.$router.currentRoute);
        
        

        eventBus.$on('login-successful', data => {
            this.launchApp(data, true);
        });
        eventBus.$on('register-login-successful', data => {
            if(data.instanceID !== this.$root.instanceID)
                return;

            this.launchApp(data.data, true);
            // this.openTourGuide();
        });
        eventBus.$on('logout-successful', () => {
            this.logout();
        });
        eventBus.$on('open-modal', modal => {
            // if (eventBus.$root.isReady) {
            //     eventBus.$root.isReady = false;
            if(modal.instanceID === this.$root.instanceID) {
                this.formObj.id = modal.id;
                this.formObj.mode = 'new';
                if (modal.id) {
                    this.formObj.mode = 'edit';
                }

                this.modal = modal;
                this.modalIsOpen = true;
            }

            // }
            // setTimeout(() => {
            //     eventBus.$root.isReady = true;
            // }, 100);


        });
        eventBus.$on('close-modal', () => {
            this.closeModal();
            // const body = document.getElementsByTagName("body");
            // for (const b of body) {
            //     // console.log(b);
            //     b.style.overflow = "auto";
            // }
        });
        eventBus.$on('open-side-modal', modal => {
            this.sideModalIsOpen = true;
            this.sideModal = modal
        }) 

        window.onbeforeunload = () => {
            localStorage.removeItem('adfx');
            localStorage.removeItem('adt');
        }

        
        if(this.isAutoPilotMode)
            localStorage.removeItem('token');

        const token = localStorage.getItem('token');

        // console.log(rightNowUTCWithFormatting(false));

        if (!token || this.externalUserAutoLogin) {
            this.loggedOut = true;
        } else {

            // const now = rightNowUTCWithFormatting(false);
            const cacheBlocker = +localStorage.getItem('cacheCallBlocker');
            let params = '';
            let clearStores = false;

            // console.log(now - cacheBlocker, window.bootUp.cacheBlockerIntervall)

            // if (now - cacheBlocker > window.bootUp.cacheBlockerIntervall) {
                this.$store.dispatch("tweetdeck/clearFetchBoards");
                params = window.bootUp.cacheParams;
                clearStores = true;
            // }


            if(!window.bootUp.firstCallMade) {
                talkToServer(`/user/refresh${params}`, "GET", null, null, true).then(response => {
                    if (response && response.success) {
                        // console.log("resp", response);
                        this.saveUserData(response);
                        this.launchApp(response.data, clearStores);
                        if(!cacheBlocker) {
                            tabCommSender.postMessage({ wakeUpPopout: true });
                        }
                    } else {
                        this.logout();
                    }
                    if(isExternalClientMode() && !this.entity.quantum_mode && !response.data.user.user.bm) {
                        this.logout();
                    }

                }).catch(() => this.logout());
            }else{
                this.launchApp({}, false);
            }

            this.initializeVersionId();
        }
        
        tabCommReceiver.onmessage = event => { 
        

            // console.log(event);
            if(event?.wakeUpMain && !getParameterByName("popout") || 
            event?.wakeUpPopout && getParameterByName("popout")) {
                location.reload();
            }

            if(event?.dashboardUpdate) {
                this.communicateDashboardUpdateToOtherTabs(event.dashboard);
            }

            if(event?.unlockedWidgets && getParameterByName("popout")) {
                this.setUnlockedWidgets(event.unlockedWidgets);
            }
            if(event && Object.prototype.hasOwnProperty.call(event, "entity") && getParameterByName("popout")) {
                this.setEntity(event.entity);
            }
            if(event && Object.prototype.hasOwnProperty.call(event, "userQna") && getParameterByName("popout")) {
                let profile = _.cloneDeep(this.user);

                profile = {
                    ...profile,
                    qanda_enabled: event.userQna
                }
                this.setCurrentUser(profile);
            }
            
        }

    },
    methods: {
        ...dashboards.mapActions(['addDashboard','resetDashboards', 'updateDashboardById', 'setActiveDashboard', 'closeAllPopouts', 'setAnalChatActiveOn', 'setSystemDashboards', 'setSystemDashboardsFromLayout', 'setActiveDashboardAndTab', 'processSystemLayouts','addUserGuideDashboard', 'updateDashboardById', 'communicateDashboardUpdateToOtherTabs', 'setDashboardAsTab']),
        ...payments.mapActions(['setCurrentShop', 'loadPaymentMethod', 'loadAvailableProducts', 'setWidgetProducts', 'removePaymentMethod', 'loadPaymentHistoryPromise','setFailedPayment']),
        ...general.mapActions(['setCountries', 'setCurrentUser', 'destroyUserStoreData', 'setVersionId', 'fetchShopSettings', 'processShopSettings', 'setActiveNavItem', 'setComponentAfterRefresh', 'setDbReady', 'setLastTrainingCentreOrderUpdated', 'setWatchHistory', 'setUnlockedWidgets', 'setEntity']),
        ...widgets.mapActions(['addPusherChannel', 'removePusherChannel', 'clearPusherChannels']),
        ...squawk.mapActions(['setSubscribedToSquawk']),
        registerCloseWindowEvent: function () {
            let context = this;
            window.addEventListener('beforeunload', function () {
                context.closeWindowPopouts();

            }, false)
        },

        processLogin(data) {
            // console.log("processLogin",data);
            this.launchApp(data.data, true, data.isOnboard);
        },

        closeModal() {

            if(this.modal.componentInModal === "bug-report")
                eventBus.$emit('bug-report-closed');

            this.modal = {};
            this.modalIsOpen = false;

            this.sideModalIsOpen = false;
            this.sideModal ={};
        },

        closeWindowPopouts: function () {
            let context = this;
            let dashboard = _.cloneDeep(context.activeDashboardInfo);
            if (dashboard.popout) {
                dashboard.tab = true;
                dashboard.popout = false;
                context.updateDashboardById(dashboard);
            } else {
                context.poppedoutDashboards.forEach(popout => popout.window.close());
                context.closeAllPopouts();
            }
        },
        registerAnalchatCheckEvent: function () {
            eventBus.$on("ANALCHAT_CHECK", () => {
                if(this.componentToBeRendered !== 'home-component' && this.componentToBeRendered !== 'video-component') {
                    this.shutDownIntercom();
                    return;
                }
                if(!this.loggedOut && !this.isPopOut &&
                (this.availableWidgets.length || this.watchToRemoveShowcaseAnalChat)) {

                    const analChatImpID = window.bootUp.widgets.find(el => el.code === 'ANALCHAT')?.implementation_id;
                    let allowRemoval = !this.availableWidgets?.includes(analChatImpID+"") || !this.userQnaOn;
                    if(allowRemoval) {

                        this.watchToRemoveShowcaseAnalChat = false;
                        this.removeAnalChatWidget();
                    } else {

                        this.setAnalChatActiveOn(null);
                        const found = this.allWidgets.find(el => el.code === "ANALCHAT");

                        if(found && found.planDetails.showcasePlanSlug) {
                            this.watchToRemoveShowcaseAnalChat = true;
                            const dashboardID = 999999;
                            this.setAnalChatActiveOn(dashboardID);
                            this.startIntercom();
                        }else{
                            talkToServer("/setting/user/analyst-chat", "GET", null, null, true).then(resp => {

                                if(resp && resp.data && resp.data.slug && resp.data.slug === "analyst-chat") {

                                    let dashboardID = (JSON.parse(resp.data.json)).dashboardID;

                                    this.setAnalChatActiveOn(dashboardID);
                                    if(dashboardID)
                                        this.startIntercom();
                                }

                            });
                        }
                        // const obj = {
                        //     dashboardID: Math.random()
                        // };

                        // talkToServer("/setting/user/analyst-chat", "POST", null, obj, true).then(() => {
                        //     this.setAnalChatActiveOn(obj.dashboardID);
                        //     this.startIntercom();
                        // });
                    }
                }
            })

        },
        async launchApp(data, clearStores, isOnboard=false) {
            // console.log(data)
            if(!this.isPopOut)
                this.$router.replace({'query': null});
                
            this.setActiveNavItem("home");
            let context = this;
            this.loggedOut = false;
            if(this.singleWidgetMode && document.querySelector('.fs-loading-notification-' + this.$root.instanceID))
                document.querySelector('.fs-loading-notification-' + this.$root.instanceID).innerHTML = "Loading Indicator... 66%";

			if(window.bootUp.isExploreMode)
				return;

            // if(!this.singleWidgetMode) data.user.associated_data.settings
                this.allSettings = data.user.associated_data.settings;// await talkToServer('/setting/user', "GET", null, null, true);
            
            const allSettings = this.allSettings;
            // console.log('All settings', allSettings);

            if(!window.bootUp.firstCallMade) {
                //start cacheBlocker only on main window and once for non-terminal users
                if(!getParameterByName("popout") && window.bootUp.cacheBlocker == null) {



                    if(clearStores) {
                        this.clearStoreValues();
                        this.resetDashboards();
                    }
                    AppService.setupDB(clearStores).then(response => {
                        if(response) {
                            // console.log("clearStores", clearStores, "cachecall blocker null", window.bootUp.iDB);

                            this.$store.dispatch('storeCacheCallData', data);
                            this.$store.dispatch("widget/addLookupCategories");
                            this.$store.dispatch("headlines/setHeadlineValues", true);
                            this.readyForWidgets = true;

                        }

                    });
                    setupCacheCallBlocker();
                }else {
                    AppService.setupDB().then(() => {

                        this.$store.dispatch("widget/addLookupCategories");
                        this.$store.dispatch("headlines/setHeadlineValues", true);
                        this.$store.dispatch("headlines/setRareHeadlinesFetched", true);
                        this.readyForWidgets = true;

                        // console.log("ready for widgets", this.readyForWidgets);
                    }
                    );
                }
            }else{
                this.readyForWidgets = true;
            }

            if(!window.bootUp.firstCallMade || window.bootUp.firstCallMade) {

                const shop = allSettings?.find(el => el.slug === 'shopsettings');
                if(shop)
                    this.processShopSettings(shop.json);

                if(!window.bootUp.isExploreMode) {
                    const layouts = allSettings?.find(el => el.slug === 'layouts');
                    if(layouts)
                        await this.processSystemLayouts(layouts.json);

                }

                // await this.getUserSettings();
                await this.loadAvailableProducts();
                // if(!this.externalUser)
                this.setCurrentShop([data.user.associated_data.product_access, data.user.associated_data.scheduled_product_access]);
                this.setWidgetProducts(window.bootUp.entity?.widget_products || {});
                this.setCountries(window.bootUp?.countries || []);
                this.setCurrentUser(data.user.user);
                this.setDbReady(true);


                if(!data.user.user.first_logged_in_at) {
                    this.setTimezoneOnFirstLogin();

                    if(isMobileForDashboards()) {
                        const html = `Please note, functionality on mobile devices are limited.<br /><br /><strong>To take full advantage of the terminal, please log in on a desktop or laptop computer.</strong>`
                        Swal.fire({
                            title: 'Mobile Version',
                            html: html,
                            type: 'info',
                            confirmButtonColor: '##1d9deb',
                            confirmButtonText: 'OK'
                            // timer: 1500
                        });
                    }

                }


                if(!this.externalUser && !this.demoPageMode)
                    this.loadPaymentMethod().then(() => {
                        context.checkCurrentUserPaymentSettings();
                    });


                // start cacheBlocker only on main window and once for non-terminal users
                // console.log(clearStores,"clearStores");
                // if(!getParameterByName("popout") && window.bootUp.cacheBlocker == null) {
                // 	AppService.setupDB(clearStores).then(response => {
                // 		if(response) {
                //             this.readyForWidgets = true;
                //             this.$store.dispatch('storeCacheCallData', data);
                //         }

                // 	});
                // 	setupCacheCallBlocker();
                // }else {
                //     AppService.setupDB().then(() => this.readyForWidgets = true);
                // }

                //Squawk

                if (this.subscribedToSquawk) {

                    this.setOrGetAudioTokens();

                }

                //headline fetch job
                
                if ((this.$store.getters["widget/getWidgetByCode"]("HEADLINES") || this.$store.getters["widget/getWidgetByCode"]("VIDCOM")) &&
                !window.bootUp.headlineFetchJob) {
                    const number = 5;
                    window.bootUp.headlineFetchJob = setInterval(() => {

                        const lastCheck = dayjs().utc().add(-number, 'minutes').format("YYYY-MM-DD[T]HH:mm:ss");
                        // console.log("last check",lastCheck);
                        talkToServer("/widgets/headlines/en/since/"+lastCheck, "GET", null, null, true).then(res => {
                            // console.log("resp headline", res);
                            if(res && res.data?.length) {

                                this.processNews(res.data);

                            }
                        });

                        setTimeout(() => {
                            talkToServer("/widgets/vidcom/en/since/"+lastCheck, "GET", null, null, true).then(res => {
                                // console.log("resp vidcom", res);
                                if(res && res.data?.length) {

                                    this.processNews(res.data);

                                }
                            });
                        }, 5000);
                    }, number * 60 * 1000);
                }


                initialisePusherWithAuth(true);
                // this.setupNonAuthPusherChannels();

                window.Echo.connector.pusher.connection.bind('state_change', (e) => {
                    if(e.current === 'unavailable') {
                        this.pusherUnavailable();

                    }
                    
                    if(e.current === 'connected' && this.pusherSwal) {
                        this.pusherAvailable();
                        
                    }
                });

                //TEMP
                window.user.date_format = "M d";
                window.user.time_format = "HH:mm";

                this.setupDefaultPusherChannels(this.displayPaymentNotification);
                if(!this.isAutoPilotMode && !this.isExternalClientMode)
                    await this.checkFailedPayments();
            }

            this.getLayouts();

            if(!this.displayTC && this.componentAfterRefresh === 'videos') {
                this.setComponentAfterRefresh('');
            }

            const baseComponent = this.isUserBm ? "client-profiles" : "home-component";
            //temp for development for switching between modes
            if(this.isUserBm)
                this.setComponentAfterRefresh("clients");
            else if(!this.isUserBm && this.componentAfterRefresh === 'clients')
                this.setComponentAfterRefresh("home");

            let nextComp = this.componentAfterRefresh ? Constants.COMPONENTS[this.componentAfterRefresh] : baseComponent;

           
            if((isMobileForDashboards() || this.singleWidgetMode) && !this.isUserBm && nextComp === baseComponent ) {
                this.setComponentAfterRefresh('dashboard');
                nextComp = 'dashboard-component';
            }
            
            // else if(this.isPopOut || this.externalUser) {
            //     nextComp = "dashboard-component";
            // }
            // else {
            //     console.log("fall back");
            //     this.componentToBeRendered = "home-component";
            // }
            if((this.isExternalClientMode || isBrokerDemo()) && !this.isPopOut) {
                this.$router.replace("/");
            }

            if(isMobileForDashboards() || this.singleWidgetMode) {
                const mobiles = _.orderBy(Object.values(this.layouts).filter(el => `${el.id}`.indexOf("mobile_finso") > -1 ), dashboard => dashboard.orderBy, ['desc']);
                let key = mobiles.length ? mobiles[0].id : -1;
                if(this.singleWidgetMode)
                    key = "mobile_finso_" + this.$root.widgetId;

                if(key && key !== -1) {
                    this.setActiveDashboard({ 
                        target: "dashboard",
                        instanceID: this.$root.instanceID,
                        id: key
                    });
                    // this.setActiveDashboard({ 
                    //     target: "video",
                    //     instanceID: this.$root.instanceID,
                    //     id: key
                    // });
                }
                    
            }

            if(this.isPopOut)
                nextComp = 'dashboard-component';

            if(this.specialLink === '/showcaselogin') {
                 this.setComponentAfterRefresh('home');
                nextComp = "home-component";
                // localStorage.removeItem("specialLink");
            }

            this.componentToBeRendered = nextComp;
            // sometimes the component doesn't render
            setTimeout(() => {
                const mainTerminalWindowVisible = document.querySelector('#main-terminal-window');

                if(!mainTerminalWindowVisible)
                    eventBus.$emit("nav-change", {
                        instanceID: this.$root.instanceID,
                        comp: this.componentAfterRefresh || 'home'
                    });
                
            }, 4000);
            

            const startProcessing = new CustomEvent('start-processing');
            document.dispatchEvent(startProcessing);

            if(this.promoID && this.promoID !== Constants.MTA_FREE_ROUTE) {
                this.redirectToCheckout();
            }


            this.handleRibbon();
            const now = usersTime(new Date(), true, true, true, {
                dateFormat: "y-m-d",
                timeFormat: "24hr"
            }, "UTC");
            const eligibilityWindow = addTime(Constants.DASHBOARD_AUTO_CREATE_WINDOW, "seconds", this.user.first_logged_in_at);
            
            if(eligibilityWindow > now) {
                const standardDashboardIDs = this.systemDashboards.filter(el => !el.mobile && !el.vidpage)?.map(el => el.id);
                
                if(standardDashboardIDs?.length)
                    standardDashboardIDs.forEach(el => this.setDashboardAsTab(el));
                
            }

            // this.showIRPTPopUp();

           

            if(!this.externalUser && !this.isPopOut && !window.bootUp.remoteAccess && !this.demoPageMode) {
                let onBoardingPropFound = false;
                allSettings.find(el => {
                    if(el.slug === "welcome-dismissed")
                        this.activateWelcomePopup(el);
                    else if(el.slug === "onboard-steps") {
                        onBoardingPropFound = true;
                        const state = JSON.parse(el.json);
                        const step = parseInt(state?.step);
                        

                        if(step && step > 0) {
                            // state.step = "3";
                            state.step = 8;
                            state.skippable = true;
                            this.loggedOut = true;
                            this.continueOnboardingState = state;
                            if(this.$router.currentRoute.path !== '/start-here')
                                this.$router.push('/start-here');

                        }else{
                            // if(clearStores) {
                                this.$router.replace("/");
                            // }
                            
                        }
                    }

                    if(!onBoardingPropFound /*&& clearStores*/) {
                        this.$router.replace("/");
                    }
                        
                });

                
                // else if(clearStores) {
                //     nextComp = "home-component";

                //     if(isMobileForDashboards())
                //         nextComp = 'dashboard-component';
                // }
  
            }

            if(isOnboard) { 
                if(this.promoID && this.promoID !== Constants.MTA_FREE_ROUTE) {
                    this.loggedOut = false;
                    this.redirectToCheckout();

                }
                else{
                    const step = this.checkoutLoginMode ? 100 : 3;
                    this.continueOnboardingState = { step: step };
                    this.loggedOut = true;
                }
                
                
                if(this.$router.currentRoute.path !== '/start-here')
                    this.$router.push('/start-here');
            }

            if(!this.externalUser) {
                 eventBus.$emit('open-modal', {
                    instanceID : this.$root.instanceID,
                    classes: "terminal-modal simplified",
                    componentInModal: "terminal-moved",
                    mode: "wide",
                });
            }

            if(this.entityLocked && !this.externalUserAutoLogin)
                this.logout();
        },


        verifyPaymentSettingsValidity: function () {
            // console.log("this.isCurrentCardValid, this.activeSubscriptions", this.isCurrentCardValid, this.activeSubscriptions);
            if (!this.isCurrentCardValid) {
                return this.userHasPurchasedProducts;
            }
            return false;
        },
        checkCurrentUserPaymentSettings: function () {
            let cardUpdateRequired = this.verifyPaymentSettingsValidity();
            if (cardUpdateRequired) {
                this.displayCardUpdateNotification();
            } else if (this.isCurrentCardExpiring) {
                this.displayCardExpirationNotification();
            }
        },

        redirectToCheckout() {
            //APR PROMO
            if(this.$router.currentRoute.path !== '/')
                this.$router.push('/');
                
            if(this.promoID === 55) {
                const legacyInPast = this.historicProductAccess.find(el => el.product.slug.indexOf('legacy') > -1 || el.product.slug.indexOf('essential') > -1);
                const condition1 = legacyInPast && this.activePlan.product.id === 20;
                const condition2 = this.activePlan.product.id == 55 || this.activePlan.product.id == 26;
                if(!(condition1 || condition2)) {
                    Swal.fire({
                        title: 'Notice',
                        type: 'info',
                        text: 'You are not eligible for this promotion.',
                        allowOutsideClick: false,
                        showCancelButton: false,
                        confirmButtonColor: '#3B9C83',
                        confirmButtonText: 'OK'
                    }).then(() => {});
                    return;
                }
            }
            //---APR PROMO
            const promo = Object.values(this.availableProducts).find(el => el.id === this.promoID);
            let notFound = true;
            if(promo.product_type === 'plan') {
                //APR PROMO
                // const activeID = this.activePlan.product.parent_id ? this.activePlan.product.parent_id : this.activePlan.product.id;
                // if(activeID === promo.id)
                    // notFound = false;
                    notFound = true;
                //---APR PROMO
            }
            else
                notFound = promo ? this.promotionalProducts.find(el => el.id === promo.id) : true;


            if(!notFound) {
                Swal.fire({
                    title: 'Notice',
                    type: 'info',
                    text: 'You have already purchased this promotion.',
                    allowOutsideClick: false,
                    showCancelButton: false,
                    confirmButtonColor: '#3B9C83',
                    confirmButtonText: 'OK'
                }).then(() => {});
            }
            if(promo && notFound) {
                if(promo.product_type === 'plan') {
                        eventBus.$emit("nav-change", {
                        instanceID: this.$root.instanceID,
                        comp: "checkout",
                        details: {
                            type: "plan",
                            selectedItem: this.mergedPlans.find(el => el.id === this.promoID),
                            isUpgrading: this.isUpgrading
                        }
                    });
                }
                else {
                    
                    TrackingService.sendTransaction(this.activePlan, "purchased_");
                    
                    eventBus.$emit("nav-change", {
                        instanceID: this.$root.instanceID,
                        comp: "checkout",
                        details: {
                            type: "promotion",
                            selectedItem: promo
                        }
                    });
                }
            }
        },

        displayCardUpdateNotification: function () {
            if(isMobileForDashboards())
                return;

            Swal.fire({
                title: 'No payment method!',
                type: 'warning',
                text: 'Please add a card now to ensure your product access continues uninterrupted.',
                showCancelButton: true,
                cancelButtonColor: '#1d9deb',
                confirmButtonColor: '#ff5656',
                confirmButtonText: 'Update card details!'
            }).then((result) => {
                if (result.value) {
                    this.showModal(PaymentCardModal);
                }
            });
        },
        displayPaymentNotification: function (product) {
            if(isMobileForDashboards())
                return;

            Swal.fire({
                title: 'Action Required!',
                type: 'warning',
                text: 'Your recent payment failed. To continue using this product, please update your payment details now.',
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonColor: '#ff5656',
                confirmButtonText: 'Update!'
            }).then((result) => {
                if (result.value) {
                    if(!this.userHasPurchasedProducts) {
                        this.removePaymentMethod().then(() => {});
                    }
                    this.openPaymentSettings(product.invoice_id);
                }
            });
        },
        checkFailedPayments: async function () {
            this.loadPaymentHistoryPromise().then(() => {
                // console.log("checkFailedPayments")
                let currentDate = new Date().getTime();
                let selectedInvoices = [];
                // console.log(this.paymentHistory)
                this.paymentHistory.forEach((payment) => {
                    if(payment.paid_status !== 'full' &&  currentDate - new Date(payment.created_at) <= PAYMENT_LIMIT) {
                        selectedInvoices.push(payment.invoice_id);
                    }
                    // console.log(currentDate - new Date(payment.created_at) <= PAYMENT_LIMIT)
                })

                if(isMobileForDashboards())
                    return;

                // console.log("selectedInvoices.length > 0 && !this.isCurrentCardValid", selectedInvoices.length > 0 && !this.isCurrentCardValid)

                if(selectedInvoices.length > 0 && !this.isCurrentCardValid) {
                    Swal.fire({
                        title: 'Action Required!',
                        type: 'warning',
                        text: 'To access your product you need to add a card and make payment.?',
                        showCancelButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        showCloseButton: false,
                        cancelButtonColor: '#1d9deb',
                        confirmButtonColor: '#ff5656',
                        confirmButtonText: 'Make payment'
                    }).then((result) => {
                        if (result.value) {
                            eventBus.$emit('close-modal');
                            this.$modal.hideAll();
                            setTimeout(() => {
                                eventBus.$emit('open-modal', {
                                    instanceID : this.$root.instanceID,
                                    title: "Account Settings",
                                    componentInModal: "account-settings",
                                    mode: "wide",
                                    panel: {
                                        tab: 'extra',
                                        selectedInvoices: selectedInvoices
                                    }
                                });
                            }, 300)

                        }
                    });
                }
            });

        },
        displayCardExpirationNotification: function () {
            if(isMobileForDashboards())
                return;

            Swal.fire({
                title: 'Your Card is about to expire!',
                type: 'warning',
                text: 'Please update your card before ' + this.toSystemDate(this.paymentMethod.valid_until) + ' to ensure your product access continues uninterrupted.',
                showCancelButton: true,
                cancelButtonColor: '#1d9deb',
                confirmButtonColor: '#ff5656',
                confirmButtonText: 'Update card details!'
            }).then((result) => {
                if (result.value) {
                    this.showModal(PaymentCardModal);
                }
            });
        },



        openPaymentSettings: function (invoiceId) {
            eventBus.$emit('close-modal');
            this.$modal.hideAll();
            setTimeout(() => {
                eventBus.$emit('open-modal', {
                    instanceID : this.$root.instanceID,
                    title: "Account Settings",
                    componentInModal: "account-settings",
                    mode: "wide",
                    panel: {
                        tab: 'extra',
                        selectedInvoices: [invoiceId]
                    }
                });
            }, 300)

        },
        handlePayment: function (product) {
            let action;
            if (product.product_type === "plan") {
                action = this.purchasePlan(product);
            } else {
                action = this.purchaseAddon(product);
            }
            action.then(() => {
                this.hideModal("PaymentCardModal");
            }).catch(() => {
                Swal.fire({
                    title: 'Payment Failed!',
                    type: 'warning',
                    text: 'Please add a new payment card or contact your bank to process this payment',
                    showCancelButton: true,
                    cancelButtonColor: '#1d9deb',
                    confirmButtonColor: '#ff5656',
                    confirmButtonText: 'Ok!'
                })
            })
        },
        logout() {
            if(window.bootUp.remoteAccess) {
                window.bootUp.remoteAccess = false;
                this.autoLogin = false;
            }
            localStorage.removeItem("remoteAccess");
            this.remoteAccess = false;

            this.closeWindowPopouts();

            this.continueOnboardingState = {};

            // if(window.Echo)
            //     window.Echo.disconnect();

            this.leaveDefaultPusherChannels();
            this.clearPusherChannels();
            window.bootUp.isExploreMode = false;

            localStorage.removeItem('token');
            localStorage.removeItem('adfx');
            localStorage.removeItem('adt');
            this.resetDashboards();
            this.destroyUserStoreData();
            window.loggedInUserId = null;
            this.setActiveDashboard({});
            this.setWatchHistory({});
            this.setDbReady(false);
            this.setLastTrainingCentreOrderUpdated({});
            this.setComponentAfterRefresh('');
            workerTimers.clearInterval(window.bootUp.cacheBlocker);
            clearInterval(window.bootUp.headlineFetchJob);
            window.bootUp.headlineFetchJob = null;
            window.bootUp.cacheBlocker = null;
            this.shutDownIntercom();
            this.clearStoreValues();
            this.$store.dispatch("headlines/setRareHeadlinesFetched", false);

            localStorage.removeItem("promo");
            this.promoID = null;
            localStorage.removeItem("specialLink");
            this.specialLink = '';

            if(window.webrtc)
                window.webrtc.stop_audio();

            window.bootUp.iDB.deleteDB();
            delete window.user;
            this.loggedOut = true;
            if(this.isUserBm)
                this.$router.push("/admin-login");
            else
                this.$router.push("/login");
        },
        clearStoreValues() {
            this.$store.dispatch("general/clearStoreValues");
        },
        // getUserSettings() {
        //     // console.log('getting now user settings...');
        //     this.setAnalChatActiveOn(null);
        //     return talkToServer('/setting/user', 'GET', null, null, true).then(response => {
        //         console.log("All user settings", response);
        //         const analChat = response.data.find(item => item.slug === "analyst-chat");

        //         if(analChat) {
        //             this.setAnalChatActiveOn((JSON.parse(analChat.json)).dashboardID);
        //         }

        //         return;

        //     });
        // },
        async setTimezoneOnFirstLogin() {
            const userObj = _.cloneDeep(this.user);
            userObj.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            // console.log(userObj);

            await accountService.saveUserAccountSettings(userObj).then(result => {
                    if(result.data.success) {
                        return this.loadCurrentUserSettings().then(() => {});

                    }
            })
            .catch(() => {
                // const errMessages = [];
                // Object.values(error?.response?.data?.data || {}).forEach(message => errMessages.push(message[0]));
                Swal.fire({
                    title: 'Error',
                    type: 'danger',
                    text: 'Could not set default timezone automatically, please set it manually in your Account Settings.',
                    showCancelButton: true,
                    cancelButtonColor: '#1d9deb'
                });
            });

            // console.log("after", this.user);
        },
        initializeVersionId: function () {
            AppService.getVersionId().then(result => {
                this.setVersionId(result.data);
                this.versionChanged = false;
                this.checkVersion();
            })
        },
        checkVersion: function () {
            let handler = () => AppService.getVersionId().then(result => {
                if(result.data !== this.versionId && this.versionChanged !== true) {
                    this.versionChanged = true;
                    let message = 'There is a new version of the application (#' + result.data + '). </br>Click here to load the new version!';
                    this.$toast.info(message, {
                        duration: 0,
                        onClick: () => {
                            window.location.reload();
                        }
                    });
                }
            })
            setInterval(handler, 300000);
        },
        getLayouts() {
            // console.log("get layouts");
            // let context = this;
            const instanceID = this.$root.instanceID;
            // if(!window.bootUp.isExploreMode) {

            // console.log('layouts', instanceID);
            // if(!this.layouts[instanceID] && this.externalUser) {
            //     this.autoCreateBrokerDashboards();
            // }else{
                const layouts = _.cloneDeep(this.layouts);
                // console.log("layout", this.layouts);
                let currentActiveDashboard = layouts[this.activeDashboard];
                let currentActiveVideoPage = layouts[this.activeVideoPage];

                let dId;
                let vId;
                if(currentActiveDashboard)
                    dId = currentActiveDashboard.id;
                if(currentActiveVideoPage)
                    vId = currentActiveVideoPage.id

                if(this.activeDashboard < 0 || !currentActiveDashboard || !this.activeVideoPage || !currentActiveVideoPage) {

                    const dashboards = Object.keys(layouts).map(key => layouts[key]);
                    // let firstKey = dashboards.find(el => !isNaN(el.id));

                    let activeDashboard, activeVideoPage;

                    if(isMobileForDashboards()) {
                        activeDashboard = null; //dashboards.find(dashboard => dashboard.mobile);
                        activeVideoPage = null;
                    }
                    else {
                        if(this.activeDashboard < 0 || !currentActiveDashboard) {
                            activeDashboard = dashboards.filter(el => !el.vidpage).find(dashboard => {
                                if(dashboard.default)
                                    return dashboard
                                else if(dashboard.broker && instanceID === dashboard.id)
                                    return dashboard
                                // else
                                //     return firstKey;
                            });

                            dId = activeDashboard ? activeDashboard.id : -1;
                        }
                        if(!this.activeVideoPage || !currentActiveVideoPage) {
                            activeVideoPage = dashboards.filter(el => el.vidpage).find(dashboard => {
                                if(dashboard.default)
                                    return dashboard
                                else if(dashboard.broker && instanceID === dashboard.id)
                                    return dashboard
                                // else
                                //     return firstKey;
                            });

                            vId = activeVideoPage ? activeVideoPage.id : -1;

                        }
                    }
                }



                if(this.activeDashboard >= 0 && !this.poppedoutDashboards.includes(this.activeDashboard)) {
                        if(layouts[this.activeDashboard])
                            layouts[this.activeDashboard].tab = true;

                        // console.log("poppedout", Object.values(layouts));
                        this.setSystemDashboards(Object.values(layouts));
                }
                this.setActiveDashboardAndTab({id: dId, instanceID: instanceID});
                this.setActiveDashboard({target: "videopage", id: vId, instanceID: instanceID});
                //     context.layoutFetchDone = true;
            // }
        },
        // autoCreateBrokerDashboards() {
        //     // console.log("autocreating...");
        //     // const targetDiv = this.$root.targetDiv;
        //     let impID = this.$root.widgetId;

        //     const nextID = this.$root.instanceID;

        //     const widget = this.$store.getters["widget/getWidgetByImplementationId"](impID);
        //     const elements = createDashboardElements(widget, "broker");

        //     let code = {
        //         id: nextID,
        //         name: nextID,
        //         broker: true,
        //         elements: elements
        //     };

        //     // console.log("code",code);

        //     this.updateDashboardById(code);
        //     this.setActiveDashboardAndTab({
        //         id: code.id,
        //         instanceID: this.$root.instanceID
        //     });

        //     this.layoutFetchDone = true;

        // },
        activateWelcomePopup(welcome) {

            const panelObj = {
                "welcome-dismissed": welcome ? JSON.parse(welcome.json) : {}
            }

            // console.log(welcome);

            if(!welcome?.["v3-onboard"]) {
                panelObj.active = this.activePlan;
                panelObj.type = 'v3-onboard';

                 if(panelObj.active !== 'Legacy Plan' || panelObj.active !== 'Pro Plan')
                    return;

            }

            eventBus.$emit('open-modal', {
                instanceID : this.$root.instanceID,
                title: "Welcome to your new terminal",
                componentInModal: "welcome-popup",
                mode: "",
                panel: {
                    panelObj: panelObj
                }
            });

        },
        onboardSkipped() {
            if(localStorage.getItem('token') && !this.isPopOut) {

                // console.log("rendering comp.")
                const env = getServerEnv();

                this.componentToBeRendered = "home-component";
                
                if(isMobileForDashboards())
                    this.componentToBeRendered = "dashboard-component";

                if(this.promoID === Constants.MTA_FREE_ROUTE) {
                    const mtas = this.currentAddons.filter(el => el.product.parent_id === Constants.MTA_PROMO[env]);
                    if(!mtas.length)
                        this.componentToBeRendered = "video-component";

                    localStorage.removeItem("promo");
                }
                

                if(this.$router.currentRoute.path !== '/') {
                    this.$router.replace("/");
                }
                

                talkToServer('/setting/user/onboard-steps', "POST", null, { step: "-1" }, true)

                this.loggedOut = false;
            }

        },
        handleRibbon() {
            const comp = this.componentToBeRendered;

            const isThereFailedProduct = this.hasRenewFails;
            if(isAutoPilotMode())
                this.ribbonType = '';
            else if(this.isUserBm && comp !== 'client-profiles' && comp !== 'team-page' && comp !== 'auto-pilot') {
                    this.ribbonType = 'isUserBm';
            }
            else if(!this.isUserBm && comp === 'home-component') {
                // if((!this.isCurrentCardValid || this.isCurrentCardExpiring) && this.activeSubscriptions)
                if(isExternalClientMode()) {
                    this.ribbonType = 'externalAccess';
                }
                else {
                    if(isThereFailedProduct)
                        this.ribbonType = 'updateCard';
                    else if(!this.ribbonType !== 'updateCard' && !this.externalUserAutoLogin)
                        this.ribbonType = 'planUpgrade';
                }

            }else{
                this.ribbonType = '';
            }

        },
        liveStreamStarted(stream) {
            if(!this.isCurrentlyLive(stream.id))
                    this.$toast.error("The " + stream.name + " is live now.<br/>If you have access, you can join via the Training Centre.", {
                    duration: 300000,
                    position: "bottom-left"
                });
        },
        pusherUnavailable() {
            if(!this.pusherOn)
                return;

            this.pusherOn = false;

            const html = `Your connection to our live feed has dropped. Please <strong>refresh your terminal to connect again.</strong><br /><br /> Alternatively, please wait for us to automatically reconnect you. <br /><br /> A successful automatic reconnection will be confirmed here.`
            if(this.pusherSwal)
                this.pusherSwal.close();

            this.pusherSwal = Swal.fire({
                title: 'Connection error',
                html: html,
                type: 'warning',
                confirmButtonColor: '#1d9deb',
                confirmButtonText: 'Refresh now',
                showCancelButton: true,
                cancelButtonColor: '#727B9A',
                cancelButtonText: 'Close'
                // timer: 1500
            });

            this.pusherSwal.then(ev => {
                if(ev.value)
                    location.reload();
            });
        },
        pusherAvailable() {
            if(this.pusherOn)
                return;

            this.pusherOn = true;

            this.pusherSwal.close();
            setTimeout(() => {
                const html = `Your connection has been restored.`
                this.pusherSwal = Swal.fire({
                    title: 'Connection fixed',
                    html: html,
                    type: 'info',
                    confirmButtonColor: '##1d9deb',
                    confirmButtonText: 'OK'
                    // timer: 1500
                });
            }, 200);
        },
        makeRequestOn() {
            this.requestOn = true;
        }
        // showIRPTPopUp() {
        //     if(this.availableWidgets.includes('17') && !this.seenIRPTMessage) {
        //         const html = "Due to maintenance the <strong>Interest Rate Probabilities</strong> indicator will be unavailable on Monday, 27th Feb 2023. We apologise for the inconvenience.";
        //         Swal.fire({
        //             title: 'Service Announcement',
        //             html: html,
        //             type: 'info',
        //             confirmButtonColor: '#1d9deb',
        //             confirmButtonText: 'Don\'t Show Again',
        //             showCancelButton: true,
        //             cancelButtonText: "Dismiss",
        //             cancelButtonColor: "#727B9A",
        //         }).then(r => {
        //             if(r.value)
        //                 talkToServer("/setting/user/irpt-20230227", "POST", null, { seen: true }, true);
        //         });
        //     }
        // }
    },
    watch: {
        componentToBeRendered () {
            this.handleRibbon();
            eventBus.$emit("ANALCHAT_CHECK");


        },
        hasRenewFails() {
            this.handleRibbon();
        },
        readyForCategoryProcessing(val) {
            // console.log("headline val changed.....");
            if(val) {
                this.$store.dispatch("widget/addLookupCategories");
            }
        },
        availableWidgets: function (nVal, oVal) {
            // console.log("avail", nVal, oVal);
            if(this.singleWidgetMode && nVal.length) {
                // console.log("avail", this.$root.instanceID);
                const impID = this.$root.widgetId;
                const widget = this.$store.getters["widget/getWidgetByImplementationId"](impID);
                // console.log(impID, widget);
                const code = widget.code;
                const channel = Constants.PUSHER_CHANNELS[code];
                this.addPusherChannel(channel);
                this.setupPusherChannels(channel);
                return;
            }
            const changedWidgets = diffArray(nVal, oVal);
            if(changedWidgets.length) {
                changedWidgets.forEach(el => {
                    const impID = +el;
                    const widget = this.$store.getters["widget/getWidgetByImplementationId"](impID);
                    const code = widget.code;
                    const mainHeadlineImpID = 5;
                    const hasSquawkLicence = window.bootUp.entity.audio_squawk_licence && impID === mainHeadlineImpID;

                    const channel = Constants.PUSHER_CHANNELS[code];

                    if(channel) {
                        if(nVal.includes(el)) {
                            if(hasSquawkLicence) {
                                this.setOrGetAudioTokens();
                                // console.log("headlines");
                                setTimeout(() => this.setSubscribedToSquawk(true));
                            }

                            if(!this.pusherChannels.includes(channel) &&
                            channel !== 'livestream' &&
                            channel !== 'videolibrary'
                            ) {
                                // console.log("added", channel);
                                // if(!this.isUserBm) {
                                this.addPusherChannel(channel);
                                this.setupPusherChannels(channel);
                                // }
                                // window.Echo.connector.pusher.connection.bind_global(function (event, data) {
                                //     if(data.channel !== 'private-curr-strength')
                                //         console.log(`The event ${event} was triggered with data`, data);
                                // });
                                // console.log(window.Echo);
                                // Object.values(window.Echo.connector.channels).forEach(c => {
                                //     c.bind('pusher:ping', function() {
                                //         console.log('we got pinged') ;
                                //     });
                                // });
                                
                                

                        }



                        }
                        else if(oVal.includes(el)) {
                            // console.log("removed", channel);
                            this.removePusherChannel(channel);
                            this.leavePusherChannels(channel);

                                if(hasSquawkLicence) {
                                    window.webrtc.stop_audio();
                                    this.setSubscribedToSquawk(false);
                                    if(localStorage.getItem("token"))
                                        localStorage.removeItem("playingSquawk");
                                }

                        }
                    }


                });

                eventBus.$emit("ANALCHAT_CHECK");
            }

        },
        activePlan: {
            deep: true,
            handler: function (nVal,oVal) {
                
                if(!oVal && nVal?.product?.is_visible_front_end && !this.isPopOut) {
                    const found = this.allSettings?.find(el => el.slug === "welcome-dismissed");
                    if(!found) {
                        console.log(this.allSettings);
                        this.onboardSkipped();
                    }
                        
                }
                    
            }
        }
    },
    // beforeDestroy() {
        
    // }
}
</script>

<style lang="scss" scoped>
    .platform-background {
        background-color: $onboard-survey-background;
        min-height: 200px;

        &.no-overflow {
            height: 100vh;
            overflow: hidden;
        }
    }

</style>
<style lang="scss">
    .maintenance-section {
        & > div {
            text-align: center;
            color: $input-color-filter;

            & > div:first-child {
                font-size: 40px;
                margin-bottom: 35px;
            }
            & > div:last-child {
                font-size: 18px;
            }
        }
    }
    #main-terminal-window.no-overflow {
        max-height: calc(100vh - 60px);
    }
    #main-terminal-window.client-profiles {
        padding: 0;
    }
</style>
<template>
    <div
    :style="innerElementHeight">
        <!-- TradingView Widget BEGIN -->
        <div class="tradingview-widget-container">
        <div class="tradingview-widget-container__widget"></div>
        <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span class="blue-text">Track all markets on TradingView</span></a></div>
        </div>
        <!-- TradingView Widget END -->
    </div>
</template>

<script>
import { eventBus } from '../../../../main';
export default {
    props: ["details"],
    created() {
        eventBus.$on("widget-resize", obj => {
            if(obj.id === this.details.layoutID) {
                this.height = obj.height;

            }
            
        });
    },
    mounted() {
        const widgetPlaceholder = document.getElementsByClassName('tradingview-widget-container')[0];
        widgetPlaceholder.replaceChildren();
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-forex-heat-map.js'
        script.async = true;
        script.innerHTML = JSON.stringify({
            "width": "100%",
            "height": "100%",
            "currencies": [
                "EUR",
                "USD",
                "JPY",
                "GBP",
                "CHF",
                "AUD",
                "CAD",
                "NZD"
            ],
            "isTransparent": true,
            "colorTheme": "dark",
            "locale": "en"
            });
        widgetPlaceholder.appendChild(script);
    },
    computed: {
        innerElementHeight() {
            const paddingTop = this.height > 550 ? {'padding-top': '25px'} : {};
            return {height: this.height - this.heightAdjustment + 70 + 'px', 'overflow-y': 'auto', 'overflow-x': 'auto', ...paddingTop};
        },
    },
    data() {
        return {
            height: 0,
            heightAdjustment: 180,
        }
    },
}
</script>
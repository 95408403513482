<template>
    <div id="content" :class="{popout: isPopOut}">
        <grid-layout ref="gridlayout" :layout.sync="layoutsArray"
        class="terminal"
        :class="{static: fullscreenId !== 0}"
        :col-num="unit"
        :row-height="elementHeight"
        :is-draggable="true"
        :is-resizable="true"
        :preventCollision="true"
        :vertical-compact="false"
        :use-css-transforms="false"
        @layout-updated="layoutUpdatedEvent"
        >
                <grid-item :key="'item_'+item.i" v-for="item in layoutsArray" 
                        :ref="'grid_item_'+item.i"
                        :resizeFrom="['bottom', 'left']"
                        :x="item.x"
                        :y="item.y"
                        :w="item.w"
                        :h="item.h"
                        :i="item.i"
                        :static="true"
                        :class="{'set-to-full-screen': fullscreenId === item.i}"
                        @container-resized="containerResizedEvent"
            >
                <add-widget v-if="item.emptySlot" :item="item"></add-widget>
                <div 
                v-else
                class="text item" @mouseover="toggleHandlers(item.i, true)" @mouseleave="toggleHandlers(item.i, false)"
                     :ref="'item'+item.i">
                    <div class="widget-wrapper">
                        <widget-frame
                                :showHandlers="true"
                                :layout_id="item.i"
                                :pageType="pageType"
                                :active="active"
                                :implementation_id="item.implementation"
                                :panels="layouts[activeDashboard].panelLayoutID"
                        ></widget-frame>
                    </div>
                    <!-- <span  id="widget-resize-step" class="resize-corner right hoverable-info" ><font-awesome-icon class="red" v-if="!!showHandlers[item.i]"
                        :icon="['fas', 'expand-alt']"/></span>
                    <span class="resize-corner left hoverable-info" ><font-awesome-icon class="red" v-if="!!showHandlers[item.i]"
                        :icon="['fas', 'expand-alt']"/></span> -->
                </div>
                <slot-action-overlay 
                v-if="addingMode"
                :item="item"
                class="slot-action-overlay"
                @slot-selected="slotSelected"
                ></slot-action-overlay>
            </grid-item>
        </grid-layout>
    </div>
</template>

<script>
import {GridLayout, GridItem} from "vue-grid-layout";
import WidgetFrame from './WidgetFrame.vue';
import AddWidget from './AddWidget.vue';
import SlotActionOverlay from './SlotActionOverlay.vue';
import {createNamespacedHelpers} from 'vuex';
import _ from "lodash"
const general = createNamespacedHelpers('general');
const dashboards = createNamespacedHelpers('dashboard');
const payments = createNamespacedHelpers('payments');
const widgets = createNamespacedHelpers('widget');
import Constants from '../../utility/constants';
import {eventBus} from '../../main';
import {getParameterByName, calculatePosition, isMobileForDashboards} from '../../utility/helper-functions';
import TrackingService from '../../services/tracking/tracking.service';
import * as workerTimers from 'worker-timers';

// let mouseXY = {"x": null, "y": null};
export default {
    props: ["active", "panelLayoutID", "pageType"],
    components: {
        GridLayout,
        GridItem,
        WidgetFrame,
        AddWidget,
        SlotActionOverlay
    },
    computed: {
        ...general.mapState(['demoPageMode', 'singleWidgetMode']),
        ...general.mapGetters(['isUserBm', 'singleWidgetMode']),
        ...dashboards.mapState([ 'fullscreenId']),
        ...dashboards.mapGetters(['activeDashboardByInstanceID', 'getDashboardById', 'dashboards', 'layouts', 'activeDashboardInfoByInstanceID', 'getLayoutsByDashboardID', 'analChatActiveOn']),
        ...payments.mapGetters(['availableWidgets']),
        ...widgets.mapGetters(['getWidgetByImplementationId']),
        activeDashboard() {
            return this.activeDashboardByInstanceID(this.$root.instanceID, this.pageType);
        },
        activeDashboardInfo() {
            return this.activeDashboardInfoByInstanceID(this.$root.instanceID, this.pageType);
        },
        // layout() {
        //     return this.layouts[this.activeDashboard] ? this.layouts[this.activeDashboard].layout : [];
        // },
        // layout() {
        // 	return getLayoutsByDashboardID(this.activeDashboard);
        // },
        // empty() {
        //     return this.layoutsArray.length === 0 && !getParameterByName('popout');
        // },
        layout: function () {
            if(this.activeDashboardInfo?.popout && !getParameterByName('popout')) {
                return [];
            }
            // console.log("activee", this.activeDashboard);
            return this.getLayoutsByDashboardID(this.activeDashboard);
        },
        // fullScreenWidget: function () {
        //     return _.cloneDeep(this.layoutsArray.find(element => element.i === this.fullscreenId));
        // },
        isMainDashboardPopout: function () {
            return this.activeDashboardInfo?.popout && !getParameterByName('popout');
        }
    },
    watch: {
        layoutsArray: {
            deep: true,
            handler: function (newValue, oldValue) {
                if (!_.isEqual(newValue, oldValue) && !this.isMainDashboardPopout) {
                    // console.log("layoutsArray watcher", newValue, oldValue);
                    this.updateWidgetLayout([newValue, this.activeDashboard]);
                }
            }
        },
        layout: {
            deep: true,
            handler: function (newValue, oldValue) {
                if (!_.isEqual(newValue, oldValue) && newValue[0]?.i !== 0) {
                    // console.log("layout watcher", newValue, oldValue);
                    this.layoutsArray = _.cloneDeep(newValue).filter(item => !this.disableWidget(item.implementation) || item.emptySlot);
                }
            }
        },
        availableWidgets: {
            deep: true,
            handler: function (newValue, oldValue) {
                if (!_.isEqual(newValue, oldValue)) {
                    this.layoutsArray = _.cloneDeep(this.layout).filter(item => !this.disableWidget(item.implementation) || item.emptySlot);
                }
            }
        },
        activeDashboard: {
            deep: true,
            handler: function () {
                this.panelProps = Constants.PANELLAYOUTIDS[this.layouts[this.activeDashboard].panelLayoutID];
                
                    
                this.unit = this.panelProps.cols;

                this.sendTracking();
                this.setupHeartBeatInterval();
                this.setupHeartBeat();
            }
        },
        fullscreenId(val) {
            let seconds = 0;
            if(!val)
                seconds = 1;

            this.sendTracking(seconds);
            this.setupHeartBeatInterval();
            this.setupHeartBeat();
            
        }
    },
    data() {
        return {
            layoutsArray: [],
            isPopOut: getParameterByName('popout'),
            unit: 4,
            // elementHeight: window.outerHeight / this.unit - 80,
            initialized: false,
            showHandlers: {},
            panelProps: null,
            addingMode: false,
            widgetObjToAdd: null,
            removing: false,
            elementHeight: null,
            heartbeatInterval: null,
            heartbeatSecondsInterval: null,
            heartbeatTimer: 0
        }
    },
    created() {
        // console.log("elements",this.layouts[this.activeDashboard].elements);
        eventBus.$on("find-slot-for-widget", obj => {
            if(this.$root.instanceID === obj.instanceID) {
                this.addingMode = true;
                this.widgetObjToAdd  = obj.widgetObj;
            }
                
        });
        eventBus.$on("added_widget", () => {
            setTimeout(() => {
                this.sendTracking(this.heartbeatTimer);
                this.setupHeartBeatInterval();
                this.setupHeartBeat();
            },500);
            
        });
        const dbID = getParameterByName('dashboard');
        if(dbID)
            this.$store.dispatch('dashboard/setActiveDashboard', {
                target: this.pageType,
                id: parseInt(dbID),
                instanceID: this.$root.instanceID
            });
        // console.log("drag and drop created bottom only", dbID, this.activeDashboard);
        if(this.panelLayoutID)
            this.panelProps = Constants.PANELLAYOUTIDS[this.panelLayoutID];
        else {
            // if(this.activeDashboard < 1)
            //     this.$router.push('/home');
            // else
                this.panelProps = Constants.PANELLAYOUTIDS[this.layouts[this.activeDashboard].panelLayoutID];
            // console.log("existing dashb", this.layouts[this.activeDashboard]);
        }
        this.unit = this.panelProps.cols;
            
        // console.log('created dnd', this.unit);
        if(_.isEmpty(this.layouts[this.activeDashboard].elements)) 
            this.createEmptyLayout();
        // eventBus.$on('scroll-into-widget', obj => {
        //     this.scrollIntoWidget(obj);
        // });
        eventBus.$on("close-widget", obj => {
            if(obj.instanceID === obj.instanceID)
                this.removeItem(obj.layoutID);
        });
        // const dBoardID = getParameterByName('dashboard');
        // if (dBoardID)
        //     this.$store.dispatch('dashboard/setActiveDashboard', {
        //         id: parseInt(dBoardID),
        //         instanceID: this.$root.instanceID,

        //     });


        this.setupHeartBeatInterval();
        this.setupHeartBeat();


    },
    mounted() {
        this.layoutsArray = _.cloneDeep(this.layout).filter(item => !this.disableWidget(item.implementation) || item.emptySlot);
        
        // document.addEventListener("dragover", function (e) {
        //     mouseXY.x = e.clientX;
        //     mouseXY.y = e.clientY;
        // }, false);
        // this.index = randomizeBetween(0,10000);

        // console.log("layouts",this.layouts);
        //on popout the tracking fires twice
        if(!this.isPopOut) {
            this.sendTracking();
        }
        // if(this.singleWidgetMode)
        //     this.elementHeight = 3000;
        setTimeout(() => {
            // console.log("document.querySelector('.irpt-embed')",document.querySelector('.irpt-embed'))
            this.calculateElementHeight();
        },1500);
        if(this.singleWidgetMode && document.querySelector('.fs-loading-notification-' + this.$root.instanceID))
            document.querySelector('.fs-loading-notification-' + this.$root.instanceID).style.display = "none";
        
    },
    updated() {
        if(this.pageType === 'videopage')
            return;

        // if(this.singleWidgetMode)
        //     console.log("updated");
        
    },
    beforeDestroy() {
        if(this.heartbeatInterval)
            workerTimers.clearInterval(this.heartbeatInterval);
        if(this.heartbeatSecondsInterval)
            workerTimers.clearInterval(this.heartbeatSecondsInterval);
        this.heartbeatInterval = null;
        this.heartbeatSecondsInterval = null;
        eventBus.$off('added_widget');
    },
    methods: {
        ...dashboards.mapActions(['setActiveDashboard', 'deleteDashboardById', 'updateDashboardById', 'addDashboard', 'updateWidgetLayout', 'setFullScreenId']),
        disableWidget: function (implementation_id) {
            if(this.pageType === 'videopage' || this.demoPageMode)
                return false;

            if(!this.availableWidgets?.includes(implementation_id+"")) {
                Object.values(this.activeDashboardInfo.elements).forEach(el => {
                    if(implementation_id && el.layout.implementation === implementation_id)
                        this.removeItem(el.layout.i)
                });
                
            }
            return false;
        },
        calculateElementHeight() {
            // if (this.isTerminalUser) {
                // console.log(this.isUserBm)
            if(this.isUserBm) {
                if(this.pageType === 'videopage') {
                    this.elementHeight = (window.innerHeight - 220) / 2;

                    eventBus.$emit("widget-resize", this.elementHeight);
                    return;
                }

                if((isMobileForDashboards() && !this.isPopOut)) {
                    this.elementHeight = (window.innerHeight - 95) / 2;
                    return;
                }
                if(this.isPopOut) {
                    this.elementHeight = (window.innerHeight - 60) / 2;
                    return;
                }
                    
                this.elementHeight = (window.innerHeight - 190) / 2;
            }
            else {
                // if(this.$root.widgetHeight) {
                //     this.elementHeight = (this.$root.widgetHeight - 30) / 2;
                //     return;
                // }
                if(this.singleWidgetMode) {
                    console.log();
                    if(document.querySelector('.irpt-embed')) { //document.querySelector('.irpt-embed')
                        
                        this.elementHeight = (document.querySelector('.irpt-embed').offsetHeight / 2) + 50;
                        // this.elementHeight = 0;
                        // this.elementHeight = (document.querySelector('.irpt-embed').offsetHeight / 2) + 30;
                        // console.log("resizing",this.elementHeight)
                        document.querySelector('.vue-grid-layout').style.height = this.elementHeight * 2 + 'px';
                        
                        return;
                    }
                    
                }
                

                if(this.pageType === 'videopage') {
                    this.elementHeight = (window.innerHeight - 170) / 2;
                    eventBus.$emit("widget-resize", this.elementHeight);
                    return;
                }

                if(isMobileForDashboards() && !this.isPopOut) {
                    this.elementHeight = (window.innerHeight - 95) / 2;
                    return;
                }
                if(this.isPopOut) {
                    this.elementHeight = (window.innerHeight - 60) / 2;
                    return;
                }
                    

                this.elementHeight = (window.innerHeight - 150) / 2;
                
            }
            // }
            // return this.$root.height - 10;
        },
        // scrollIntoWidget: function (layoutID) {
        //     let context = this;
        //     setTimeout(() => {
        //         const ref = 'item' + layoutID;
        //         console.log(context.$refs, ref);
        //         const item = context.$refs?.[ref]?.[0] || context.$refs[ref];
        //         if(item) {
        //             item.scrollIntoView({behavior: "smooth"});
        //         }
        //         // this.$tours['myTour'].nextStep();
        //     }, 800);
        // },
        toggleHandlers: function (id, toggleState) {
            if(this.isTerminalUser) {
                let handlers = _.cloneDeep(this.showHandlers);
                handlers[id] = toggleState;
                this.showHandlers = handlers;
            }
            // for fullscreen handle toggling :show-handlers="showHandlers[fullscreenId]"
            // normal view handle toggling
            //:showHandlers="showHandlers[item.i]"
        },
        moveItem(i) {
            let layout = _.cloneDeep(this.layout);
            const draggedItem = layout.find(item => item.i === i);
            if (draggedItem.h > 10)
                draggedItem.h = 10;
            if (draggedItem.w > 40)
                draggedItem.w = 40;
            this.updateWidgetLayout([layout, this.activeDashboard]);
        },
        calcWH(item, height, width, x, y) {
            const colWidth = item.calcColWidth();
            const rowHeight = item.rowHeight;
            // width = colWidth * w - (margin * (w - 1))
            // ...
            // w = (width + margin) / (colWidth + margin)
            let w = Math.round((width + item.margin[0]) / (colWidth + item.margin[0]));
            let h = Math.round((height + item.margin[1]) / (rowHeight + item.margin[1]));
            // Capping
            w = Math.max(Math.min(w, item.cols - x), 0);
            h = Math.max(Math.min(h, item.maxRows - y), 0);
            return {w, h};
        },
        // drag: function () {
        //     let parentRect = document.getElementById('content').getBoundingClientRect();
        //     let mouseInGrid = false;
        //     if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
        //         mouseInGrid = true;
        //     }
        //     if (mouseInGrid === true && (this.layout.findIndex(item => item.i === 'drop')) === -1) {
        //         this.layout.push({
        //             x: (this.layout.length * 2) % (this.colNum || this.unit),
        //             y: this.layout.length + (this.colNum || this.unit), // puts it at the bottom
        //             w: window.bootUp.grid / 2,
        //             h: window.bootUp.grid / 2,
        //             i: 'drop',
        //             static: false
        //         });
        //     }
        //     let index = this.layout.findIndex(item => item.i === 'drop');
        //     if (index !== -1) {
        //         this.$refs.gridlayout.$children[this.layout.length].$refs.item.style.display = "none";
        //         let el = this.$refs.gridlayout.$children[index];
        //         el.dragging = {"top": mouseXY.y - parentRect.top, "left": mouseXY.x - parentRect.left};
        //         let new_pos = el.calcXY(mouseXY.y - parentRect.top, mouseXY.x - parentRect.left);
        //         if (mouseInGrid === true) {
        //             this.$refs.gridlayout.dragEvent('dragstart', 'drop', new_pos.x, new_pos.y, 2, 2);
        //             DragPos.i = String(index);
        //             DragPos.x = this.layout[index].x;
        //             DragPos.y = this.layout[index].y;
        //         }
        //         if (mouseInGrid === false) {
        //             this.$refs.gridlayout.dragEvent('dragend', 'drop', new_pos.x, new_pos.y, 2, 2);
        //             this.layout = this.layout.filter(obj => obj.i !== 'drop');
        //         }
        //     }
        // },
        // dragend: function () {
        //     let parentRect = document.getElementById('content').getBoundingClientRect();
        //     let mouseInGrid = false;
        //     if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
        //         mouseInGrid = true;
        //     }
        //     if (mouseInGrid === true) {
        //         this.$refs.gridlayout.dragEvent('dragend', 'drop', DragPos.x, DragPos.y, 2, 2);
        //         this.layout = this.layout.filter(obj => obj.i !== 'drop');
        //         this.layout.push({
        //             x: DragPos.x,
        //             y: DragPos.y,
        //             w: window.bootUp.grid / 2,
        //             h: window.bootUp.grid / 2,
        //             i: DragPos.i,
        //             static: false
        //         });
        //         this.$refs.gridLayout.dragEvent('dragend', DragPos.i, DragPos.x,DragPos.y,2,this.unit);
        //         this.$refs.gridLayout.$children[this.layout.length].$refs.item.style.display="block";
        //     }
        // },
        layoutUpdatedEvent: function (newLayout) {
            if(this.panelProps.cols * this.panelProps.rows !== newLayout.length)
                return;
                
            setTimeout(() => {
                if(!this.isMainDashboardPopout) {
                    this.updateLayout(newLayout);
             
                }
            },100);
            
        },
        updateLayout(newLayout) {
            // console.log("newLayout");
            // console.log(newLayout);
            // console.log("layout");
            // console.log(this.layout);
            if (!_.isEqual(this.layout, newLayout)) {
                let activeDashboard = _.cloneDeep(this.activeDashboardInfo);
                newLayout.forEach(widget => {
                    if(!this.layoutsArray.length) {
                        activeDashboard.elements[widget.i] = widget;
                    }
                    else 
                        activeDashboard.elements[widget.i].layout = widget;
                });
                    
                this.updateDashboardById(activeDashboard);
            }
            // else{
            //     this.turnOnAnalChatIfNeeded();
            // }
        },
        removeItem: function (val) {
            // console.log("remove item", val);
            let activeDashboard = _.cloneDeep(this.activeDashboardInfo);
            const thisElement = activeDashboard?.elements[val];
            if(!thisElement || this.removing)
                return;
                
            this.removing = true;
            const slot = thisElement.layout.slot;
            const panelProps = Constants.PANELLAYOUTIDS[activeDashboard.panelLayoutID]
            // console.log(panelProps, slot);
            delete activeDashboard.elements[val];
            
               
            const layout = calculatePosition(slot, panelProps);
            layout.emptySlot = true;
            const item = {
                i: layout.i,
                emptySlot: true,
                layout: layout
            }
            activeDashboard.elements[layout.i] = item;
            this.updateDashboardById(activeDashboard);
            // console.log("active", activeDashboard);
            setTimeout(() => this.removing = false, 500);
            
        },
        switchMovability(id) {
            let layout = _.cloneDeep(this.layout);
            const item = layout.find(element => element.i === id);
            if (item) {
                item.static = !item.static;
                // this.updateLayout(this.layout);
                this.updateWidgetLayout([layout, this.activeDashboard]);
            }
        },
        createEmptyLayout() {
            const cols = this.panelProps.cols;
            const rows = this.panelProps.rows;
    
            // const colWidth = 1;
            const items = cols * rows;
            const layoutArr = [];
            for(let i=1; i<=items; i++) {
               
                const item = this.createEmptyItem(i, this.panelProps);
                layoutArr.push(item);
                // console.log(layoutArr);
                
            }
            this.updateLayout(layoutArr);
        },
        createEmptyItem(i, panelProps) {
            const layout = calculatePosition(i, panelProps);
            layout.emptySlot = true;
            const item = {
                i: layout.i,
                emptySlot: true,
                layout: layout
            }
            return item;
        },
        slotSelected(itemToReplace) {
            
            let activeDashboard = _.cloneDeep(this.activeDashboardInfo);
            delete activeDashboard.elements[itemToReplace.i];
            const layout = calculatePosition(itemToReplace.slot, this.panelProps);
            // console.log(layout);
            this.widgetObjToAdd.layout = { 
                ...this.widgetObjToAdd.layout,
                ...layout
            }
            const i = layout.i;
            activeDashboard.elements[i] = this.widgetObjToAdd;
            activeDashboard.elements[i].layout.slot = itemToReplace.slot;
            // console.log(activeDashboard);
            this.updateDashboardById(activeDashboard);
            
            
            const obj = {
                implementation_id: this.widgetObjToAdd.layout.implementation,
                name: this.getWidgetByImplementationId(this.widgetObjToAdd.layout.implementation).name,
                panel_layout: activeDashboard.panelLayoutID
            }
          
            TrackingService.sendEvent("added_widget", obj);

            // console.log("added", this.heartbeatTimer);
            setTimeout(() => {
                this.sendTracking(this.heartbeatTimer);
                this.setupHeartBeatInterval();
                this.setupHeartBeat();
            },500);

            this.addingMode = false;
            this.widgetObjToAdd = {};
            
        },
        containerResizedEvent() {
            this.$nextTick(() => this.calculateElementHeight());
        },
        setupHeartBeat() {
            if(this.heartbeatInterval)
                workerTimers.clearInterval(this.heartbeatInterval);

            this.heartbeatInterval = workerTimers.setInterval(() => {
                this.sendTracking();
            }, Constants.TRACK_INTERVAL)
        },
        sendTracking(seconds) {
            const dataString = this.getTrackingDataString();
            TrackingService.trackHeartBeat({
                heartbeatLength: seconds,
                lookingAt: dataString
            });
        },
        setupHeartBeatInterval() {
            if(this.heartbeatSecondsInterval)
                workerTimers.clearInterval(this.heartbeatSecondsInterval);

            this.heartbeatTimer = 0;
            this.heartbeatSecondsInterval = workerTimers.setInterval(() => {
                if(this.heartbeatTimer < Constants.TRACK_INTERVAL / 1000) 
                    this.heartbeatTimer++;
                else
                    this.heartbeatTimer = 1;

                // console.log("hb timer",this.heartbeatTimer);
            }, 1000);
        },
        getTrackingDataString() {
            let dataString = ""
            if(this.fullscreenId) {
                return this.layout.find(el => el.i === this.fullscreenId)?.implementation;
            }

            const IDs = this.layoutsArray.map(el => el.implementation).filter(el => !isNaN(el)).join(',');
            dataString = "[" +IDs +"]";
           
            if(!IDs)
                dataString = "empty_dashboard";
            else if(IDs.indexOf(',') === -1)
                dataString = IDs;

            return dataString;
        }
    }
}
</script>
<style scoped lang="scss">
.droppable-element {
  display: inline-block;
  width: 150px;
  text-align: center;
  background: #fdd;
  border: 1px solid black;
  margin: 10px 0;
  padding: 10px;
}
.vue-grid-layout {
  background: #1C1C1C;//$color-01;
}
.vue-grid-layout.static {
  position: static;
  margin-bottom: -50px;
}
// .vue-grid-layout.terminal {
// //   min-height: calc(100vh - 60px);
//     height: calc(100vh - 50%);
// }
.popout .vue-grid-layout {
//   background: $color-01;
  min-height: calc(100vh - 30px);
}
.vue-grid-layout.empty {
    display: none;
}
/* .vue-grid-item:not(.vue-grid-placeholder) {
    background: #ccc;
    border: 1px solid black;
} */
.vue-grid-item {
  overflow: hidden;
  transition: none!important;
  border-radius: 10px;
}
.vue-grid-item .resizing {
  opacity: 0.9;
}
.vue-grid-item .static {
  background: #cce;
}
.vue-grid-item .text {
  font-size: 24px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}
.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}
.vue-grid-item .widget-wrapper {
  height: 100%;
  width: 100%;
}
.vue-grid-item .minMax {
  font-size: 12px;
}
.vue-grid-item .add {
  cursor: pointer;
}
.vue-grid-item.set-to-full-screen {
  top: 10px!important;
  left: 10px!important;
  width: calc(100% - 20px)!important;
  height: calc(100% - 65px)!important;
  z-index: 5;
}
.vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 8px;
  left: 8px;
  /* background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat; */
  // background-position: bottom right;
  padding: 0 8px 8px 0;
  // background-repeat: no-repeat;
  // background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
  z-index: 1;
  color: $color-20;
  font-size: 13px;
  &.inactive {
    color: $color-07;
    cursor: default;
  }
}
.vue-allow-drag {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 8px;
  left: 28px;
  padding: 0 8px 8px 0;
  box-sizing: border-box;
  z-index: 1;
  color: $color-20;
  font-size: 13px;
  & button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  & .red {
    color: $color-12;
  }
  & .green {
    color: $color-13;
  }
}
.resize-corner {
  position: absolute;
  bottom: 5px;
  pointer-events: none;
  font-size: 20px;
  color: $color-20;
  &.right {
    right: 7px;
    transform: rotateX(180deg);
  }
  &.left {
    left: 7px;
  }
}
.layoutJSON {
  background: #ddd;
  border: 1px solid black;
  margin-top: 10px;
  padding: 10px;
}
.layoutJSON {
  background: #ddd;
  border: 1px solid black;
  margin-top: 10px;
  padding: 10px;
}
.columns {
  -moz-columns: 120px;
  -webkit-columns: 120px;
  columns: 120px;
}
.remove {
  position: absolute;
  right: 2px;
  top: 0;
  cursor: pointer;
}
// .popout {
//     display: inline-block;
//     width: 150px;
//     text-align: center;
//     background: #dff;
//     border: 1px solid black;
//     margin-top: 10px;
//     margin-left: 10px;
//     padding: 10px;
//     color: black;
//     text-decoration: none;
// }
// nav ul {
//     display: flex;
//     margin: 10px 0;
//     padding: 0;
// }
// nav ul li {
//     list-style: none;
// }
// nav ul li:nth-child(3) {
//     margin-left: auto;
// }
</style>
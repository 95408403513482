<template>
    <div :class="fontsize">
        <div class="main-video-grid"
        :style="innerElementHeight">
            <div v-if="archiveVideos.resultsReady && upcomingVideos.resultsReady && !bulkVideos.length" class="no-more-items"><font-awesome-icon :icon="['fas', 'exclamation-triangle']" /> No videos available</div>
            <div v-else class="main-video-grid-center">


                <div v-if="videoInPlayer[0] && videoInPlayer[0].status === 'scheduled'">
                    <div v-for="countdown in countDownArray"
                    :key="countdown.deadLine">
                        <component
                        :is="'count-down'"
                        :impID="impID"
                        :details="countdown"
                        :video="videoInPlayer[0]"
                        ></component>
                    </div>
                </div>

                <div
                class="terminal-count-down grey"
                v-else-if="videoInPlayerLive && liveEnded === videoInPlayer[0].id"><h2>This live stream has now ended. The recording will appear under the archives section when it's processed.</h2>
                </div>

                <div v-else>
                    <div v-for="video in videoInPlayer"
                    :key="video.src">
                        <component 
                        :is="currentPlayer" 
                        :video="video" 
                        :key="video.src"
                        :layoutID="details.layoutID"
                        :code="details.widgetCode"
                        :impID="impID"
                        :isLocked="isLocked"
                        :videoAutoPlay="videoAutoPlay"
                        @allow-player-render="allowPlayerRender"
                        @stream-ended="endLive"
                        ></component>
                    </div>
                </div>

                <div>
                    <div v-if="details.widgetCode === 'TCSTREAM' && isThereUpcoming && entityQnaToShow" id="video-question">
                        <form
                        v-if="defaultActiveTab === 'upcomingVideos'"
                        @submit.prevent>
                            <input 
                            v-model="question"
                            
                            type="text" :placeholder=" videoInPlayerLive ? 'Ask a question' : 'Wait for a live event to ask a question'"
                            >
                            <button 
                            @click="submitQuestion"
                            
                            ><font-awesome-icon :icon="['fas', 'paper-plane']"/></button>
                        </form>
                        
                    </div>
                    <div v-else id="video-custom-block">

                    </div>
                

                    <div class="video-selection">
            
                        <video-list
                        :origin="details.widgetCode"
                        :tabs="tabs"
                        :defaultActiveTab="defaultActiveTab"
                        :listStyle="playlistHeight"
                        :noItemMessage="'No events'"
                        :sortedItems="sortedItems.videos"
                        :resultsReady="sortedItems.resultsReady"
                        :currentSection="undefined"
                        :questions="currentQuestionsForVideo"
                        @load-more="loadMore"
                        :noMoreItems="sortedItems.noMoreItems"
                        :layoutID="details.layoutID"
                        :newVideo="newVideo"
                        :widgetCode="details.widgetCode"
                        :impID="impID"
                        :isLocked="isLocked"
                        @new-video-viewed="newVideo = false"
                        @render-video="renderVideo"
                        @playlist-changed="changePlaylist"
                        ></video-list>
                
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '../../main';
import CountDown from '../../components/ui/CountDown';
import Swal from 'sweetalert2';
import StreamPlayer from './components/VideoPlayers/StreamPlayer'
import VideoList from './components/VideoPlayers/VideoList';
import VideoPlayer from './components/VideoPlayers/VideoPlayer';
import VideoMixin from '../../mixins/video';
import { createNamespacedHelpers } from 'vuex';
import { talkToServer } from '../../utility/helper-functions';
import _ from "lodash";

// import VideoQuestion from './components/VideoQuestion';
const livestreamsNS = createNamespacedHelpers('webinars');
const payments = createNamespacedHelpers('payments');
const general = createNamespacedHelpers('general');

export default {
    props: ["details"],
    mixins: [VideoMixin],
    components: {
        CountDown,
        StreamPlayer,
        VideoList,
        VideoPlayer
    },
    created() {
        eventBus.$on("widget-resize", obj => {
            if(obj.id === this.details.layoutID) {
                
                if(this.details.widgetCode === 'TCSTREAM') {
                    
                    const innerHeight = obj.height - this.heightAdjustment + 145;
                    this.playlistHeight = {height: innerHeight - (80 + 43) + 'px'};
                    
                    this.innerElementHeight = {height: innerHeight + 'px', 'overflow-y': 'auto', 'overflow-x': 'hidden'};
                }
                else{
                    this.playlistHeight = {height: obj.height - this.heightAdjustment + 'px'};
                

                    const pageAdj = this.details.pageType === 'videopage' ? 95 : 150;
                    const innerHeight = obj.height - this.heightAdjustment + pageAdj;

                    this.innerElementHeight = {height: innerHeight + 'px', 'overflow-y': 'auto', 'overflow-x': 'hidden'};

                    // const playerHeight = document.querySelector('.widget-' + this.details.layoutID + ' .main-video-grid-center > div:first-child')?.clientHeight;
                    if(!this.details.isMdWidget) {
                        this.playlistHeight = { height: 'auto' };
                    }
                }
                
            }
            
        }); 

        const obj = {
            menu: {
                // isSearchEnabled: false,
                // isGlossaryEnabled: false,
                isVideoQuestionEnabled: true,
            }
            
        }
    
        this.$emit('set-frame', obj);

        this.fetchInitialStreams();
        
    },
    beforeDestroy() {
        //add disconnect participant code https://app.asana.com/0/830439190133212/1202098893784602
        if(this.videoInPlayerLive)
            console.log("bd disconnect");
    },
    computed: {
        ...livestreamsNS.mapGetters(['isCurrentlyLive']),
        ...payments.mapGetters(['videoWidgetsForShop']),
        ...general.mapGetters(['entityQnaToShow', 'userQnaOn']),
        impID() {   
            return this.details.implementation_id;
        },
        fontsize() {
            return 'medium-font';
            // if(this.details.userSettings)
            //     return this.details.userSettings.fontsize;
            
            // return '';
        },
        isSmWidget() {
            return this.details.isSmWidget;
        },
        currentPlayer() {
            return this.isUpcomingActive ? "stream-player" : "video-player";
        },
        heightAdjustment() {
            if(this.details.width >= 417) {
                if(this.details.pageType === "videopage")
                    return 150;

                return 175; //200
            }

            return 170;
        },
        beforeMessage() {
            return "'" + this.videoInPlayer[0].title + "'" + " begins streaming in:";
        },
        isThereUpcoming() {
            return this.bulkVideos.filter(el => el.status === 'scheduled' || el.status === 'live').length;
        },
        defaultActiveTab() {
            if(this.isThereUpcoming)
                return 'upcomingVideos'
            
            return 'archiveVideos';
        },
        tabs() { 
            const standardTabs = [
                {
                    id: "archiveVideos",
                    name: "Archives"
                },
                {
                    id: "questions",
                    name: "Topics",
                    count: this.currentQuestionsForVideo?.length,
                    canDisable: true,
                    disabled: this.currentQuestionsForVideo?.length === 0
                }
            ];

            if(this.isThereUpcoming)
                return [ 
                    {
                        id: "upcomingVideos",
                        name: "Upcoming"
                    },
                    ...standardTabs
                ];

            
            return standardTabs;
            
        },
        videoInPlayerLive() {
            return this.videoInPlayer[0] && this.videoInPlayer[0].status === 'live';
        },
        isShowCase() {
            
            return this.videoWidgetsForShop.find(el => el.implementation_id === this.impID)?.planDetails?.planWidgetDetails?.showcase;
        }
       
    },
    data() {
        return {
            videoInPlayer: [],
            allowRender: true,
            activeVideo: null,
            countDownArray: [],
            liveEnded: null,
            timeout: null,
            playlistHeight: '',
            innerElementHeight: '',
            // defaultActiveTab: '',
            thisVideoTag: "The next",
            question: '',
            videoAutoPlay: false
            
        }
    },
    provide() {
        return {
            $activeVideo: () => this.activeVideo
        }
    },
    methods: {
        ...livestreamsNS.mapActions(['addCurrentLiveID', 'removeCurrentLiveID']),

        allowPlayerRender(trueOrFalse) {
            this.allowRender = trueOrFalse;
            // console.log("allow", trueOrFalse);
        },
        renderVideo(obj) {

            this.allowPlayerRender(true);

            this.isUpcomingActive = obj.src.indexOf("mux.com") > -1 ? true : false;
            
            this.videoInPlayer = [obj];
            this.activeVideo = obj.id;

            if(this.upcomingActive) {
                const index = Object.values(this.upcomingVideos.videos).flat().findIndex(el => el.id === this.activeVideo);

                if(index === 0)
                    this.thisVideoTag = "The next";
                else 
                    this.thisVideoTag = "This";
            }

            if(obj.status === "scheduled") {
                this.currentQuestionsForVideo = [];
                this.countDownArray.splice(0, 1, {
                    afterMessage: 'Please wait for the host to start the session...',
                    beforeMessage: this.beforeMessage,
                    deadLine: this.videoInPlayer[0].time,
                });
            }
            else{
                this.fetchQuestionsForVideo(obj.id).then(response => {

                    this.currentQuestionsForVideo = response;
                });
            }

            this.$nextTick(() => eventBus.$emit("widget-resize", {
                id: this.details.layoutID,
                height: this.details.height,
                
            }));
            setTimeout(() => {
                this.videoAutoPlay = true;
            }, 500);
            
        },
        setReminder() {
            eventBus.$emit('open-modal', {
                instanceID : this.$root.instanceID,
                title: "Set Reminder",
                componentInModal: "stream-reminder",
            });
        },
        cancelReminder() {
            Swal.fire({
                title: 'Are you sure?',
                type: 'warning',
                text: 'You won\'t be notified about this webinar.',
                showCancelButton: true,
                cancelButtonColor: '#1d9deb',
                cancelButtonText: 'Keep Reminder',
                confirmButtonColor: '#ff5656',
                confirmButtonText: 'Cancel Reminder'
            }).then((result) => {
                if (result.value) {
                    eventBus.$emit("close-modal");
                }
            });
        },
        endLive(id) {
            this.liveEnded = id;
            this.removeCurrentLiveID(id);
            //add disconnect participant code https://app.asana.com/0/830439190133212/1202098893784602
        },
        changePlaylist(obj) {
            this.currentQuestionsForVideo = [];
            this.upcomingVideos.noMoreItems = false;
            this.archiveVideos.noMoreItems = false;
            if(obj === 'upcomingVideos') {
                this.archiveActive = false;
                this.upcomingActive = true;
            }
            else{
                this.upcomingActive = false;
                this.archiveActive = true;
            }
            setTimeout(() => {
                this.videoAutoPlay = true;
                eventBus.$emit("widget-resize", {
                    id: this.details.layoutID,
                    height: this.details.height,
                    
                })
            }, 200);


            // console.log("u, a", obj, this.upcomingActive, this.archiveActive)

            this.fetchInitialStreams();
        },
        submitQuestion() {
            if(!this.question.length)
                return;

            if(this.isLocked || !this.userQnaOn) {
                this.showBrokerRequest(this.details.implementation_id);
                return;
            }

            let success, slug;
            let query = {};

            query.question = this.question;
            slug = `/widgets/live-streams/${this.videoInPlayer[0].id}/question`;
            success = 'Your question was successfully submitted';

            talkToServer(slug, "POST", null, query, true).then(response => {
                if(response && response.data && (response.data.success || response.success)) {
                    this.question = '';

                    Swal.fire({
                        title: 'Success!',
                        type: 'info',
                        text: success,
                        showCancelButton: false
                    });

                    // this.$emit("close-slide-down");
                }else{
                    Swal.fire({
                        title: 'Error!',
                        type: 'warning',
                        text: "Could not submit question. Please try again later.",
                        showCancelButton: false
                    });
                }
            }).catch(() => {
                
            })
            

        }
    },
    watch: {
        upcomingVideos: {
            handler(value) {
                if(value.resultsReady === true && 
                this.allowRender && this.isThereUpcoming) {

                    let firstVideo;
                    let i = 0;
                    const list = this.upcomingVideos.videos
                    for(const day in list) {
                        if(i === 0) {
                            firstVideo = list[day][0];
                            i++;
                            // this.thisVideoTag = "The next";
                        }
                            
                    }


                    if(firstVideo) {

                        const obj = {
                            id: firstVideo.id,
                            src: firstVideo.playback_url,
                            title: firstVideo.name,
                            time: firstVideo.starts_at,
                            status: firstVideo.status
                        };

                        this.videoInPlayer = [obj];
                        this.activeVideo = obj.id;

                        

                        if(obj.status === "scheduled") {
                            this.countDownArray.splice(0, 1, {
                                afterMessage: 'Please wait for the host to start the session...',
                                beforeMessage: this.beforeMessage,
                                deadLine: this.videoInPlayer[0].time,
                            });
                        }

                        this.firstRender = false;
                    }
                }
            },
            deep: true
        },
        archiveVideos: {
            handler(value) {
                if(value.resultsReady === true && 
                this.allowRender && !this.isThereUpcoming && !_.isEmpty(this.archiveVideos.videos)) {


                    let firstVideo;
                    let i = 0;
                    const list = this.archiveVideos.videos
                    for(const day in list) {
                        if(i === 0) {
                            firstVideo = list[day][0];
                            i++;
                            // this.thisVideoTag = "The next";
                        }
                            
                    }


                    if(firstVideo) {

                        const obj = {
                            id: firstVideo.id,
                            src: firstVideo.playback_url,
                            title: firstVideo.name,
                            time: firstVideo.starts_at,
                            status: firstVideo.status
                        };


                        this.renderVideo(obj);


                        this.firstRender = false;
                    }
                }
            },
            deep: true
        },
        lastLiveStreamUpdate(val) {
            if(val.widget_implementation_id !== this.impID)
                return;

            //not a great way to handle highlights for updates or fresh news, but working for now
            // clearTimeout(this.highlightTimeOut);
            // this.highlightTimeOut = setTimeout(() => {
            //     this.removeHighlight();
            // },1800);

            // console.log("upcoming, archive (active)", this.upcomingActive, this.archiveActive);

            const ID = val.id;
            let bulkVideos = [...this.bulkVideos];
            const index = bulkVideos.findIndex(element => element.id === ID);


            if(index > -1) {
                bulkVideos.splice(index, 1, val);
                // console.log("removed", index, "added", val);
            }else{
                // console.log("simply pushed", val);
                bulkVideos.push(val);
                this.newVideo = true;
                // playSound();
            }

            this.bulkVideos = bulkVideos;

            if(this.allowRender) {
                // if(archiveActive) {
                    // console.log("allowRender")
                    this.changePlaylist("upcomingVideos");
                // }
                
                const obj = {
                    id: val.id,
                    src: val.playback_url,
                    title: val.name,
                    published_at: val.starts_at,
                    status: val.status
                };

                this.videoInPlayer = [obj];
                this.activeVideo = obj.id;

                if(val.status === "live") {
                    this.addCurrentLiveID(val.id);

                    setTimeout(() => {
                        this.videoInPlayer = [obj];
                    }, 2500);
                    
                }else if(val.id === this.isCurrentlyLive(val.id) &&
                val.status === "finished") {
                    this.removeCurrentLiveID(val.id);
                } 

            }
            // else{
                
            // }
                
        },
        lastLiveStreamDelete(val) {
                
            let bulkVideos = [...this.bulkVideos];
            const index = bulkVideos.findIndex(element => element.id === val);

            if(index > -1) {
                bulkVideos.splice(index, 1);
            }

            this.bulkVideos = bulkVideos;
        },
        videoInPlayerLive() {
            // console.log("videoInPlayerLive", nVal,oVal);
            // if(nVal && !oVal)
            //     console.log("connect");
            // else if(!nVal && oVal)
            //     console.log("disconnect")
            
        }
    }
    
}
</script>
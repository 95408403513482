import { createNamespacedHelpers } from "vuex";

const general = createNamespacedHelpers("general");

export default {
 
    data() {
        return {
            // seenIRPTMessage: false
        }
    },
    methods: {
        ...general.mapActions(["setRuleSet", "setWarningDismissed"]),
        saveUserData(response) {
            // console.log(response)
            this.setRuleSet(response.data.user.ruleSet);
            localStorage.setItem('token', response.access_token);
            this.$store.dispatch("tweetdeck/clearFetchBoards");
            // console.log("user data",response);
            if(response.data.user.userList)
                this.$store.dispatch("rules/setUserList", response.data.user.userList);

            window.user = response.data.user.user;
            window.loggedInUserId = response.data.user.internal_user_id;
            const warningDismissed = response.data.user.associated_data.settings.find(el => el.slug === 'squawk-warning202308');

            if(warningDismissed)
                this.setWarningDismissed()

            // this.seenIRPTMessage = response.data.user.associated_data.settings.find(el => el.slug === "irpt-20230227");

            const reminders = response.data.user.associated_data.live_stream.active_reminders;
            const renewfails = response.data.user.associated_data.product_access.filter(el => el.renewfail_activated_at);
            this.$store.dispatch("general/setRenewFails", renewfails);
            const historicProductAccess = response.data.user.associated_data.historic_product_access
            this.$store.dispatch("payments/setHistoricProductAccess", historicProductAccess);

            let watchHistory = response.data.user.associated_data.settings.find(el => el.slug === "watch-history");

            //temp until live
            const watchHistoryVersion = 1;
            if(watchHistory?.json) {

                watchHistory = JSON.parse(watchHistory.json);
                if(!watchHistory.recent_courses)
                    watchHistory.recent_courses = {};
                if(!watchHistory.TCSTREAM)
                    watchHistory.TCSTREAM = {};
                if(!watchHistory.TCVIDEO)
                    watchHistory.TCVIDEO = {};
                if(!watchHistory.VIDCOM)
                    watchHistory.VIDCOM = {};
                
            }else{
                watchHistory = {
                    recent_courses: {},
                    TCSTREAM: {},
                    TCVIDEO: {},
                    VIDCOM: {},
                };
            }

            // console.log(watchHistory);

            if(watchHistory.version !== watchHistoryVersion) {
                Object.entries(watchHistory).forEach((key) => {
                    // console.log("deleting");
                    const obj = key[1];
                    Object.entries(obj).forEach((k) => {
                        
                        delete k[1].src;
                        delete k[1].status;
                        delete k[1].time;
                        delete k[1].title;
                        delete k[1].published_at;
                    });
                });

                watchHistory.version = watchHistoryVersion;

            }

                
            //temp ends
            
            this.$store.dispatch("general/setWatchHistory", watchHistory);
            
            if(reminders)
                this.$store.dispatch("webinars/setActiveReminders", reminders);

            const ratings = response.data.user.associated_data.content_scoring_rated;

            this.$store.dispatch("widget/setRatings", ratings);

        }
    },
}
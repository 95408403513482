<template>
    <div v-if="resultsReady"
    class="irpt-embed">
        <embed-widget-header
        :details="details"
        ></embed-widget-header>
        <div class="irpt-embed-header">
            <div class="irpt-embed-header-title"><h3>Interest Rate Probability Tracker</h3></div>
            <div class="irpt-embed-header-nav">
                <nav>
                    <ul>
                        <li v-for="bank in banks"
                        :key="bank.central_bank_code">
                            <a 
                            @click="switchBank(bank.central_bank, bank.central_bank_code)"
                            href="javascript:void(0);"
                            :class="{'active-bank': modals[0] === bank.central_bank_code}">
                                <span>
                                    <img 
                                    :src="flagSrc(bank.central_bank_code)" :alt="bank.central_bank + ' flag'" />
                                </span>
                                <span>{{ bank.central_bank_code }}</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <div>

        </div>
            
            <div v-for="modal in modals"
            :key="modal">
                <interestrate-modal
                :panel="panelForEmbed"
                ></interestrate-modal>
            </div>
            <div class="fs-social-share">
                <div></div>
                <div class="fs-social-share-field">
                    <div class="fs-social-share-field-cta">Share</div>
                    <div class="fs-social-share-field-buttons">
                        <a @click="clickToCopyLink" style="cursor: pointer;" target="blank" class="copy-link-fs"><span class="tooltiptext" v-if="showToolTip">Copied to clipboard</span><img :src="createImgUrl('social-link')" /></a>
                        <a :href="createHref('twitter')" target="blank"><img :src="createImgUrl('social-twitter')" /></a>
                        <a :href="createHref('linkedin')" target="blank"><img :src="createImgUrl('social-linkedin')" /></a>
                        <a :href="createHref('facebook')" target="blank"><img :src="createImgUrl('social-facebook')" /></a>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
import InterestrateModal from './components/InterestRate/InterestrateModal.vue';
import EmbedWidgetHeader from './components/EmbedWidgetHeader.vue';
import ContentLockedMixin from '../../mixins/contentLockedMixin';
import IrptMixin from '../../mixins/irptMixin';
import { frontFacingAssets, isEmbeddedOnFS } from '../../utility/helper-functions';

export default {
    props: ["details"],
    // created() {
    //     console.log(this.details)
    // },
    mixins: [IrptMixin, ContentLockedMixin],
    components: {
        EmbedWidgetHeader,
        InterestrateModal
    },
    computed: {
        
    },
    data() {
        return {
            modals: [],
            validUrl: true,
            showToolTip: false
        }
    },
    methods: {
        flagSrc(code) {
            let src = frontFacingAssets() + code + '.png';
            return src;
        },
        switchBank(bank, code) {
            if(isEmbeddedOnFS()) {
                const slug = this.createSlug(bank);
                window.open(slug, "_blank");
            }
            else {
                this.renderBank(code);
                
            }
            
            
        },
        createSlug(bank) {
            return "/indicators/" + bank.toLowerCase().replaceAll(' ', '-') + "-interest-rate-probability-tracker/";
        },
        renderBank(code) {
            this.modals = [code];
            this.panelForEmbed = {
                bank: this.banks.find(el => el.central_bank_code === code),
                isLocked: this.isLocked
            }
        },
        createImgUrl(filename) {
            return frontFacingAssets() + filename + '.svg';
        },
        createHref(media) {
            const link = window.location.href;
            // const encodedLink = encodeURI(link);
            const message = encodeURIComponent('Hey, I have found this indicator on Financial Source');
            let title = ' ' + this.createSlug(this.panelForEmbed.bank.central_bank)?.replace('/indicators/', '').replaceAll('-', ' ').replace('/','');
            
            let space = 0;
            const titleArr = title.split("");
            titleArr.forEach((el, i) => {
                if(el === ' ') {
                    space = i;
                }
                if(space + 1 === i) {
                    titleArr[i] = el.toUpperCase();
                }
            });
            titleArr.splice(0,1);
            title = titleArr.join('');
            title = encodeURIComponent(title);
            
            if(media === 'link') {
                return link;
            }
            if(media === 'twitter') {
                return `http://twitter.com/share?&url=${link}&text=${message}`; //&hashtags=comma,separated
            }
            if(media === 'linkedin') {
                return `https://www.linkedin.com/sharing/share-offsite/?url=${link}`;
            }
            if(media === 'facebook') {
                return  `https://www.facebook.com/share.php?u=${link}`;
            }
        },
        clickToCopyLink() {
            const link = this.createHref('link');
            navigator.clipboard.writeText(link).then(() => {
                // console.log("Copied to clipboard",link);
                this.showToolTip = true;
                setTimeout(() => {
                    this.showToolTip = false;
                },1500)
            });
        }
    },
    watch: {
        resultsReady(val) {
            if(val) {
        
                if(!isEmbeddedOnFS()) {
                    this.renderBank(this.banks[0].central_bank_code);
                    return;
                }
                
                const path = window.location.pathname;
                const found = this.banks.find(el => {
                    // console.log(this.createSlug(el.central_bank), path, this.createSlug(el.central_bank) === path);
                    return this.createSlug(el.central_bank) === path;
                });
                console.log(path, found, this.banks)
                if(found)
                    this.renderBank(found.central_bank_code);
                else {
                    this.renderBank(this.banks[0].central_bank_code);
                    if(isEmbeddedOnFS())
                        this.$router.replace(this.createSlug(this.banks[0].central_bank));
                        // window.location.href = this.createSlug(this.banks[0].central_bank);
                }
            }

        }
    }
}
</script>

<style lang="scss" scoped>
    .irpt-embed {
        & .irpt-embed-header {

        }
        & .irpt-embed-header-title h3 {
            text-transform: uppercase;
            color: $input-color;
            font-size: 16px;
            font-weight: 600;
            margin: 0;
            padding: 10px 0;
            text-align: center;
            letter-spacing: 0.2px;

        }
        & .irpt-embed-header-nav {
            padding: 10px 0;
            border-top: 1px solid $partner-border-4;
            border-bottom: 1px solid $partner-border-4;
        }
        & .irpt-embed-header-nav ul {
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }
        & .irpt-embed-header-nav ul li {
            list-style-type: none;
            display: flex;
        }
        & .irpt-embed-header-nav ul li:not(:last-child) {
            list-style-type: none;
            display: flex;
            margin-right: 5px;
        }
        & .irpt-embed-header-nav ul li a {
            color: $input-color;
            font-size: 16px;
            padding: 4px;
            border: 1px solid transparent;

        }
        & .irpt-embed-header-nav ul li a.active-bank {
            border-color: $partner-dimmed-text-2;
            background-color: $client-border-2;
            border-radius: 4px;
        }
        & .irpt-embed-header-nav ul li a img {
            width: 20px;
            margin-bottom: -5px;
            margin-right: 5px;
        }
    }
    .fs-social-share {
        margin-top: 15px;
        margin-right: 5px;
        display: flex;
        justify-content: flex-end;

        & .fs-social-share-field {
            display: flex;
            align-items: center;
            padding: 10px 15px;
            background-color: $partner-background-2-25;
            border: 1px solid $partner-border-1;
            border-radius: 10px;

            & .fs-social-share-field-cta {
                font-size: 10px;
                color: $input-color-filter;
                margin-right: 15px;
            }
            & .fs-social-share-field-buttons {
                & a {
                    height: 30px;

                    &:not(:last-child) {
                        margin-right: 7.5px;
                    }
                }
                display: flex;
                
                justify-content: space-around;
                align-items: center;
            }
        }

        .copy-link-fs {
            position: relative;
            display: inline-block;

            & .tooltiptext {
                background-color: $partner-background-2;
                border: 1px solid $partner-border-1;
                color: $input-color-filter;
                font-size: 10px;
                text-align: center;
                border-radius: 4px;
                padding: 5px;
                position: absolute;
                z-index: 1;
                bottom: 150%;
                left: -50%;
                // margin-left: -75px;
                transition: opacity 0.3s;
            }

            & .tooltiptext::after {
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -5px;
                
            }
        }
    }
</style>
<template>
    
    <div class="data-table-row">

        <div class="data-table-row-main">
            <div class="data-table--cell"
            :style="cellStyles"
            >{{ formattedDate }}</div>
        </div>

        <div class="data-table-row-subrows">

            <calendar-event
            v-for="(calEvent, index) in calendarDay"
            :key="calEvent.id"
            :calEvent="calEvent"
            :details="details"
            :calHeight="calHeight"
            :nextTimeStamp="nextTimeStamp"
            :eventIndex="index"
            :nextEventIndex="nextEventIndex"
            :countrySelection="countrySelection"
            :layoutID="layoutID"
            :autoScroll="autoScroll"
            :singleLine="calendarDay.length===1"
            :isLocked="isLocked"
            @upcoming-event-position="emitUpcomingEventPosition"
            @check-next-event="$emit('check-next-event')"
            ></calendar-event>

        </div>

    </div>
    
</template>

<script>
import dayjs from 'dayjs';
import CalendarEvent from './CalendarEvent';
import calStyleMixin from '../../../../mixins/calStyleMixin';

export default {
    props: ["calendarDay", "day", "details", "calHeight", "layoutID", "autoScroll", "countrySelection", "isLocked", "nextTimeStamp", "nextEventIndex"],
    mixins: [calStyleMixin],
    components: {
        CalendarEvent
    },
    computed: {
        formattedDate() {
            return dayjs(this.day).format("ddd Do MMM");
        }
    },
    methods: {
        emitUpcomingEventPosition(position) {
            this.$emit("upcoming-event-position", position);
        }
    },
}
</script>
<template>
    <div class="sunset-ribbon">Our Daily Trade Analysis livestreams will no longer be available after September 30th 2022. <a href="javascript:void(0);" @click="openSunsetMessage">Learn more.</a></div>
</template>

<script>
import { eventBus } from '../../../../main';
export default {
    methods: {
        openSunsetMessage() {
            eventBus.$emit('open-modal', {
                instanceID : this.$root.instanceID,
                componentInModal: "sunset-popup",
                classes: "terminal-modal simplified",
                mode: "",
            });
        }
    }
}
</script>

<style lang="scss">
    .sunset-ribbon {
        padding: 4px 12px;
        line-height: 18px;
        font-size: 14px;
        text-align: center;
        background-color: $core-lightred;
        color: $input-color;

        & a {
            color: $input-color;
        }

        @include respond(md) {
            padding: 8px 12px;
            line-height: unset;
        }
    }
</style>
import HttpClient from "./HttpClient";
import Swal from "sweetalert2";

export default (error) => {
    let errorStatus = HttpClient.isCancel(error) ? -100 : error?.response?.status;
    if(error.response?.data?.exception) {
        errorStatus = 600;
    }
    const showGeneralErrorMessage = error.config?.generalError;

    if(showGeneralErrorMessage) {
        switch (errorStatus) {
            case 600:
                Swal.fire({
                    title: 'Payment Failed!',
                    type: 'warning',
                    text: 'Please add a new payment card or contact your bank to process this payment',
                    showCancelButton: true,
                    cancelButtonColor: '#1d9deb',
                    confirmButtonColor: '#ff5656',
                    confirmButtonText: 'Ok!'
                })
                break;
            default:
                Swal.fire({
                    title: 'Action Required!',
                    html: error.response.data.error,
                    type: 'info',
                    confirmButtonColor: '##1d9deb',
                    confirmButtonText: 'OK'
                });
                break;
        }
    }
    if(error?.response?.data?.error === "There is a domain configuration issue. Please speak with support")
        Swal.fire({
            title: 'Something went wrong',
            type: 'warning',
            text: error.response.data.error,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonColor: '#ff5656',
            confirmButtonText: 'OK'
        }).then(() => {});
    return Promise.reject(error);
};

<template>
    <div 
    class="login-overlay register" >
        <div class="e-login-form">
            <div class="login-header">
                REQUEST ACCESS
            </div>
            <div>
                <form @submit.prevent>

                <base-input
                elementType="input"
                :class="{invalid: error.name}"
                inputType="text"
                :required="false"
                v-model="profile.name"
                forID="name"
                :placeholder="'Name'"
                inputClass="partner-input"
                formControlClass=""
                label="Name"
                labelClass="medium-font"
                :invalid="error.name"
                :invalidMessage="error.name"
                @clear-validity="clearValidity"
                ></base-input>

                <base-input
                elementType="input"
                :class="{invalid: error.email}"
                inputType="email"
                :required="false"
                v-model="profile.email"
                forID="email"
                :placeholder="'sample@email.com'"
                inputClass="partner-input"
                formControlClass=""
                label="Email address"
                labelClass="medium-font"
                :invalid="error.email"
                :invalidMessage="error.email"
                @clear-validity="clearValidity"
                ></base-input>

                <vue-phone-number-input 
                no-example  
                size="sm"
                :default-phone-number="selectedNumber.number"
                :default-country-code="selectedNumber.countryCode"
                :dark="true"
                :darkColor="'#1B1B1B'"
                :class="{'invalid-input-phone': error.phone}"
                v-model="selectedNumber.number"   
                @update="updatePhoneNumber"
                >
                </vue-phone-number-input>
                <div class="error" v-if="error.phone">{{ error.phone }}</div>
              
                <label style="margin-top: 28px;" 
                :class="{'u-color-12': error.widget}"
                >Which features are you most interested in using?</label>
                <widget-selector
                :explainerText="'These are the tools that you will have access to and engage with on the analyst-desk.<br />Upon approval, you will have access to the specific widgets that you include here.'"
                :qnaActiveOnPrevRequest="'no previous'"
                :qnaStartingValue="true"
                @widget-selection-changed="impIDs => widgetSelectionChanged(impIDs)"
                @qna-on-changed="val => qna = val"
                ></widget-selector>

                <div class="error">{{ serverError }}</div>

                <div class="button-bar" style="display: flex; justify-content: space-between; margin-top: 40px;">

                    <base-button
                    mode="o-tranparent-grey o-border-radius-0 o-width-125"
                    :link="false"
                    @click.native="$emit('back-to-home')"
                    >Back
                    </base-button>

                    <base-button
                    mode="o-blue o-width-125 o-border-radius-0"
                    :link="false"
                    @click.native="submitRequest"
                    :disabled="isProcessing"
                    :showSpinner="isProcessing"
                    >Submit
                    </base-button>
                    
                </div>
            </form>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import _ from "lodash";
import bootUpMixin from '../../../mixins/bootUpMixin';
import { talkToServer } from '../../../utility/helper-functions';
import VuePhoneNumberInput from 'vue-phone-number-input';
import Swal from "sweetalert2";
import countries from '../../../assets/countries.json';
import WidgetSelector from '../broker/components/WidgetSelector.vue';

const general = createNamespacedHelpers("general");

const CONDITIONS = [
    {test: (model) => model.name?.length > 2 && model.name?.length < 31, message: 'Name must be 3 - 30 characters long', key: "name"},
    {test: (model) => model.email?.length > 5 && model.email?.length < 64 && model.email?.indexOf('@') > 0 && model.email?.indexOf('.') > 0, message: 'Please fill email field.', key: "email"},
    
];

export default {
    mixins: [bootUpMixin],
    components: {
        VuePhoneNumberInput,
        WidgetSelector
    },
    created() {
        if(this.user.email) {
            this.profile = _.cloneDeep(this.user);
            this.selectedNumber.number = this.profile.sms_notification_number;
            this.selectedNumber.countryCode = countries.find(el => el.callingCodes?.[0] === "+" + this.smsNotificationCountry)?.cca2;
        }

        if(this.entityQnaCustom)
            this.qna = true;

    },
    mounted() {
        this.$nextTick(() => window.scrollTo(0,0));
    },
    computed: {
        ...general.mapState(["user", "demoPageMode"]),
        ...general.mapGetters(["entityQnaCustom", "entityQnaOn"]),
        smsNotificationCountry () {
            if(!this.profile || !this.profile?.sms_notification_country)
                return "";

            if(this.profile.sms_notification_country.indexOf("+") === 0)
                return this.profile.sms_notification_country.substring(1);

            if(this.profile.sms_notification_country.indexOf("00") === 0)
                return this.profile.sms_notification_country.substring(2);
            
            return this.profile.sms_notification_country;
        }
    },
    data() {
        return {
            profile: {},
            selectedNumber: {
                countryCode: 'GB',
                number: ''
            },
            phoneNumber: '',
            validPhoneNumber: '',
            error: {},
            isProcessing: false,
            serverError: '',
            selectedWidgets: [],
            allWidgetsSelected: 1,
            qna: false
        }
    },
    methods: {
        ...general.mapActions(["setComponentAfterRefresh"]),

        clearValidity(name) {
            delete this.error[name];
        },
        validateData() {
        this.error = {};
        this.serverError = "";
            CONDITIONS.forEach((condition) => {
                if (!condition.test(this.profile)) {
                    this.error[condition.key] = condition.message;
                }
            });
            if(!this.validPhoneNumber)
                this.error.phone = "Please enter a valid phone number";
            if(!this.selectedWidgets.length)
                this.error.widget = "Please select at least one feature";
            console.log(this.error)
        },
        submitRequest() {
            if(this.demoPageMode) {
                Swal.fire({
                    title: 'Thank you',
                    type: 'info',
                    text: 'Your access request has been received and will be processed the next business day.',
                    allowOutsideClick: false,
                    showCancelButton: false,
                    confirmButtonColor: '#3B9C83',
                    confirmButtonText: 'OK'
                }).then(() => {
                    this.$emit('back-to-home');
                });
                return;
            }

            this.validateData();
            if(!_.isEmpty(this.error))
                return;

            const formData = {
                name: this.profile.name,
                email: this.profile.email,
                refno: window.bootUp.refno,
                phone_number: this.validPhoneNumber,
                sms_notification_country: this.profile.sms_notification_country,
                sms_notification_number: this.profile.sms_notification_number,
                widgets: this.selectedWidgets,
                qanda_requested: this.qna,
                application_mode: "quantum"
            }
            // console.log(formData);
            this.isProcessing = true;
            talkToServer(`/register`, 'POST', null, formData).then(response => {
                // console.log('any response', response);
                this.isProcessing = false;
                if (!response.success && response.error) {
                    this.serverError = "Something went wrong, please try again later"
                } else {
                    talkToServer(`/entity/${window.bootUp.refno}`, 'GET', null, null).then(entResp => {
                        if (entResp.success) {
                            window.bootUp.entity = entResp.data.entity;
                            this.$store.dispatch("rules/addBulkRecords")
                        }
                        });
                    Swal.fire({
                        title: 'Thank you',
                        type: 'info',
                        text: 'Your access request has been received and will be processed the next business day.',
                        allowOutsideClick: false,
                        showCancelButton: false,
                        confirmButtonColor: '#3B9C83',
                        confirmButtonText: 'OK'
                    }).then(() => {
                        this.$emit('back-to-home');
                    });

                }
            })
            .catch(e => {
                console.log(e);
                this.isProcessing = false;
                if (e.status === 401) {
                this.serverError = "Username or password not correct"
                }
                else  {
                    this.serverError = "Something went wrong, please try again later"
                } 
            });
        },
        updatePhoneNumber(data) {
            // console.log(data)
            this.error.phone = '';
            if(data.isValid) {
                this.validPhoneNumber = data.e164; // TEMP!! //or formattedNumber
                this.profile.sms_notification_country = "+" + data.countryCallingCode;
                this.profile.sms_notification_number = data.phoneNumber || data.nationalNumber;
            }
            else 
                this.validPhoneNumber = null
        },
        
        widgetSelectionChanged(impIDs) {
            if(impIDs.length)
                this.error.widget = null;
            // console.log("err", this.error)
            this.selectedWidgets = [...impIDs];
            // console.log("selected and saved", impIDs);
        }
    },
    watch: {
        // selectedWidgets(val) {
        //     if(val.length !== this.widgetsToShow.length) {
        //         this.widgetSelectMode = 0
        //     }
        //     else 
        //         this.widgetSelectMode = 1
        // }
    }
}

</script>

<template>
    <div :id="targetDiv" style="overflow:hidden;" class="analyst-desk" :class="{'broker-mode': demoPageMode, 'external-fs': externalUser, 'external-auto-fs': externalUserAutoLogin}">
        <div v-if="demoPageModeForSidebar" class="broker-view">
          <div class="broker-sidebar" :style="{'color': textColor, 'background-color': backgroundColor}">

            <div>
              <input type="text" value="broker name" id="brokername" :style="{'color': textColor}">
              <!-- <h5>Trade your edge</h5> -->
            </div>

            <div class="menu-list">
              <h4>Trading Accounts</h4>
              <nav>
                <ul>
                  <li><font-awesome-icon :icon="['fas', 'caret-down']" class="caret" :style="{'color': textColor, 'opacity': 0.53}"/>My accounts</li>
                  <li><font-awesome-icon :icon="['fas', 'caret-down']" class="caret" :style="{'color': textColor, 'opacity': 0.53}"/>Open New Account</li>
                  <li><font-awesome-icon :icon="['fas', 'caret-down']" class="caret" :style="{'color': textColor, 'opacity': 0.53}"/>Financial Statements</li>
                </ul>
              </nav>
            </div>

            <div class="menu-list">
              <h4>Funds</h4>
              <nav>
                <ul>
                  <li><font-awesome-icon :icon="['fas', 'caret-down']" class="caret" :style="{'color': textColor, 'opacity': 0.53}"/>Add funds</li>
                  <li><font-awesome-icon :icon="['fas', 'caret-down']" class="caret" :style="{'color': textColor, 'opacity': 0.53}"/>Withdraw funds</li>
                  <li><font-awesome-icon :icon="['fas', 'caret-down']" class="caret" :style="{'color': textColor, 'opacity': 0.53}"/>Transfer funds</li>
                  <li><font-awesome-icon :icon="['fas', 'caret-down']" class="caret" :style="{'color': textColor, 'opacity': 0.53}"/>Bank Accounts</li>
                </ul>
              </nav>
            </div>

            <div class="menu-list">
              <h4>Tools</h4>
              <nav>
                <ul>
                  <li><font-awesome-icon :icon="['fas', 'caret-down']" class="caret" :style="{'color': textColor, 'opacity': 0.53}"/>Open Web Terminal</li>
                  <li><font-awesome-icon :icon="['fas', 'caret-down']" class="caret" :style="{'color': textColor, 'opacity': 0.53}"/>Download MT4</li>
                  <li class="active-item" :style="{'background-color': textColor, 'color': backgroundColor}"><font-awesome-icon :icon="['fas', 'caret-down']" class="caret" :style="{'color': backgroundColor, 'opacity': 0.53}"/>Analyst Desk</li>
                </ul>
              </nav>
            </div>

            <div class="menu-list">
              <h4>Need Help?</h4>
              <nav>
                <ul>
                  <li><font-awesome-icon :icon="['fas', 'caret-down']" class="caret" :style="{'color': textColor, 'opacity': 0.53}"/>Start Tour</li>
                  <li><font-awesome-icon :icon="['fas', 'caret-down']" class="caret" :style="{'color': textColor, 'opacity': 0.53}"/>FAQ</li>
                  <li><font-awesome-icon :icon="['fas', 'caret-down']" class="caret" :style="{'color': textColor, 'opacity': 0.53}"/>Support</li>
                </ul>
              </nav>
            </div>

            <div class="menu-list">
              <h4>Controls</h4>
              <nav>
                <ul>
                  <li class="theme-edit"><font-awesome-icon :icon="['fas', 'caret-down']" class="caret"  :style="{'color': textColor, 'opacity': 0.53}" /><label :style="{'color': textColor}" for="background">Background</label><input type="color" name="background" id="background" v-model="backgroundColor" /></li>
                  <li class="theme-edit"><font-awesome-icon :icon="['fas', 'caret-down']" class="caret" :style="{'color': textColor, 'opacity': 0.53}"/><label :style="{'color': textColor}" for="text">Text</label><input type="color" name="text" id="text" v-model="textColor"></li>
                </ul>
              </nav>

              <nav
              style="margin-top: 35px;">
                <h5>Settings</h5>
                <ul>
                  <li>
                    <span style="border: 1px solid black"
                    :style="{'border-color': textColor}">
                    <button style="background: transparent; border: none; cursor: pointer;" 
                    :style="{'color': textColor}"
                    @click="showSettings"
                    >Show Settings</button>
                    </span>
                  </li>
                </ul>
              </nav>

              <nav
              style="margin-top: 35px;">
                <h5>Notification</h5>
                <ul>
                  <li>
                    <span style="border: 1px solid black"
                    :style="{'border-color': textColor}">
                    <button style="background: transparent; border: none; cursor: pointer;" 
                    :style="{'color': textColor}"
                    @click="showPlatformNotification"
                    >Fire Platform notification</button>
                    </span>
                  </li>
                </ul>
              </nav>
            </div>

          </div>
          <div>
            <div class="broker-top-menu" :style="{'color': textColor, 'background-color': backgroundColor}">
              <nav>
                <ul>
                  <li>Profile</li>
                  <li>Log out</li>
                </ul>
              </nav>
            </div>
          <router-view style="/*padding-top: 80px;*/"/>
            <div class="broker-footer" :style="{'color': textColor, 'background-color': backgroundColor}">
              <div>
                This is an example of disclaimer text, this broker may be authorized and regulated by the Financial Services Commission (FSC) Mauritius, FSC License no C118023XXX. Risk Warning: CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div v-else>
          <router-view/>
        </div>
        <div class="platform-notification"
        :class="{'active': platformNotificationOn}"
        >
         <div class="platform-notification-left"><font-awesome-icon :icon="['fas', 'play-circle']"/></div>
         <div  class="platform-notification-right">
            
          <div>
            <div class="hint">
              From Analyst Desk
            </div>
            <div>
              <button
              @click="closePlatformNotification"
              ><img :src="closeButtonSrc" />
              </button>
            </div>
            
          </div>
          <div class="platform-notification-content">'Short GBPUSD After Solid APD Beat But Watch Claims Data Next' is now published in Real-Time Video Commentary</div>
         </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { eventBus } from './main';
import { frontFacingAssets, getParameterByName, initialisePusherWithAuth, isAutoPilotMode, isBrokerDemo } from './utility/helper-functions';
// import _ from 'lodash';
const general = createNamespacedHelpers("general");
const payments = createNamespacedHelpers("payments");
const widgets = createNamespacedHelpers("widget");
export default {
  created() {
    initialisePusherWithAuth(false);
    this.setEntity(window.bootUp.entity);
    if(window.bootUp.externalUser)
        this.setExternalUser(true);
    if(window.bootUp.externalUserAutoLogin && isAutoPilotMode())
        this.setExternalUserAutoLogin(true);
    if(isBrokerDemo())
        this.setDemoPageMode(true);
        // console.log(isBrokerDemo())
    if(this.$root.widgetId !== 3)
      this.setSingleWidgetMode(true);


    eventBus.$on("products-ready", () => {
      const widgetsChoice = this.widgetsToSelectFromForHomeScreen.map(el => el.implementation_id);
      if(this.entityQnaOff) {
         const analChatImpID = this.getWidgetImpIDByCode('ANALCHAT');
        //  console.log(analChatImpID);
            const index = widgetsChoice.findIndex(el => el === analChatImpID);
            if(index > -1)
              widgetsChoice.splice(index,1);
      }
      this.setWidgetsChoiceOnDemo(widgetsChoice);
      this.widgetSelectionChange();

      if(this.firstLoad) {
        this.firstLoad = false;
        if(isBrokerDemo()) {
          this.demoProducts.forEach(element => {
              this.ruleModel.push(element.product.widgets[0].pivot.widget_implementation_id);
          });
          this.widgetLockChange();
        }

        let menuToDisplay = [...this.terminalMenuOptions];

        if(this.demoPageMode) 
          menuToDisplay = ['home', 'dashboard', 'videos'];

        this.setTerminalMenu(menuToDisplay);

        }
      // console.log("demo", this.demoProducts);
    });

    eventBus.$on("widget-selection-change", this.widgetSelectionChange);

    if(this.isUserBm)
      document.querySelector('body').style.backgroundColor = '#141414';

  },
  computed: {
    ...general.mapState(["demoPageMode", "terminalMenuOptions", "unlockedWidgets", "externalUser"]),
    ...payments.mapState(["demoProducts"]),
    ...general.mapGetters(["widgetsChoiceOnDemo", "isUserBm", 'entityQnaOff', "externalUserAutoLogin"]),
    ...payments.mapGetters(["widgetsToSelectFromForHomeScreen", "widgetsOnTheHomeScreen"]),
    ...widgets.mapGetters(["widgets", 'getWidgetImpIDByCode']), 
    demoPageModeForSidebar() {
      return this.demoPageMode && !getParameterByName('dashboard');
    },
    widgetsToHandle() {
      return this.widgetsToSelectFromForHomeScreen;
    },
    closeButtonSrc() {
      let src = frontFacingAssets() + 'close-x.svg';
      return src;
    },
  },
  data() {
    return {
      targetDiv: (this.$root.targetDiv).replace('#',''),
      textColor: '#FFFFFF',
      backgroundColor: '#C91A31',
      widgetsToDisplay: [],
      widgetsChoice: [],
      menuState: 'fulldemo',
      platformNotificationOn: false,
      platformNotificationTimeout: null,
      ruleModel: [],
      firstLoad: true
    }
  },
  methods: {
    ...general.mapActions(["setTerminalMenu", "setRuleSet", "setWidgetsChoiceOnDemo", "setExternalUser", "setDemoPageMode", "setExternalUserAutoLogin", "setEntity", "setSingleWidgetMode"]),
    ...payments.mapActions(["setWidgetsOnTheHomeScreen"]),

    showSettings() {
      eventBus.$emit('open-modal', {
          classes: "terminal-modal simplified",
          componentInModal: "frontend-controls",
          mode: "wide", 

      });
    },

    widgetSelectionChange() {
      const widgets = this.widgetsToSelectFromForHomeScreen.filter(el => this.widgetsChoiceOnDemo.includes(el.implementation_id));
      this.setWidgetsOnTheHomeScreen(widgets);
      this.$store.dispatch("dashboard/generateReadonlyDashboards");
    },
    showPlatformNotification() {
      if(this.platformNotificationOn) {
        this.closePlatformNotification();
        return;
      }

      this.platformNotificationOn = true;
      this.platformNotificationTimeout = setTimeout(() => this.platformNotificationOn = false, 4000);
    },
    closePlatformNotification() {
      this.platformNotificationOn = false;
      clearTimeout(this.platformNotificationTimeout);
      
    },
    widgetLockChange() {
      const newState = [...this.ruleModel];
      // console.log(newState)
      this.setRuleSet(newState);
    }
  }
}
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
  font-family: Poppins;
  height: 100%;
  background-color: $onboard-survey-background;

}

.window {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.responsive-16-9-frame {
  overflow: hidden;
  height: 0;
  padding-top: 56.25%;
  position: relative;


  & iframe, img {
    border: 0;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;

  }
}

.li-align-right {
  margin-left: auto;
}

.icon-description {
  font-size: 9px;
}

.instruction-row {
  margin-bottom: 35px;
}

.footer {
  height: 50px;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-size: 12px;
  background-color: $onboard-survey-background;
  border-top: 1px solid $separator-line-color;
  letter-spacing: 0.3px;
  color: $input-color-filter;
}
.broker-view {
  position: relative;
  & .broker-top-menu {
    padding: 25px 50px;

    // background-color: #C91A31;
    // position: fixed;
    top: 0;
    z-index: 2;
    width: 100%;

    & nav ul {
      display: flex;
      margin: 0;
      padding: 0;

      & li {
        list-style-type: none;
        margin: 0 30px;
        cursor: pointer;

        &:first-child {
          margin-left: 0;
        }
      }

    }
  }

  & .broker-footer {
    padding: 40px 50px;
    font-size: 12px;

    @include respond(lg) {
      display: grid;
      grid-template-columns: 320px 1fr;
    }
    // background-color: #C91A31;
  }

  display: grid;
  grid-template-columns: 250px 1fr;
  // $whiteish: rgba(#FFFFFF, 0.53);
  $rose: #F55760;
  // color: $whiteish;

  & .broker-sidebar {
    // background-color: #C91A31;
    padding-left: 18px;
    height: 100%;

  }

  & #brokername {
    margin: 0;
    padding-left: 12px;
    margin-top: 14px;
    font-size: 26px;
    font-weight: bold;
    background: transparent;
  }
  & h5 {
    margin: 0;
    padding-left: 12px;
    margin-top: -15px;
    font-size: 15px;

  }

  & .menu-list {

    &:first-child {
      margin-top: 60px;
    }

    margin-top: 40px;

    & h4 {
      margin: 0;
      padding: 0 12px;
      font-size: 16px;
      text-transform: uppercase;
      letter-spacing: 0.1px;
      font-weight: bold;
    }
    & ul {
      margin: 0;
      margin-top: 10px;
      padding: 0;
      list-style: none;
    }
    & li {
      padding: 4px 35px 4px 12px;
      font-size: 14px;
      letter-spacing: 0.4px;
      display: flex;
      align-items: self-end;
      width: fit-content;

      & input {
        width: auto;
      }

      & .caret {
        margin-right: 7px;
        transform: rotateZ(-45deg);
      }

      &.active-item {
        border-radius: 8px;

        & .caret {
          // color: $whiteish;
        }
      }

    }

    .theme-edit {
      & label {
        color: white;
        width: 105px;
        margin: 0;
      }
      & input {
        background-color: transparent;
        height: 25px;
        padding: 0;
        width: 50px;
      }
    }
  }
}
.platform-notification {
    display: grid;
    grid-template-columns: 100px 1fr;
    position: fixed;
    bottom: 25px;
    right: 0px;
    transform: translateX(50px);
    background-color: $onboard-survey-card-background;
    padding: 10px;
    width: 370px;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0px 4px 8px 0 $onboard-survey-background, 6px 4px 8px 0 $onboard-survey-background;
    transition: all 0.6s ease;
    transition-property: opacity, transform, visibility;

    &.active {
      opacity: 1;
      visibility: unset;
      transform: translateX(-25px);
    }

    &-left {
      display: flex;
      justify-content: center;
      align-items: center;

      & svg {
        color: $input-color-filter;
        font-size: 50px;
      }
    }
    &-right > div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & .hint {
        padding-left: 10px;
        font-size: 12px;
        color: $input-color-filter;
      }
    }

    & button {
      background: transparent;
      border: none;
    }

    &-content {
      padding: 15px 10px;
      color: $input-color;
      
    }
  }
</style>
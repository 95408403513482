import PaymentService from "@/services/payments/payment.service.js";
import _ from "lodash";
import { eventBus } from "../../main";
import EventBus from "../bus";


export default {
    loadAvailableProducts({commit}) {
        return new Promise((resolve => {
            PaymentService.listAllAvailableProducts().then(result => {
                // name override
                if(result.data.data?.["macro-trading-accelerator-offer"])
                    result.data.data["macro-trading-accelerator-offer"].name = "Macro-Trading Accelerator";
                if(result.data.data?.["macro-trading-accelerator-offer-three-monthly"])
                    result.data.data["macro-trading-accelerator-offer-three-monthly"].name = "Macro-Trading Accelerator Three Monthly";
                if(result.data.data?.["annual-macro-trading-accelerator-offer"])
                    result.data.data["annual-macro-trading-accelerator-offer"].name = "Annual Macro-Trading Accelerator";
                //name override ENDS

                commit("setAvailableProducts", result.data.data);
                resolve();
            })
        }));
    },

    setCurrentShop({dispatch, rootState}, [current, scheduled]) {
        let currentPlans = _.cloneDeep(current);
        if(rootState.general.demoPageMode) {
            dispatch("setDemoProducts", currentPlans.filter(el => el.product.product_type !== 'plan').filter(el => el.product.widgets[0].pivot.widget_implementation_id !== 5 && el.product.widgets[0].pivot.widget_implementation_id !== 80 && el.product.widgets[0].pivot.widget_implementation_id !== 98 && el.product.widgets[0].pivot.widget_implementation_id !== 6));
        }
        
        dispatch("setCurrentProducts", currentPlans);

        let scheduledPlans = _.cloneDeep(scheduled);
        dispatch("setScheduledProducts", scheduledPlans);
        
        dispatch("dashboard/generateReadonlyDashboards", currentPlans, {root: true});
        // console.log('analyst chat from action');
        EventBus.$emit("ANALCHAT_CHECK");

        setTimeout(() => {
            eventBus.$emit("products-ready");
        },1000);
        


    },
    setCurrentProducts({commit}, payload) {
        commit('setCurrentProducts', payload);
    },
    setScheduledProducts({commit}, payload) {
        commit('setScheduledProducts', payload);
    },
    loadPaymentMethod({commit}) {
        return new Promise(resolve => {
            PaymentService.getPaymentMethodDetails().then(result => {
                commit("setPaymentMethod", _.cloneDeep(result?.data?.data));
                resolve();
            }).catch(() => {
                commit("setPaymentMethod", {});
                resolve();
            })
        });
    },
    loadPaymentHistory: async function ({commit}) {
        let paymentHistory = [];
        Promise.all([PaymentService.listInvoiceHistory(), PaymentService.listPaymentAttemptsHistory()])
            .then(([resultInvoices, resultPaymentAttempts]) => {
                PaymentService.processPaymentEntries(resultInvoices, resultPaymentAttempts, paymentHistory);
                commit("setPaymentHistory", paymentHistory);
            })
    },
    loadPaymentHistoryPromise: function ({commit}) {
        let paymentHistory = [];
        return new Promise(function (resolve) {
            Promise.all([PaymentService.listInvoiceHistory(), PaymentService.listPaymentAttemptsHistory()])
                .then(([resultInvoices, resultPaymentAttempts]) => {
                    PaymentService.processPaymentEntries(resultInvoices, resultPaymentAttempts, paymentHistory);
                    commit("setPaymentHistory", paymentHistory);
                    resolve();
                })
        });

    },
    removePaymentMethod({dispatch}) {
        return new Promise(resolve => {
            PaymentService.removePaymentMethod().then(() => dispatch("loadPaymentMethod").then(() => resolve()));
        })
    },
    purchaseAddon({dispatch}, payload) {
        return new Promise(resolve => {
            PaymentService.purchaseAddon(payload).then(() => dispatch("general/loadCurrentUserSettings", { purchase: true }, {root:true}).then(() => resolve()));
        })
    },
    purchasePlan({dispatch}, payload) {
        return new Promise(resolve => {
            PaymentService.purchasePlan(payload).then(() => {
                console.log("loadCurrentUserSettings")
                dispatch("general/loadCurrentUserSettings", { purchase: true }, {root:true}).then(() => resolve())
            });
        })
    },
    cancelAddon({dispatch}, payload) {
        return new Promise(resolve => {
            PaymentService.cancelAddon(payload).then(() => dispatch("general/loadCurrentUserSettings", {}, {root:true}).then(() => resolve()));
        })
    },
    cancelPlan({dispatch}, payload) {
        return new Promise(resolve => {
            PaymentService.cancelPlan(payload).then(() => dispatch("general/loadCurrentUserSettings", {}, {root:true}).then(() => resolve()));
        })
    },
    setWidgetProducts({commit}, payload) {
        commit("setWidgetProducts", _.cloneDeep(payload));
    },
    destroyUserStoreData({commit}) {
        commit('destroyUserStoreData');
    },
    setFailedPayment({commit}, payload) {
        commit("setFailedPayment", payload);
    },
    setMakingPayment({commit}, payload) {
        commit("setMakingPayment", payload);
    },
    setHistoricProductAccess({commit}, payload) {
        // console.log(payload)
        commit("setHistoricProductAccess", payload);
    },
    setWidgetsOnTheHomeScreen({commit}, payload) {
        commit('setWidgetsOnTheHomeScreen', payload);
    },
    setDemoProducts({commit}, payload) {
        commit('setDemoProducts', payload);
    },
    
    
}


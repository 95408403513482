<template>
    <BarTest 
    :chartData="chartData"
    :legendOn="false"
    :chartHeight="350"
    :min="0"
    :max="100"
    :chartWidth="chartWidth"
    :yTicks="(value) => '   ' + value + '%'"
    :iteration="iteration"
    :labelText=" context => context.value + '% chance of a rate of ' + context.label"
    title="Probability Between Most Probable Outcomes" />
</template>

<script>
import BarTest from '../../../charts/BarChartWrapper.vue';
export default {
    props: ["bank", "chartWidth"],
    components: {
        BarTest
    },
    created() {
        this.processData();
    },
    data() {
        return {
            barColor: ["rgba(22, 206, 185, op)", "rgba(129, 199, 132, op)"],
            chart1Defaults: {
                    // label: "Probability of this change",
                    label: false,
                    pointBackgroundColor: "#16CEB9",
                    borderWidth: 1,
                    fill: false,
                    pointBorderColor: "#16CEB9",
                    backgroundColor: (c) => this.renderBackgroundColor(c),
                    borderColor: (c) => this.barColor[c.dataIndex % this.barColor.length].replace("op", '1')
                },
                // chart2Defaults: {
                //     label: "Data 2",
                //     borderColor: "#81c784",
                //     pointBackgroundColor: "#81c784",
                //     borderWidth: 1,
                //     fill: false,
                //     pointBorderColor: "#81c784",
                //     backgroundColor: "#81c784",
                // },
                iteration: 0
            }
        },
        methods: {
            renderBackgroundColor(c) {
                const color = this.barColor[c.dataIndex % this.barColor.length];
                const startColor = color.replace('op', '0');
                const endColor = color.replace('op', '1');
                const canvas = c.chart.ctx;
                const gradient = canvas.createLinearGradient(0,0,0,350);
                gradient.addColorStop(1, startColor);
                // gradient.addColorStop(0.5, endColor);
                gradient.addColorStop(0, endColor);

                return gradient;
                
                // return 
            },
            processData() {
                let dataset = [];
                let labelset = [];

                const sc1 = this.bank.sc1_change_by;
                const sc2 = this.bank.sc2_change_by;
                const sc1Formatted = sc1.toFixed(3) + '%';
                const sc2Formatted = sc2.toFixed(3) + '%';

                let sc1Text = 'Hold at ' + sc1Formatted;
                let sc2Text = 'Hold at ' + sc2Formatted;

                if(sc1 > this.bank.current_rate)
                    sc1Text = 'Hike to ' + sc1Formatted;
                else if(sc1 < this.bank.current_rate)
                    sc1Text = 'Cut to ' + sc1Formatted;

                if(sc2 > this.bank.current_rate)
                    sc2Text = 'Hike to ' + sc2Formatted;
                else if(sc2 < this.bank.current_rate)
                    sc2Text = 'Cut to ' + sc2Formatted;

                if(sc1 < sc2) {
                    dataset = [this.bank.sc1_probability, this.bank.sc2_probability];
                    labelset = [
                        sc1Text,
                        sc2Text
                    ];    
                }else {
                    dataset = [this.bank.sc2_probability, this.bank.sc1_probability];
                    labelset = [
                        sc2Text,
                        sc1Text
                    ]; 
                }
                this.chartData = {
                    datasets: [
                        {
                            ...this.chart1Defaults,
                            data: dataset,
                            
                        },
                    ],
                    labels: labelset
                };
            }
        },
        watch: {
            bank: {
            handler: function() {
                this.processData();
                this.iteration++;
            },
            deep: true

            },
        }
    }
</script>
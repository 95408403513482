<template>
    <div 
    :style="backgroundIMG"
    class="login-overlay">
        <div class="e-login-form">
            <div>
                <div class="login-header">
                    LOGIN
                </div>
                <div>
                    <form @submit.prevent>

                    <base-input
                    elementType="input"
                    :class="{invalid: error.email}"
                    inputType="email"
                    :required="true"
                    v-model="profile.email"
                    forID="email"
                    :placeholder="'sample@email.com'"
                    inputClass="partner-input"
                    formControlClass=""
                    label="Email address"
                    labelClass="medium-font"
                    :invalid="error.email"
                    :invalidMessage="error.email"
                    @clear-validity="clearValidity"
                    ></base-input>

                    <base-input
                    elementType="input"
                    :class="{invalid: error.password} "
                    inputType="password"
                    :required="true"
                    v-model="profile.password"
                    forID="password"
                    :placeholder="'Password'"
                    inputClass="partner-input"
                    formControlClass=""
                    label="Password"
                    labelClass="medium-font"
                    :invalid="error.password"
                    :invalidMessage="error.password"
                    @clear-validity="clearValidity"
                    >
                    </base-input>

                    <div class="form-text-div align-right"><a class="u-input-color-filter" href="javascript:void(0);" @click="$emit('password-reset')">Forgotten Password</a></div>
                    <div class="error">{{ serverError }}</div>
                    <div class="button-bar" style="display: flex; justify-content: space-between; margin-top: 10px;">

                        <base-button
                        mode="o-tranparent-grey o-border-radius-0 o-width-125"
                        :link="false"
                        @click.native="$emit('back-to-home')"
                        >Back
                        </base-button>

                        <base-button
                        mode="o-blue o-width-125 o-border-radius-0"
                        :link="false"
                        @click.native="next"
                        type="submit"
                        :disabled="isProcessing"
                        :showSpinner="isProcessing"
                        >Login
                        </base-button>
                        
                    </div>
                </form>
                </div>
            </div>
        </div>
        <div class="admin-login-prompt">Admin? <router-link to="admin-login">Log in here</router-link></div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import _ from "lodash";
import bootUpMixin from '../../../mixins/bootUpMixin';
import { talkToServer } from '../../../utility/helper-functions';
import { frontFacingAssets } from '../../../utility/helper-functions';

const general = createNamespacedHelpers("general");

const CONDITIONS = [
    {test: (model) => model.email?.length > 5 && model.email?.length < 64 && model.email?.indexOf('@') > 0 && model.email?.indexOf('.') > 0, message: 'Please fill email field.', key: "email"},
    {test: (model) => model.password.length > 7 && model.password?.length < 33, message: 'Passwprd must be 8 - 32 characters long', key: "password"},
];

export default {
    mixins: [bootUpMixin],
    computed: {
        backgroundIMG() {
            // return "background-color: red;"
            return "background: black url('" + frontFacingAssets() + "Login BG Image.png') no-repeat center -890px; ";
        },
    },
    data() {
        return {
            profile: {},
            error: {},
            isProcessing: false,
            serverError: ''
        }
    },
    methods: {
        ...general.mapActions(["setComponentAfterRefresh"]),

        clearValidity(name) {
            delete this.error[name];
        },
        validateData() {
        this.error = {};
            CONDITIONS.forEach((condition) => {
                if (!condition.test(this.profile)) {
                    this.error[condition.key] = condition.message;
                }
            });
        },
        next() {
            this.validateData();
            if(!_.isEmpty(this.error))
                return;

            const formData = {
                email: this.profile.email,
                password: this.profile.password,
                refno: window.bootUp.refno,
            }
            this.isProcessing = true;
            talkToServer(`/login${window.bootUp.cacheParams}`, 'POST', null, formData).then(response => {
                // console.log('any response', response);
                this.isProcessing = false;
                if (!response.success && response.error) {
                    this.serverError = "Something went wrong, please try again later"
                } else if(response.data.user.user.bm){
                    this.serverError = "This is an admin account, you will be redirected to the admin login screen";
                    setTimeout(() => this.$router.push("admin-login"), 2500);
                } else {
                    this.setComponentAfterRefresh("home");
                    this.saveUserData(response);
                                
                    this.$emit('register-login-successful', {
                        data: response.data,
                        isLogin: true
                    });

                }

            })
            .catch(e => {
                console.log(e);
                this.isProcessing = false;
                if (e.status === 401) {
                this.serverError = "Username or password not correct"
                }
                else  {
                    this.serverError = "Something went wrong, please try again later"
                } 
            });
        },
    }
}

</script>

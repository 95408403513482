<template>
    <div class="addon-item">
        <div class="addon-product-list">
            <div>
                <div class="product-icon" v-if="!addon.ended_at || isBundle" style="line-height:56px;">
                  <font-awesome-icon class="check"  :icon="['fa', 'check']" size="sm"/>
                </div>
                <div class="product-icon"  v-else>
                  <font-awesome-icon class="pause" :icon="['fa', 'pause']" size="sm"/>
                </div>
            </div>
            <div style="display: flex; flex-direction: column; align-self: center; padding-right:5px;">
            
                    <div class="product-name">
                        <span><span v-if="!addon.product.is_promotion"></span>{{ productName
                        }}</span>
                    </div>
                    <div class="plan-description">
                    <span v-html="description"></span>
                </div>
                <div 
                v-if="!addon.product.is_promotion && !addon.ended_at"
                class="plan-description"
                style="margin-top: 5px;">
                  <div>
                    <label
                    >
                      {{ 'Next payment will be ' + formattedPrice + ' on ' + toSystemDate(addon.next_billing_at) + ' ' + vatData}}
                    </label>
                  </div>
                </div>
                <div 
                v-else-if="isLifetime"
                class="plan-description"
                style="margin-top: 5px;">
                <div
                >
                    <span
                    >
                    Lifetime Access
                    <span v-if="isBlackFriday2023"> - 2 month analyst chat access</span>
                    </span>
                </div>
                </div>
                <div 
                v-else
                class="plan-description red"
                style="margin-top: 5px;">
                <div
                >
                    <span
                    v-if="addon.product.is_subscription"
                    >
                      {{ addon.next_billing_at ? 'Access ends on ' + toSystemDate(addon.next_billing_at) : '' }}
                    </span>
                    <span 
                    v-else-if="!addon.product.is_subscription && displayLength"
                    >
                      Access ends on {{ endedAtDate }}
                    </span>
                    <span 
                    v-else-if="!addon.product.is_subscription && !displayLength"
                    >
                      
                    </span>
                  </div>
                </div>
            </div>
            <div v-if="!addon.product.is_promotion"
            style="display: flex; flex-direction: column; align-self: start;"
            >
              <VueCustomTooltip 
                v-if="addon.ended_at && +this.addon.price > 0 && addon.product.is_subscription"
                position="is-left"
                label="Cancel Downgrade" :multiline="true">
                <a 
                class="transparent-link"
                href="javascript:void(0);"
                v-if="addon.ended_at" @click="reactivateHandler(addon)">
                    <font-awesome-icon :icon="['fa', 'redo']" />
                </a>
                </VueCustomTooltip>
                <VueCustomTooltip
                v-else-if="+this.addon.price > 0 && addon.product.is_subscription"
                position="is-left"
                label="Downgrade" :multiline="true">
                <a 
                class="transparent-link"
                href="javascript:void(0);"
                @click="cancelHandler(addon)">
                    <font-awesome-icon
                             :icon="['fas', 'trash-alt']"/>
                </a>
                </VueCustomTooltip>

            </div>
        </div>
        <div >
            <div class="slide-down-link-style">
                <span v-if="addon.product.is_subscription">
                    {{ formattedPrice }} {{ isFree(formattedPrice) ? '' : ' per ' + (this.addon.product.subscription_length === 1 ? '' : this.addon.product.subscription_length) + ' ' + this.addon.product.subscription_length_type + ( this.addon.product.subscription_length === 1 ?  '' : 's') + ' ' + vatData}}
                </span>
                <span v-else-if="isLifetime || !displayLength">
                    {{ formattedPrice }} {{ vatData}}
                </span>
                <span v-else>
                    {{ formattedPrice }} {{ isFree(formattedPrice) ? '' : ' for the ' + this.addon.product.subscription_length + ' ' + this.addon.product.subscription_length_type + ( this.addon.product.subscription_length === 1 ?  '' : 's') + ' ' + vatData}}
                </span>
            </div>
        </div>
        <!-- SPECIAL PROMO -->
        <div v-if="addon.product.widgets && addon.product.widgets.length > 0 && addon.product.id !== 80"
        class="account-widget-details"
        style="margin-top: 25px;"
        >
        <!-- SPECIAL PROMO ENDS -->
          <a href="javascript:void(0);"
            class="slide-down-link-style"
            @click="isSlideDownOpen = !isSlideDownOpen"
            >{{ slideDownProducts }}  
              <font-awesome-icon v-if="!isSlideDownOpen" :icon="['fa', 'chevron-down']"/>
              <font-awesome-icon v-else :icon="['fa', 'chevron-up']"/>
            </a>
            <vue-slide-up-down :active="isSlideDownOpen" :duration="250" class="vue-slide-up-down"
            >
              <div style="margin-top:10px;">
                    <div
                    v-if="categorisedWidgetsToShow.widgets.length"
                    >
                        <span class="included-widgets-label">Indicator{{ categorisedWidgetsToShow.widgets.length > 1 ? 's' : '' }}:</span>
                        <ul class="included-widgets">
                        <li
                        v-for="widget in categorisedWidgetsToShow.widgets" :key="widget.name"
                        >
                        <VueCustomTooltip
                        v-if="widget.showcase"
                        :label="widgetInfoLabel(widget)" position="is-bottom" :multiline="true"
                        >
                            <font-awesome-icon class="showcase" :icon="['fa', 'question-circle']" size="sm"/>
                        </VueCustomTooltip>
                        <font-awesome-icon v-else :icon="['fa', 'check']" size="sm"/>
                        <span class="product-widget-name">{{ widget.name }}</span>
                        </li>
                        </ul>
                    </div>
                    <div class="">
                        <div v-if="categorisedWidgetsToShow.livestreams.length">
                            <span class="included-widgets-label">Live stream series:</span>
                            <ul class="included-widgets">
                                <li
                                v-for="widget in categorisedWidgetsToShow.livestreams" :key="widget.name"
                                >
                                <VueCustomTooltip
                                v-if="widget.showcase"
                                :label="widgetInfoLabel(widget)" position="is-bottom" :multiline="true"
                                >
                                    <font-awesome-icon class="showcase" :icon="['fa', 'question-circle']" size="sm"/>
                                </VueCustomTooltip>
                                <font-awesome-icon v-else :icon="['fa', 'check']" size="sm"/>
                                <span class="product-widget-name">{{ widget.name }}</span>
                                </li>
                            </ul>
                        </div>
                        <div v-if="categorisedWidgetsToShow.videos.length">
                            <span class="included-widgets-label">Video course{{ categorisedWidgetsToShow.videos.length > 1 ? 's' : '' }}:</span>
                            <ul class="included-widgets">
                                <li
                                v-for="widget in categorisedWidgetsToShow.videos" :key="widget.name"
                                >
                                <VueCustomTooltip
                                v-if="widget.showcase"
                                :label="widgetInfoLabel(widget)" position="is-bottom" :multiline="true"
                                >
                                    <font-awesome-icon class="showcase" :icon="['fa', 'question-circle']" size="sm"/>
                                </VueCustomTooltip>
                                <font-awesome-icon v-else :icon="['fa', 'check']" size="sm"/>
                                <span class="product-widget-name">{{ widget.name }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </vue-slide-up-down>
        </div>
    </div>
</template>

<script>
import { formatPrice, getServerEnv } from '../../../utility/helper-functions';
import SubscriptionMixin from "../../../mixins/SubscriptionMixin";
import { usersTime } from '../../../utility/datetime';
import { createNamespacedHelpers } from 'vuex';
import Constants from '../../../utility/constants';

const widgets = createNamespacedHelpers("widget"); 
const payments = createNamespacedHelpers("payments");

export default {
    name: "AddonDetails",
    mixins: [SubscriptionMixin],
    // created() {
    //   console.log("this.addon",this.addon);
    // },  
    props: {
        addon: {
            type: Object,
            default: () => {
            }
        },
        cancelHandler: {
            type: Function,
            default: () => {
            }
        },
        reactivateHandler: {
            type: Function,
            default: () => {
            }
        }
    },
    computed: {
        ...widgets.mapGetters(['getCodeByImplementationId']),
        ...payments.mapGetters(['availableProducts']),
        formattedPrice: function () {
          return formatPrice(this.addon);
        },
        vatData() {
            if(this.includesVAT)
                return "(includes UK VAT)";
            else
                return "";
        },
        period: function () {
            let fromDate = "From: " + this.toSystemDate(this.addon.started_at);
            let toDate = ", To: " + this.toSystemDate(this.addon.ended_at);
            return fromDate + (this.addon.ended_at ? toDate : "");
        },
        nextPayment: function () {
            if (this.addon.ended_at) {
                return "You’ve cancelled this product. Access ends on " + this.endedAtDate;
            } else {
                let price = "Next payment: " + this.formattedPrice + ' ' + this.vatData;
                let date = ", On: " + this.toSystemDate(this.addon.next_billing_at);
                return price + (this.addon.next_billing_at ? date : "");
            }
        },
        endedAtDate: function () {
            if(this.addon.product.specific_finish_date)
                return usersTime(this.addon.product.specific_finish_date, true, false, false, { dateFormat: "d M Y", timeFormat : "24hr" }, false, this.timezone) + ' at ' + usersTime(this.addon.product.specific_finish_date, false, true, false, { dateFormat: "d M Y", timeFormat : "24hr" }, false, this.timezone);

            return this.toSystemDate(this.addon.ended_at);
        },
        isBundle() {
          if(this.addon.product.product_sub_type === "bundle")
            return true

          return false;
        },
        description() {
            let desc = this.addon.product.description;
            const regex = new RegExp("<[^>]*>?",'gm')
            desc = desc.replace(regex,'');

            return desc;
        },
        slideDownProducts() {
            const prodArr = [];
            let plural = '';
            const widgets = this.categorisedWidgetsToShow.widgets;
            const livestreams = this.categorisedWidgetsToShow.livestreams;
            const videos = this.categorisedWidgetsToShow.videos;

            if(widgets.length) {
                plural = widgets.length > 1 ? 's' : '';
                prodArr.push(`${widgets.length} indicator${plural}`);
            }
            if(livestreams.length) {
                prodArr.push(`${livestreams.length} live stream series`);
            }
            if(videos.length) {
                plural = videos.length > 1 ? 's' : '';
                prodArr.push(`${videos.length} video course${plural}`);
            }
            

            let string = '';

            // console.log(prodArr)
            
            prodArr.forEach((el, i) => {
                if(i < prodArr.length - 1) {
                    string+= el + ', '
                }
                else{
                    string+= el + ' ' 
                }
            });
                return string + 'included';
        },
        widgetsToShow() {
          return this.addon.product.widgets;
        },
        categorisedWidgetsToShow() {
            if(!this.widgetsToShow || !this.widgetsToShow.length)
                return  { 
                widgets: [], 
                livestreams: [], 
                videos: []
            };

            const arrW = [];
            const arrLs = [];
            const arrVi = [];
            this.widgetsToShow.forEach(wid => {
              const code = this.getCodeByImplementationId(wid.pivot.widget_implementation_id);

                    if(code === 'TCSTREAM') {
                        arrLs.push(wid);
                    }
                    else if(code === 'TCVIDEO') {
                        arrVi.push(wid);
                    }
                    else {
                        arrW.push(wid);
                    }
            });

            const obj = { 
                widgets: arrW, 
                livestreams: arrLs, 
                videos: arrVi
            };

            return obj;
        },
        isBlackFriday2023 () {
            const env = getServerEnv();
            return this.addon.product.parent_id === Constants.BLACK_FRIDAY_2023[env];
        },
        isLifetime() {
            const env = getServerEnv();
            return this.addon.product.parent_id === Constants.LEGACY_BUNDLE[env] || this.isBlackFriday2023;
        },
        productName() {
            if(this.isLifetime) {
                const id = this.addon.product.parent_id;
                const found = Object.values(this.availableProducts).find(el => el.id === id);
                return found.name;
            }
            return this.addon.product.name
        },
        displayLength() {
            const env = getServerEnv();
            if(this.addon.product.id === Constants.RISK_COURSE.prodID[env] || this.addon.product.parent_id === Constants.RISK_COURSE.prodID[env])
                return false;
                
            return  true;
        }
    },
    data() {
      return {
        isSlideDownOpen: false
      }
    }
}
</script>


<style lang="scss" scoped>


.addon-item {
  padding: 8px 0;
  border-bottom: 1px solid $separator-line-color;

  &:last-child {
    border: none;
  }
}

.addon-product-list {
  display: grid;
  grid-template-columns: 55px 1fr 55px;
  gap: 10px;
  margin-bottom: 10px;
  
}

.product-name {
  @include H5;
}

.product-desc {
  font-size: 14px;
}


</style>

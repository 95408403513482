import { addDays, isBST } from "../../utility/datetime";
import { processEventsBasedOnLondonTime, sortDataBy } from "../../utility/helper-functions";

export default {
    fetchInitialEcoCalendarEvents(fromTo) {

        let from = fromTo.from;
        if(isBST(from))
            from = addDays(-1, from);
        let to = fromTo.to;

        const queryObject = {
            name: "initial-ecocalendar-fetch",
            objStore: "ecocalendar",
            fromTo: {
                from,
                to
            },
        }
        
        // console.log(queryObject);

        return window.bootUp.iDB.getRecords(queryObject).then(response => {
            sortDataBy(response, "timestamp", "timeASC");
            if(!response.length || !response)
                return response;

            let events = processEventsBasedOnLondonTime(response, from, to);
            
            return events;
        });
    },
    loadMore(start, end, ids) {

        let from = start
        if(isBST(from))
            from = addDays(-1, from);
        let to = end;

        const queryObject = {
            name: "ecocalendar-load-more",
            objStore: "ecocalendar",
            fromTo: {
                from: from,
                to: to,
            },
            ids: ids
        }
        return window.bootUp.iDB.getRecordsFromServer(queryObject).then(response => {
            sortDataBy(response, "timestamp", "timeASC");
            if(!response.length || !response)
                return response;

            let events = processEventsBasedOnLondonTime(response, from, to);
            
            return events;
        });
     
        
    },
    getEventsByIds(ids) {
        return window.bootUp.iDB.getCalEventsByIds(ids);
    },
    getEventsMissingEventsForToday() {
        return window.bootUp.iDB.getEventsMissingEventsForToday();
    },
    findNextPromoEvent(countries, tickers) {
        return window.bootUp.iDB.findNextPromoEvent(countries, tickers);
    }
    
}
import {createNamespacedHelpers} from 'vuex';
const payments = createNamespacedHelpers('payments');
const general = createNamespacedHelpers('general');
import Swal from 'sweetalert2';
import { eventBus } from '../main';
import { isAutoPilotMode, isExternalClientMode } from '../utility/helper-functions';
// import { eventBus } from '../main';

export default {
    computed: {
        ...general.mapState(['demoPageMode', 'singleWidgetMode']),
        ...general.mapGetters(['unlockedWidgets', 'entityLocked', 'entity', 'user']),
        ...payments.mapGetters(['availableWidgets', 'sortedWidgetsByCreationDate']),
        isLocked() {
        //   console.log("this.unlockedWidgets", this.singleWidgetMode);
            if(this.singleWidgetMode)
                return false;

            return (!this.unlockedWidgets?.includes(this.details?.implementation_id) && !this.unlockedWidgets?.includes(this.item?.impID)) || this.entityLocked || isAutoPilotMode() && !this.entity.autopilot_mode || isExternalClientMode() && !this.entity.quantum_mode && !this.user.bm;
            
            // if(this.demoPageMode && !this.unlockedWidgets?.includes(this.details?.implementation_id)) {
            //   return true;
            // }
            // return false;

        }
    },
    methods: {
      showBrokerRequest() {

        const html = `Click below to request access. We will get in touch to process your request within one business day.`;
        // const widgetName = this.sortedWidgetsByCreationDate.find(el => el.implementation_id === impID).name;
        Swal.fire({
            title: 'Activate this feature',
            html: html,
            type: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#383A42',
            confirmButtonColor: '#0062FF',
            confirmButtonText: 'Get Access'
            // timer: 1500
        }).then((value) => {
            if(value.dismiss)
                return;
    
            eventBus.$emit("request-on");
        });
    }
    }
}
<template>
    <div class="next-steps">
        <span class="what-happens">WHAT HAPPENS AFTER YOU PAY?</span>
        <div style="margin-top: 10px" class="next-steps-container">
            <table style="width: 100%; height: 100%">
                <tbody>
                <tr>
                    <td style="vertical-align: top;">
                        <font-awesome-icon :icon="['fa', 'wallet']"/>
                    </td>
                    <td style="vertical-align: top;">
                        <span v-if="hasActiveCard">Billing details</span>
                        <template v-else style="color: red;"><span>Billing details</span><br/></template>
                        <div v-if="hasActiveCard">
                            <label> Your payment method {{ ccDetails[0] }} ending in {{ ccDetails[1] }}</label>
                            <base-button
                                    style="text-decoration: underline; background: none; color: #03c2fc; padding: 0"
                                    :link="true" @click.native="editPaymentMethod">Edit
                            </base-button>
                        </div>
                        <base-button style="text-decoration: underline; background: none; color: #03c2fc; padding: 0"
                                     v-else :link="true" @click.native="addPaymentMethod">Add payment method
                        </base-button>
                    </td>
                </tr>

                <template v-if="isMtaPromo">
                    <tr>
                        <td style="vertical-align: top;">
                            <font-awesome-icon :icon="['fas', 'store']"/>
                        </td>
                        <td style="vertical-align: top;">
                            <div v-if="activePlan.product.slug.indexOf('explorer') === -1">
                                <div v-if="!activePlan.product.trial_of_product_id">
                                    <span>Your {{ activePlan.product.name }} will be cancelled immediately.</span><br/>
                                    <label><span v-if="!activePlan.product.is_visible_front_end">Everything is included in the Marco-Trading Accelerator. You'll be able to reactivate your {{ activePlan.product.name }} plan after the accelerator ends.</span></label>
                                    <br/>
                                </div>
                                <div v-else>
                                    <span>Your {{ activePlan.product.name }} will be downgraded after on the date of expiry.</span><br/>
                                    <label>Your trial will last until {{ calculateNextBillingDate(activePlan) }}. Your account will automatically switch to Explorer after this.</label><br/>
                                </div>
                            </div>
                            <div v-else>
                                <span>Your {{ activePlan.product.name }} access will continue.</span><br/>
                                <label>This plan is free forever.</label><br/>
                            </div>
                        </td>
                    </tr>
                    <!-- <template
                    v-if="widgetCancellingBundle && addon.product && promotionalProducts.find(promo => product[0].parent_id === promo.id).widgets.filter(widget => widget.implementation_id === addon.product.widgets[0].pivot.widget_implementation_id).length > 0">
                        <tr :key="addon.slug">
                            <td style="vertical-align: top;">
                                <font-awesome-icon :icon="['fa', 'fast-forward']"/>
                            </td>
                            <td style="vertical-align: top;"> <span>
                                Your {{ addon.product.name }} subscription will be cancelled immediately.</span><br/>
                                <label> It’s included in {{ promotionalProducts.find(promo => product[0].parent_id === promo.id).name }}.</label><br/>
                            </td>
                        </tr>
                    </template> -->

                </template>

                <template v-if="widgetCancellingBundle">
                    <template v-for="addon in currentVisibleAddons">
                        <template
                                v-if="renderWidgetCancellingBundle">
                            <tr :key="addon.slug">
                                <td style="vertical-align: top;">
                                    <font-awesome-icon :icon="['fa', 'fast-forward']"/>
                                </td>
                                <td style="vertical-align: top;"> 
                                    <span>
                                    Your {{ addon.product.name }} subscription will be cancelled immediately.</span><br/>
                                    <label> It’s included in {{ promotionalProducts.find(promo => products[0] && products[0].parent_id === promo.id || products[0].id === promo.id).name  }}.</label><br/>
                                </td>
                            </tr>
                        </template>

                    </template>
                </template>


                <template v-else-if="hasPlans">
                    <template v-if="isPlanUpgrade">
                        <tr v-if="selectedPlans[0].trial_of_product_id > 0">
                            <td style="vertical-align: top;">
                                <font-awesome-icon :icon="['fa', 'fast-forward']"/>
                            </td>
                            <td style="vertical-align: top;"><span>You will be immediately upgraded to {{ selectedPlans[0].name
                                }}.</span><br/>
                                <label v-if="!noCommitmentSelected">Your trial will last until {{ calculateNextBillingDate(selectedPlans[0]) }}. Your
                                    account will automatically switch back to {{ activePlan.product.name
                                    }}.</label><br/></td>
                        </tr>
                        <tr v-else>
                            <td style="vertical-align: top;">
                                <font-awesome-icon :icon="['fa', 'fast-forward']"/>
                            </td>
                            <td style="vertical-align: top;"><span>You will be immediately upgraded to {{ selectedPlans[0].name }}.</span><br/>
                                <label v-if="!noCommitmentSelected">Next billing will be
                                    ${{ toSystemPrice(selectedPlans[0].pricing['USD']) }}
                                    on {{ calculateNextBillingDate(selectedPlans[0])
                                    }} and every {{ selectedPlans[0].subscription_length_type
                                    }} until cancelled.</label><br/></td>
                        </tr>
                    </template>
                    <template v-for="addon in currentVisibleAddons">
                        <template
                                v-if="addon.product && mainPlan.widgets.filter(widget => addon.product.widgets[0] && widget.implementation_id === addon.product.widgets[0].pivot.widget_implementation_id).length > 0">
                            <tr :key="addon.slug">
                                <td style="vertical-align: top;">
                                    <font-awesome-icon :icon="['fa', 'fast-forward']"/>
                                </td>
                                <td style="vertical-align: top;"> <span>
                                    Your {{ addon.product.name }} subscription will be cancelled immediately.</span><br/>
                                    <label> It’s included in {{ selectedPlans[0].name }}.</label><br/>
                                </td>
                            </tr>
                        </template>

                    </template>
                </template>

                <template v-else-if="activePlan">
                    <tr>
                        <td style="vertical-align: top;">
                            <font-awesome-icon :icon="['fas', 'store']"/>
                        </td>
                        <td style="vertical-align: top;">
                            <div v-if="this.activePlan.ended_at">
                                <span>Your {{ activePlan.product.name }} will end on {{ toSystemDate(activePlan.ended_at) }}.</span><br/>
                                <label>After this you will be downgraded to {{ upcomingPlans.length ? upcomingPlans[0].product.name : '' }}</label><br/>
                            </div>
                            <div v-else-if="parseFloat(activePlan.price) > 0">
                                <span>Your current {{ activePlan.product.name }} access will continue.</span><br/>
                                <label>Next billing will be
                                    ${{ toSystemPrice(parseFloat(activePlan.price))
                                    }} on {{ toSystemDate(activePlan.next_billing_at)
                                    }} and every {{ activePlan.product.subscription_length_type
                                    }} until cancelled.</label><br/>
                            </div>
                            
                            <div v-else>
                                <span>Your current {{ activePlan.product.name }} access will continue.</span><br/>
                                <label>This plan is free forever.</label><br/>
                            </div>
                        </td>
                    </tr>

                </template>

                <template v-if="hasWidgets">
                    <!-- <tr v-if="selectedWidgets[0].planDetails.includedPlan.length === 0"></tr> -->
                    <tr>
                        <td style="vertical-align: top;">
                            <font-awesome-icon :icon="['fa', 'fast-forward']"/>
                        </td>
                        <td style="vertical-align: top;"><span>Your {{ selectedWidgets[0].planDetails.addonProducts[0].widgets[0].name }} {{ productName }} will be activated immediately.</span><br/>
                            <label v-if="!noCommitmentSelected">Next billing will be
                                ${{ toSystemPrice(selectedWidgets[0].planDetails.priceNumeric)
                                }} on {{ calculateNextBillingDate(selectedWidgets[0].planDetails.addonProducts[0]) }}
                                and every month until cancelled.</label><br/></td>

                    </tr>

                </template>

                <template v-if="indicatorAddons.length">
                    <tr>
                        <td style="vertical-align: top;">
                            <font-awesome-icon :icon="['fa', 'store']"/>
                        </td>
                        <td style="vertical-align: top;">
                            <span>Your current subscription{{ indicatorAddons.length > 1 ? 's' : '' }} to the following indicator{{ indicatorAddons.length > 1 ? 's' : '' }} will continue:
                            </span>
                            <br/>
                            <ul class="current-addons">
                                <li
                                v-for="addon in indicatorAddons"
                                :key="addon.id"
                                >{{ addon.product.name }}
                                <span v-if="addon.ended_at" style="font-size: 11px;"><i>(ends: {{toSystemDate(addon.ended_at)}})</i></span>
                                </li>
                            </ul>
                            <br />
                        </td>

                    </tr>

                </template>

                <template v-if="liveStreams.length">
                    <tr>
                        <td style="vertical-align: top;">
                            <font-awesome-icon :icon="['fa', 'store']"/>
                        </td>
                        <td style="vertical-align: top;"><span>Your current subscription{{ liveStreams.length > 1 ? 's' : '' }} to the following series will continue:</span><br/>
                            <ul class="current-addons">
                                <li
                                v-for="addon in liveStreams"
                                :key="addon.id"
                                >{{ addon.product.name }}
                                <span v-if="addon.ended_at" style="font-size: 11px;"><i>(ends: {{toSystemDate(addon.ended_at)}})</i></span>
                                </li>
                            </ul>
                            <br />
                        </td>

                    </tr>

                </template>

                <template v-if="courses.length">
                    <tr>
                        <td style="vertical-align: top;">
                            <font-awesome-icon :icon="['fa', 'store']"/>
                        </td>
                        <td style="vertical-align: top;"><span>Your current subscription{{ courses.length > 1 ? 's' : '' }} to the following course{{ courses.length > 1 ? 's' : '' }} will continue:</span><br/>
                            <ul class="current-addons">
                                <li
                                v-for="addon in courses"
                                :key="addon.id"
                                >{{ addon.product.name }}
                                <span v-if="addon.ended_at" style="font-size: 11px;"><i>(ends: {{toSystemDate(addon.ended_at)}})</i></span>
                                </li>
                            </ul>
                            <br />
                        </td>

                    </tr>

                </template>

                <template v-if="isMtaCourse">
                    <tr>
                        <td style="vertical-align: top;">
                            <font-awesome-icon :icon="['fas', 'sync']"/>
                        </td>
                        <td style="vertical-align: top;">
                            <div>
                                <span>All new updates to the course included.</span> 
                                <label></label>
                                <br />
                            </div>
                        </td>
                    </tr>

                </template>


                <template v-if="hasPromos">
                    <!-- <template v-if="currentVisibleAddons.length > 0">
                        <tr v-for="addon in currentVisibleAddons" :key="addon.slug">
                            <template
                                    v-if="addon.product && selectedPromos[0].widgets.filter(widget => widget.implementation_id === addon.product.widgets[0].pivot.widget_implementation_id).length > 0">
                                <td style="vertical-align: top;">
                                    <font-awesome-icon :icon="['fa', 'fast-forward']"/>
                                </td>
                                <td style="vertical-align: top;">
                                    <span>
                                    Your {{ addon.product.name
                                        }} subscription will be cancelled immediately. </span><br/>
                                    <label>It's included in {{ selectedPromos[0].name }}.</label><br/>
                                </td>
                            </template>
                        </tr>
                    </template> -->
                    <template v-if="selectedPromos[0].is_subscription">
                        <tr>
                            <td style="vertical-align: top;">
                                <font-awesome-icon :icon="['fa', 'fast-forward']"/>
                            </td>
                            <td style="vertical-align: top;"><span>Your {{ selectedPromos[0].parent_id ? availProds.find(el => el.id === selectedPromos[0].parent_id).name : selectedPromos[0].name
                                }} will be activated immediately.</span><span v-if="!noCommitmentSelected"> </span><br/>
                                <label>Next payment will be ${{toSystemPrice(selectedPromos[0].pricing.USD)}} on {{ expiryDate }} every {{ selectedPromos[0].subscription_length_type }} until cancelled.
                                </label><br/></td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr>
                            <td style="vertical-align: top;">
                                <font-awesome-icon :icon="['fa', 'fast-forward']"/>
                            </td>
                            <td v-if="isLegacyBundle" style="vertical-align: top;"><span>Your lifetime access to {{ selectedPromos[0].parent_id ? availProds.find(el => el.id === selectedPromos[0].parent_id).name : selectedPromos[0].name
                                }} will begin immediately</span><br/>
                                <label>Your access never ends. You will not be billed again for this product.</label><br/>
                            </td>
                            <td v-else-if="isBlackFriday2023Offer" style="vertical-align: top;"><span>Your lifetime access to {{ selectedPromos[0].parent_id ? availProds.find(el => el.id === selectedPromos[0].parent_id).name : selectedPromos[0].name
                                }} will begin immediately</span><br/>
                                <label>2 month analyst chat access. Your course access never ends. You will not be billed again for this product.</label><br/>
                            </td>
                            <td v-else style="vertical-align: top;"><span>Your access to {{ selectedPromos[0].parent_id ? availProds.find(el => el.id === selectedPromos[0].parent_id).name : selectedPromos[0].name
                                }} will begin immediately</span><span v-if="displayExpiry"> and expire on {{ expiryDate
                                }}</span><br/>
                                <label>You will not be billed again for this product.</label><br/></td>
                        </tr>
                    </template>
                </template>
                <template v-if="currentVisiblePromotions.length > 0 && availProds.find(el => el.id === currentVisiblePromotions[0].product.parent_id || el.id === currentVisiblePromotions[0].product.id)">
                    <tr>
                        <td style="vertical-align: top;">
                            <font-awesome-icon :icon="['fa', 'divide']"/>
                        </td>
                        <td><span>Your {{ currentVisiblePromotions[0].product.parent_id ? availProds.find(el => el.id === currentVisiblePromotions[0].product.parent_id).name : currentVisiblePromotions[0].product.name }} access will continue <span v-if="displayExpiryForCurrPromo(currentVisiblePromotions[0])">until {{ calculateNextBillingDate(currentVisiblePromotions[0])
                            }}.</span></span><br/>
                            <label>You will not be billed again for this product.</label><br/></td>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import SubscriptionMixin from "../../../mixins/SubscriptionMixin";
import PaymentCardModal from "../payments/PaymentCardModal";
import Constants from "../../../utility/constants";
import { createNamespacedHelpers } from 'vuex';
import { toSystemDate, usersTime } from '../../../utility/datetime';
import { getServerEnv, isErbPromo, isMtaPromo } from '../../../utility/helper-functions';

const widgets = createNamespacedHelpers("widget")
const payments = createNamespacedHelpers("payments")

export default {
    name: "NextSteps",
    mixins: [SubscriptionMixin],
    props: {
        products: {
            type: Array,
        },
        noCommitmentSelected: {
            type: Boolean,
            default: false
        }
    },
    // created() {
    //     console.log(this.noCommitmentSelected);
    // },
    computed: {
        ...widgets.mapGetters(['getCodeByImplementationId']),
        ...payments.mapGetters(['sortedWidgetsByCreationDate']),

        availProds() {
            return Object.values(this.availableProducts);
        },

        selectedPromos: function () {
            return this.products.filter(product => product.product_type === "promotion");
        },
        selectedWidgets: function () {
            const prod = this.products.filter(product => product.product_sub_type === "addon");
            let selectedWidgets = [];
            if(prod?.[0]?.widgets?.length === 1)
                selectedWidgets =  [this.sortedWidgetsByCreationDate.find(el => el.details.id === prod[0].id)];
            
            return selectedWidgets;
            
        },
        productName() {
            if(this.selectedWidgets?.[0].code === 'TCSTREAM')
                return "series";
            if(this.selectedWidgets?.[0].code === 'TCVIDEO')
                return "course";
            
            return "indicator";
        },
        hasPlans: function () {
            return this.selectedPlans.length > 0;
        },
        hasWidgets: function () {
            return this.selectedWidgets.length > 0;
        },
        hasPromos: function () {
            return this.selectedPromos.length > 0 && !this.hasWidgets;
        },
        // hasCurrentIndicatorAddons: function () {
        //     return this.indicatorAddons.length > 0;
        // },
        liveStreams() {
            const widgets = this.currentAddons.filter(el => {
                if(el.product.widgets.length === 1)
                    return this.getCodeByImplementationId(el.product.widgets[0].id) === 'TCSTREAM'
            });

            // if(!this.mainPlan || this.mainPlan?.trial_of_product_id)
            //     return widgets;

            // const mainPlanWidgetIDs = this.mainPlan.widgets.map(el => el.implementation_id);
        
            // return widgets.filter(el => !mainPlanWidgetIDs.includes(el?.product?.widgets?.[0].pivot.widget_implementation_id));

            if((!this.mainPlan || this.mainPlan?.trial_of_product_id) && !this.widgetCancellingBundle)
                return widgets;

            let mainProductWidgetIDs = [];
            if(this.widgetCancellingBundle) {
                mainProductWidgetIDs = this.promotionalProducts.find(promo => this.products?.[0]?.parent_id === promo.id || this.products?.[0]?.id === promo.id)?.widgets.map(el => el.implementation_id);
            }else {
                mainProductWidgetIDs = this.mainPlan.widgets.map(el => el.implementation_id);
            }
            
            return widgets.filter(el => !mainProductWidgetIDs.includes(el?.product?.widgets?.[0].pivot.widget_implementation_id));
        },
        courses() {
            const widgets = this.currentAddons.filter(el => {
                if(el.product.widgets.length === 1)
                    return this.getCodeByImplementationId(el.product.widgets[0].id) === 'TCVIDEO'
            });

            // if(!this.mainPlan || this.mainPlan?.trial_of_product_id)
            //     return widgets;

            // const mainPlanWidgetIDs = this.mainPlan.widgets.map(el => el.implementation_id);
           
            // return widgets.filter(el => !mainPlanWidgetIDs.includes(el?.product?.widgets?.[0].pivot.widget_implementation_id));

            if((!this.mainPlan || this.mainPlan?.trial_of_product_id) && !this.widgetCancellingBundle)
                return widgets;

            let mainProductWidgetIDs = [];
            if(this.widgetCancellingBundle) {
                mainProductWidgetIDs = this.promotionalProducts.find(promo => this.products?.[0]?.parent_id === promo.id || this.products?.[0]?.id === promo.id)?.widgets.map(el => el.implementation_id);
            }else {
                mainProductWidgetIDs = this.mainPlan.widgets.map(el => el.implementation_id);
            }
            
            return widgets.filter(el => !mainProductWidgetIDs.includes(el?.product?.widgets?.[0].pivot.widget_implementation_id));
        },
        nonTcs() {
            return this.currentAddons.filter(el => {
                if(el.product.widgets.length === 1)
                    return this.getCodeByImplementationId(el.product.widgets[0].id) !== 'TCVIDEO' && this.getCodeByImplementationId(el.product.widgets[0].id) !== 'TCSTREAM'
            });
        },
        indicatorAddons() {
            if(!this.nonTcs.length)
                return [];

            let widgets = this.nonTcs.filter(el => el.product.product_sub_type === 'addon');
            

            //SPECIAL PROMO
            if(isErbPromo()) {
                widgets = widgets.filter(el => el.product.slug !== 'fs-analyst-chat');
            }
            //SPECIAL PROMO ENDS
            

            if((!this.mainPlan || this.mainPlan?.trial_of_product_id) && !this.widgetCancellingBundle)
                return widgets;

            let mainProductWidgetIDs = [];
            if(this.widgetCancellingBundle) {
                mainProductWidgetIDs = this.promotionalProducts.find(promo => this.products[0]?.parent_id === promo.id || this.products[0]?.id === promo.id)?.widgets.map(el => el.implementation_id);
            }else {
                mainProductWidgetIDs = this.mainPlan.widgets.map(el => el.implementation_id);
            }
            
            return widgets.filter(el => !mainProductWidgetIDs.includes(el?.product?.widgets?.[0].pivot.widget_implementation_id));

        },
        liveStreamAddons() {
            if(!this.liveStreams.length)
                return [];

             

            return this.liveStreams.filter(el => el.product.product_sub_type === 'option');
        },
        courseAddons() {
            if(!this.courses.length)
                return [];

            return this.courses.filter(el => el.product.product_sub_type === 'option');
        },
        isPlanUpgrade: function () {
            if (this.hasPlans) {
                // let status = this.calculateUpgradeStatus(this.selectedPlans[0]);
                // return status === 2;
                const active = this.activePlan.product;
                if(active.trial_of_product_id)
                    return true;
                if(!active.is_visible_front_end)
                    return true;
                if(!+this.activePlan.price)
                    return true;
                

                
                const activePeriodNum = Constants.PLAN_CONSTANTS.length_values[active.subscription_length_type];
                const activeLength = active.subscription_length;
                const selectedPeriodNum = Constants.PLAN_CONSTANTS.length_values[this.selectedPlans[0].subscription_length_type];
                const selectedLength = this.selectedPlans[0].subscription_length;

                return activePeriodNum * activeLength < selectedPeriodNum * selectedLength;
            }
            return false;

        },
        expiryDate() {
            if(this.selectedPromos[0].specific_finish_date)
                return usersTime(this.selectedPromos[0].specific_finish_date, true, false, false, { dateFormat: "d M Y", timeFormat : "24hr" }, false, this.timezone) + ' at ' + usersTime(this.selectedPromos[0].specific_finish_date, false, true, false, { dateFormat: "d M Y", timeFormat : "24hr" }, false, this.timezone);

            return this.calculateNextBillingDate(this.selectedPromos[0]);
        },
        isMtaPromo() {
            return isMtaPromo();
        },
        isMtaCourse() {
            const env = getServerEnv();
            return Constants.MTA_COURSE.prodID[env] === this.products[0]?.id || Constants.MTA_COURSE.prodID[env] === this.products[0]?.parent_id;
        },
        isBlackFriday2023Offer() {
            const env = getServerEnv();
            return Constants.BLACK_FRIDAY_2023[env] === this.products[0]?.parent_id;
        },
        isLegacyBundle() {
            const env = getServerEnv();
            return Constants.LEGACY_BUNDLE[env] === this.products[0]?.parent_id;
        },
        widgetCancellingBundle() {
            // return this.products[0]?.product_sub_type === 'bundle' && this.products[0]?.product_type !== 'plan';
            const env = getServerEnv();
            return Constants.MTA_PROMO[env].prodID === this.products[0]?.id || Constants.MTA_PROMO[env].prodID === this.products[0]?.parent_id;
        },
        exipry() {
            const date = this.products[0]?.promotion_ends_at;
            return toSystemDate(date);
        },
        renderWidgetCancellingBundle() {
            return this.addon?.product && this.promotionalProducts.find(promo => this.products?.[0]?.parent_id === promo.id || this.products?.[0]?.id === promo.id)?.widgets.filter(widget => widget.implementation_id === this.addon.product.widgets[0].pivot.widget_implementation_id).length > 0
        },
        displayExpiry() {
            const env = getServerEnv();
            if(this.products?.[0].id === Constants.RISK_COURSE.prodID[env] || this.products?.[0].parent_id === Constants.RISK_COURSE.prodID[env])
                return false;
            else if(this.noCommitmentSelected)
                return false;

            return true;
        }
    },
    data() {
        return {
            selectedPlans: [],
            mainPlan: null,
            includedInPlan: false,
            promoID: null,
        }
    },
    methods: {
        addPaymentMethod: function () {
            this.showModal(PaymentCardModal, {
                edit: false,
                hideHeader: true
            });
        },
        editPaymentMethod: function () {
            this.showModal(PaymentCardModal, {
                edit: true,
                hideHeader: true
            });
        },
        displayExpiryForCurrPromo(prod) {
            const env = getServerEnv();
            if(prod.product.id === Constants.RISK_COURSE.prodID[env] || prod.product.parent_id === Constants.RISK_COURSE.prodID[env])
                return false;

            return true;
        }
    },
    watch: {
        products: {
            handler: function(nVal) {
                if(nVal.length && nVal[0]?.product_type === "plan") {
                    this.selectedPlans = nVal.filter(product => product.product_type === "plan");
                    let plan = this.selectedPlans[0];
                
                    if(plan.parent_id && !plan.trial_of_product_id) {
                        plan = this.mergedPlans.find(el => el.id === plan.parent_id);
                    }
                    this.mainPlan = plan;

                }
                    
            },
            deep: true
        }
    }

}
</script>

<style lang="scss">
.next-steps {

    font-size: 14px;
   
   & .what-happens {
       @include H7;
       color: $input-color-filter;
   }

   .next-steps-container {
       max-height: 80vh;
       overflow-y: auto;
       @include scrollbarDark;
   }
}
td {
    padding-left: 5px;

    & .current-addons {
        padding-left: 10px;
        margin: 3px 0;
        list-style: none;
        margin-bottom: 5px;

        & li {
          
            color: $input-color-filter;

            &::before {
                content: "\2022"; 
                color: $input-color-filter;
                font-weight: bold; 
                display: inline-block; 
                width: 10px; 
                margin-left: -10px;
            }
        }
        
        
    }
}
</style>

<template>
    <div class="data-table-row-subrows--row" 
    :class="{'u-highlight-latest-item': showHighLightClass, 'single-line': this.singleLine}"
    data-table="eco-cal" 
    :ref="isScrollTarget ? 'upcomingRelease' : ''"
    :data-id="calEvent.id"
    >
        <div class="data-table-row-subrows--row__header"
        :class="{'single-line': this.singleLine}"
        >
            <div class="data-table--cell"
            :style="cellStyles"
            >{{ formattedTime }}</div>
            <div class="data-table--cell from-medium"
            :style="cellStyles"
            >
                <!-- <img src="assets/images/Flags/ECB.png" alt="" />&nbsp; -->
                {{ currency }}
            </div>
            <div class="data-table--cell wide-only"
            :style="cellStyles"
            >
                <font-awesome-icon :icon="['fas', 'circle']"
            :class="`u-color-${impactClassSpecifier}-importance`"/>
            </div>
            <div class="data-table--cell"
            :style="cellStyles"
            style="padding-left: 6px; padding-right: 6px;"
            >
                <span
                v-if="details.isMdWidget || isHoliday || isSpeech"
                class="row-title-label"
                >
                    <span class="row-title-label-name">{{ eventName }}</span>
                
                </span>
                <span
                v-else
                class="row-title-label" @click="openTableRow">
                    <span class="row-title-label-name control-table-expandable">
                        {{ eventName }}
                        </span>
                    <span class="row-title-label-plus control-table-expandable">
                        <font-awesome-icon :icon="['fas', 'chevron-right']" />
                    </span>
                </span>
            </div>
            <div class="data-table--cell merged-cell"
            :style="cellStyles"
            v-if="isHoliday"
            >Holiday
            </div>
            <div class="data-table--cell merged-cell"
            :style="cellStyles"
            v-if="isSpeech"
            >Speech
            </div>
            <div class="data-table--cell"
            :style="cellStyles"
            v-if="!isHoliday && !isSpeech"
            >
                <span v-if="isNextUpcoming && !actualValue && countdown">{{ countdown }}</span>
                <span v-if="!actualValue && countdownFinished">
                    <VueCustomTooltip label="Data delayed at source" position="is-left" class="eco-tooltip-revision">
                        <font-awesome-icon :icon="['fas', 'hourglass-half']" />
                    </VueCustomTooltip>
                </span>
                <span>
                    <span v-if="isActualExtreme"
                    class="tooltip-fs"
                    :class="actualSemantics"
                    >
                        <VueCustomTooltip :label="actualExtremeTooltipText" position="is-left" class="eco-tooltip-revision">
                            <font-awesome-icon :icon="['fas', 'bolt']"/>&nbsp;
                            <font-awesome-icon :icon="['fa', actualArrow]" /> {{ actualValue }}
                        </VueCustomTooltip>
                    </span>
                    <span v-else-if="actualHigherOrLower"
                    class="tooltip-fs"
                    :class="actualSemantics"
                    >
                        <VueCustomTooltip v-if="actualArrow" :label="actualTooltipText" position="is-left" class="eco-tooltip-revision">
                            <!-- <font-awesome-icon :icon="['fa', actualArrow]" />  -->
                            {{ actualValue }}
                        </VueCustomTooltip>
                    </span>
                    <span v-else>
                        {{ actualValue }}
                    </span>
                    
                </span>
            </div>
            <div class="data-table--cell u-color-revision"
            :style="cellStyles"
            v-if="!isHoliday && !isSpeech">
                <span v-if="!forecastArrow">{{ forecastValue }}</span>
                <span v-else
                class="tooltip-fs u-color-revision"
                >
                    <VueCustomTooltip  :label="forecastTooltipText" position="is-left" class="eco-tooltip-revision">
                        <font-awesome-icon :icon="['fa', forecastArrow]" /> {{ forecastValue }}
                    </VueCustomTooltip>
                </span>
            </div>
            <div class="data-table--cell u-color-revision" 
            v-if="!isHoliday && !isSpeech" 
            style="position: relative;"
            :style="cellStyles">
                <padlock-overlay
                :impID="details.implementation_id"
                v-if="isLocked"
                class="sm-padlock blur"
                ></padlock-overlay>
                {{ minValue }}
            </div>
            <div class="data-table--cell u-color-revision" 
            v-if="!isHoliday && !isSpeech" 
            style="position: relative;"
            :style="cellStyles">
                <padlock-overlay
                :impID="details.implementation_id"
                v-if="isLocked"
                class="sm-padlock blur"
                ></padlock-overlay>
                {{ maxValue }}
            </div>
            <div class="data-table--cell wide-only" 
            v-if="!isHoliday && !isSpeech"
            :style="cellStyles"
            >
                <span v-if="!revised">{{ previousValue }}</span>
                <span v-else
                class="tooltip-fs"
                :class="revisedSemantics">
                    <VueCustomTooltip :label="revisedTooltipText" position="is-left" class="eco-tooltip-revision">
                        <font-awesome-icon :icon="['fa', revisedArrow]" /> {{ previousValue }}
                    </VueCustomTooltip>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '../../../../main';
import calEventMixin from '../../../../mixins/calendareventMixin';
import calStyleMixin from '../../../../mixins/calStyleMixin';
import { addTime, usersTime } from '../../../../utility/datetime';
import PadlockOverlay from '../../../../assets/PadlockOverlay.vue';

export default {
    props: ["calEvent", "details", "calHeight", "eventIndex", "nextEventIndex", "layoutID", "autoScroll", "countrySelection", "singleLine", "isLocked"],
    mixins: [calEventMixin, calStyleMixin],
    components: {
        PadlockOverlay
    },
    data() {
        return {
            isRowOpen: false,
            showHighLightClass: false,
            interval: null
        }
    },
    mounted() {

        eventBus.$on("scroll-to-next-event", id => {
            if(id === this.layoutID)
                if(this.isScrollTarget) 
                    this.emitUpcomingEvent();
                if(this.isNextUpcoming) {
                    this.showHighLightClass = true;

                    setTimeout(() => this.showHighLightClass = false, 2500);
                }
        });

        if(this.isScrollTarget && this.autoScroll) {
            setTimeout(() => {
                this.emitUpcomingEvent();
            },800);
            
        }
        

        if(this.isNextUpcoming) {
            this.showHighLightClass = true;

            setTimeout(() => this.showHighLightClass = false, 2500);
        }

        const now = usersTime(new Date(), true, true, true, { dateFormat : "y-m-d", timeFormat: "24hr" }, "UTC");
        const tenSecAgo = addTime(-10, 'seconds', now);
        let updatedAt = this.calEvent.updated_at;
        if(updatedAt) {
            updatedAt = updatedAt.replace(".000000Z", '');
            updatedAt = updatedAt.replace("T", ' ');

            if(tenSecAgo < updatedAt) {
                // console.log(tenSecAgo, updatedAt, this.calEvent.name);
                this.showHighLightClass = true;
                this.emitUpcomingEvent();
                setTimeout(() => this.showHighLightClass = false, 2500);
            }
        }
        
        
        
    },
    // updated() {
        
    // },
    beforeDestroy() {
        eventBus.$off("scroll-to-next-event");
    },
    methods: {
        openTableRow() {
            
            eventBus.$emit('open-modal', {
                instanceID: this.$root.instanceID,
                componentInModal: "ecocal-modal",
                classes: "terminal-modal simplified auto-height",                

                mode: "wide",
                panel: {
                    eventName: this.eventName,
                    calEvent: this.calEvent,
                    isNextUpcoming: this.isNextUpcoming,
                    isLocked: this.isLocked,
                    impID: this.details.implementation_id
                }
            });

        },
        emitUpcomingEvent() {
                
            const eventRow = this.$refs.upcomingRelease;
            if(eventRow) {
                setTimeout(() => {
                    const position = eventRow.offsetTop;
                    this.$emit("upcoming-event-position", position);
                },300);
                
            }
            
        },
        
    },
    watch: {
        countrySelection() {
            setTimeout(() => {
                if(this.isScrollTarget) 
                    this.emitUpcomingEvent();
                if(this.isNextUpcoming) {
                    this.showHighLightClass = true;

                    setTimeout(() => this.showHighLightClass = false, 2500);
                }
            },400);
            
        }
    }
}
</script>

<style lang="scss" scoped>
    .data-table--cell {
        box-sizing: border-box;

        & .row-title-label-name {
            display: inline-block;
        }
    }
    .tooltip-fs {
        border-bottom-width: 1px;
        border-bottom-style: dashed;
        border-bottom-color: white;

        &.u-better-than {
            border-bottom-color: $core-lightgreen;
        }
        &.u-worse-than {
            border-bottom-color: $core-lightred;
        }
        &.u-color-revision {
            border-bottom-color: $color-16;
        }

    }
    
    .single-line {
        height: 100%;
    }
    .data-table--cell.merged-cell {
        grid-column: 3 /- 1;
    }
    .size-sm .data-table--cell.merged-cell {
        grid-column: 4 /- 1;
    }

    .size-md .data-table--cell.merged-cell {
        grid-column: 5 /- 1;
    }


    
</style>
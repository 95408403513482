<template>
    <div class="rule-setup dot-checkbox">
        <label style="display: inline-block;">
            <input type="radio" :value="1" v-model="allWidgetsSelected" @change="allWidgetsSelectedChanged"/>
            <span>Everything</span>
        </label>
        <label>
            <input type="radio" :value="0" v-model="allWidgetsSelected" @change="allWidgetsSelectedChanged"/>
            <span>Select specific features</span>
        </label>
        
        <label  style="display: inline-block;" v-if="entityQnaCustom">
            <input type="checkbox" :checked="qna" @change="clickAndUpdate"/>
            <span>Access to Q&A</span>
            <!-- <span v-if="showTickedQnaBecauseQnaIsOnPreviousRequest"> (ticked because user has Q&A froma previous access)</span> -->
            <span style="font-size: 12px; color: #ff5656;" v-if ="qnaActiveOnPrevRequest === 'previous active'"> User has previous Q&A access, to remove Q&A, revoke ALL previous accesses and create a new one. </span>
            <span style="font-size: 12px; color: #ff5656;" v-if ="showTickedQnaBecauseAnalChatIsOn && qnaClickedOnce"> You cannot unselect Q&A if Analyst Chat is included</span>
        </label>
        
        <div class="rule-setup-body">
            <vue-slide-up-down :active="hasOpenedSelector" :duration="500" class="vue-slide-up-down rule-setup-body-item-selector-action">
                <div 
                class="rule-setup-body-item-selector">
                    <h3 >Included widgets</h3>
                    <p :class="{'u-color-12': error.widget}" v-if="!hasSelectedWidgets">Your setup doesn't include any widgets.<br />
                    <span v-html="explainerText"></span></p>
                    <div 
                    v-if="hasSelectedWidgets && !widgetSelectorOpen"
                    class="selected-widgets">
                        <a 
                        v-for="widget in selectedWidgets"
                        :key="widget.implementation_id"
                        class="widget-tags"
                        href="javascript:void(0);"
                        >{{ widget.name }}</a>
                    </div>
                    <vue-slide-up-down :active="widgetSelectorOpen" :duration="250" class="vue-slide-up-down rule-setup-body-item-selector-action">
                        <div style="margin-top: 10px;">
                            <h4>Widgets</h4>
                            <p style="margin: 0 0 7px;">Selected widgets</p>
                            <div class="selected-widgets">
                                <a 
                                v-for="widget in selectedWidgets"
                                :key="widget.implementation_id"
                                class="widget-tags"
                                href="javascript:void(0);"
                                @click="addWidget('suggested', widget.implementation_id)">{{ widget.name }} <font-awesome-icon
                            :icon="['fas', 'minus']" style="margin-left=5px;"/></a>
                            </div>
                            <h4 style="font-size: 12px; margin: 7px 0;">Suggested widgets</h4>
                            <div class="suggested-widgets">
                                <a 
                                v-for="widget in suggestedWidgets"
                                :key="widget.implementation_id"
                                class="widget-tags"
                                href="javascript:void(0);"
                                @click="addWidget('selected', widget.implementation_id)">{{ widget.name }} <font-awesome-icon
                            :icon="['fas', 'plus']" style="margin-left=5px;"/></a>
                            </div>
                        </div>
                    </vue-slide-up-down>
                    <div v-if="widgetSelectorOpen" 
                    class="widget-buttons" style="margin-top:15px;">
                        <base-button
                        mode="o-tranparent-grey o-white-text o-semibolt-text o-height-30"
                        :link="false"
                        @click.native="cancelWidgetSelection"
                        >Cancel
                        </base-button>
                        <base-button
                        style="margin-left: 15px;"
                        mode="o-tranparent-grey o-white-text o-semibolt-text o-height-30"
                        :link="false"
                        @click.native="saveWidgetSelection"
                        >Save
                        </base-button>
                    </div>
                    <div v-else
                    class="widget-buttons" style="margin-top:15px;">
                            <base-button
                            mode="o-tranparent-grey o-white-text o-semibolt-text o-height-30"
                            :link="false"
                            @click.native="addAllWidgets"
                            >Add all
                            </base-button>
                            <base-button
                            style="margin-left: 15px;"
                            mode="o-tranparent-grey o-white-text o-semibolt-text o-height-30"
                            :link="false"
                            @click.native="widgetSelectorOpen = true"
                            >Add individually
                            </base-button>
                            <base-button
                            v-if="hasSelectedWidgets"
                            style="margin-left: 15px;"
                            mode="o-tranparent-red o-red-text o-semibolt-text o-height-30"
                            :link="false"
                            @click.native="removeAllWidgets"
                            >Remove All
                            </base-button>
                        </div>
                </div>
            </vue-slide-up-down>   
        </div>
    </div>
</template>

<script>
import Constants from '../../../../utility/constants';
import { createNamespacedHelpers } from 'vuex';

const general = createNamespacedHelpers("general");
// const widgets = createNamespacedHelpers("widget");

export default {
    props: ["requestedWidgets", "explainerText", "qnaActiveOnPrevRequest", "qnaStartingValue"],
    created() {
        if(!this.requestedWidgets)
            this.allWidgetsSelected = 1;

        if(this.allWidgetsSelected) 
            this.addAllWidgets(true);
        else if(this.requestedWidgets.length) {
            this.suggestedWidgets = [...this.widgetsToShow];
            this.requestedWidgets.forEach(el => {
                this.hasOpenedSelector = true;
                this.addWidget("selected", el);
                this.suggestedWidgets = this.suggestedWidgets.filter(e => e.implementation_id != el);
                this.saveWidgetSelection();

            });
        }
        else {
            this.suggestedWidgets = [...this.widgetsToShow];
            this.oldSuggestedWidgets = [...this.suggestedWidgets];
            this.oldSelectedWidgets = [...this.selectedWidgets];
        }
        this.hasSelectedWidgets = !!this.selectedWidgets.length;
        // this.widgetSelectModeChanged({ target: { value: '1'}});
            
        if(this.qnaActiveOnPrevRequest === 'previous inactive' && !this.qna)
            this.qna = false;
        if(this.qnaActiveOnPrevRequest === 'no previous')
            this.qna = this.qnaStartingValue;


    },
    computed: {
        ...general.mapGetters(["entityQnaCustom", "entityQnaOff"]),
        // ...payments.mapGetters(['widgetsToSelectFromForHomeScreen']),

        widgetsToShow() {
            const widgets = window.bootUp.widgets.filter(el => el.code !== "TERMINAL");
            if(this.entityQnaOff)
                return window.bootUp.widgets.filter(el => el.code !== "ANALCHAT");
            
            return widgets;
        },
        selectedWidgetImpIDs() {
            return this.selectedWidgets.map(el => el.implementation_id);
        },
    },
    data() {
        return {
            selectedWidgets: [],
            suggestedWidgets: [],
            oldSuggestedWidgets: [],
            oldSelectedWidgets: [],
            hasSelectedWidgets: false,
            widgetSelectorOpen: true,
            hasOpenedSelector: false,
            allWidgetsSelected: 0,
            error: {},
            qna: true,
            firstRender: true,
            // showTickedQnaBecauseQnaIsOnPreviousRequest: false,
            showTickedQnaBecauseAnalChatIsOn: false,
            qnaClickedOnce: false

        }
    },
    methods: {
        addWidget(group, impID) {
            const toAdd = this.widgetsToShow.find(el => el.implementation_id === impID)
            // console.log(group, impID, toAdd, window.bootUp.widgets)
            if(!toAdd)
                return;

            if(group === 'selected') {
                this.selectedWidgets.push(toAdd);
                this.suggestedWidgets = this.suggestedWidgets.filter(el => el.implementation_id != impID);
            }
            else{
                this.suggestedWidgets.push(toAdd);
                this.selectedWidgets = this.selectedWidgets.filter(el => el.implementation_id != impID)
            }

        },
        addAllWidgets(withSave=false) {
            this.widgetSelectorOpen = true;
            setTimeout(() => {
                this.suggestedWidgets = [];
                this.selectedWidgets = [...this.widgetsToShow];

                if(withSave)
                    this.saveWidgetSelection();

            }, 750);
        },
        removeAllWidgets(withSave=false) {
            this.widgetSelectorOpen = true;
            setTimeout(() => {
                this.selectedWidgets = [];
                this.suggestedWidgets = [...this.widgetsToShow];

                    if(withSave)
                        this.saveWidgetSelection();

            }, 750);
        },
        cancelWidgetSelection() {
            this.selectedWidgets = [...this.oldSelectedWidgets];
            this.suggestedWidgets = [...this.oldSuggestedWidgets];
            setTimeout(() => {
                this.widgetSelectorOpen = false;
            }, 500);
        },
        saveWidgetSelection() {
            this.widgetSelectorOpen = false;
            this.oldSelectedWidgets = [...this.selectedWidgets];
            this.oldSuggestedWidgets = [...this.suggestedWidgets];

            this.$emit("widget-selection-changed", this.selectedWidgetImpIDs);
         
        },
        allWidgetsSelectedChanged(val) {
            const value = +val.target.value;
            if(value) {
                this.addAllWidgets(true);
            }
            else
                this.removeAllWidgets(true);
            
            this.hasOpenedSelector = !(value);
            

        },
        checkIfQnaShouldBeOff() {
            if(this.entityQnaCustom) {
                // console.log(this.qnaActiveOnPrevRequest, this.firstRender, this.qnaStartingValue)
                if(this.qnaActiveOnPrevRequest === 'previous active' /*&& this.firstRender*/) {
                    if(this.firstRender) {
                        // if(!this.qnaStartingValue)
                            // this.showTickedQnaBecauseQnaIsOnPreviousRequest = true;
                        // this.firstRender = false;
                        setTimeout(() => {
                            this.qna = true;
                        }, 100);
                    }
                    return;
                }
                // this.showTickedQnaBecauseQnaIsOnPreviousRequest = false;

                //after first render, let admin choose 
                if(this.qnaActiveOnPrevRequest === 'previous active')
                    return;

                const selectedQnaWidgets = this.selectedWidgets.filter(el => Constants.QNA_WIDGETS.includes(el.fs_version_of_widget_for_brokers));
                this.showTickedQnaBecauseAnalChatIsOn = false;
                if(this.selectedWidgets.find(el => el.code === 'ANALCHAT')) {
                    this.showTickedQnaBecauseAnalChatIsOn = true;
                    setTimeout(() => {
                        this.qna = true;
                    }, 100);
                }

                
                if(!selectedQnaWidgets.length) {
                    setTimeout(() => {
                        this.qna = false;
                    }, 100);
                }
                    
                
                
                
            }
        },
        clickAndUpdate() {
            this.qnaClickedOnce = true;
            this.qna = !this.qna;
            this.checkIfQnaShouldBeOff();
        }
    },
    watch: {
        allWidgetsSelected(val) {
            if(val) {
                this.addAllWidgets();
            }
            else {
                this.removeAllWidgets();
            }
            this.saveWidgetSelection();
        },
        selectedWidgets(val) {
            // console.log("sel",val)
            if(val.length)
                this.hasSelectedWidgets = true;
            else
                this.hasSelectedWidgets = false;

            this.checkIfQnaShouldBeOff();

            
        },
        qna(val) {
            this.checkIfQnaShouldBeOff();
            this.$emit("qna-on-changed", !!(+(val)));
        },
    },
}
</script>
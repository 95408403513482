<template>
    <div style="margin-top:43px;">
        <div class="steps-tracker">Step 3 of 3</div>
        <h2 class="align-center">Enter your payment details</h2>
        <div class="form-text-div dimmed align-center subtitle">The details below must match the ones connected to your payment card.</div>
        <div class="card-icon-div" style="text-align: center;">

            <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.75 0.25H2.25C1.14543 0.25 0.25 1.14543 0.25 2.25V13.75C0.25 14.8546 1.14543 15.75 2.25 15.75H21.75C22.8546 15.75 23.75 14.8546 23.75 13.75V2.25C23.75 1.14543 22.8546 0.25 21.75 0.25Z" fill="white" stroke="#1C1C1C" stroke-opacity="0.2" stroke-width="0.5"/>
            <path d="M2.78773 5.91444C2.26459 5.62751 1.66754 5.39674 1 5.23659L1.028 5.11188H3.76498C4.13596 5.12489 4.43699 5.23651 4.53495 5.63071L5.12977 8.46659L5.31198 9.32073L6.97797 5.11188H8.77679L6.10288 11.2775H4.30397L2.78773 5.91444ZM10.1 11.2841H8.39883L9.46285 5.11188H11.1639L10.1 11.2841ZM16.2668 5.26277L16.0354 6.59559L15.8816 6.53004C15.5737 6.40525 15.1674 6.28054 14.6144 6.29371C13.9427 6.29371 13.6415 6.56277 13.6345 6.82546C13.6345 7.11441 13.9989 7.30484 14.5939 7.58725C15.574 8.02719 16.0286 8.56557 16.0218 9.26819C16.0081 10.5486 14.846 11.3761 13.0611 11.3761C12.2979 11.3694 11.5628 11.2181 11.1638 11.0476L11.4019 9.66205L11.6259 9.76066C12.1789 9.99071 12.5428 10.089 13.222 10.089C13.7118 10.089 14.2369 9.89838 14.2436 9.48488C14.2436 9.21565 14.0199 9.01851 13.3617 8.71646C12.7178 8.42087 11.8568 7.92848 11.8708 7.04198C11.8781 5.84042 13.0611 5 14.741 5C15.399 5 15.9312 5.13789 16.2668 5.26277ZM18.5278 9.09749H19.9417C19.8718 8.78889 19.5496 7.31147 19.5496 7.31147L19.4307 6.77964C19.3467 7.00943 19.1999 7.38373 19.2069 7.37056C19.2069 7.37056 18.6678 8.7429 18.5278 9.09749ZM20.6276 5.11188L22 11.284H20.4249C20.4249 11.284 20.2708 10.5748 20.2219 10.3581H18.0378C17.9746 10.5222 17.6808 11.284 17.6808 11.284H15.8958L18.4226 5.62399C18.5977 5.22342 18.906 5.11188 19.3118 5.11188H20.6276Z" fill="#171E6C"/>
            </svg>
            <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 0H2C0.89543 0 0 0.89543 0 2V14C0 15.1046 0.89543 16 2 16H22C23.1046 16 24 15.1046 24 14V2C24 0.89543 23.1046 0 22 0Z" fill="#22294E"/>
            <path d="M9 13C11.7614 13 14 10.7614 14 8C14 5.23858 11.7614 3 9 3C6.23858 3 4 5.23858 4 8C4 10.7614 6.23858 13 9 13Z" fill="#EB001B"/>
            <path d="M15 13C17.7614 13 20 10.7614 20 8C20 5.23858 17.7614 3 15 3C12.2386 3 10 5.23858 10 8C10 10.7614 12.2386 13 15 13Z" fill="#F79E1B"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3.99963C13.2144 4.91184 14 6.36418 14 8C14 9.63582 13.2144 11.0882 12 12.0004C10.7856 11.0882 10 9.63582 10 8C10 6.36418 10.7856 4.91184 12 3.99963Z" fill="#FF5F00"/>
            </svg>
            <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 0H2C0.89543 0 0 0.89543 0 2V14C0 15.1046 0.89543 16 2 16H22C23.1046 16 24 15.1046 24 14V2C24 0.89543 23.1046 0 22 0Z" fill="#016FD0"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7637 13.3939V7.6925L23.9112 7.70161V9.27651L22.7383 10.5299L23.9112 11.7948V13.4031H22.0386L21.0434 12.3049L20.0553 13.4072L13.7637 13.3939Z" fill="#FFFFFE"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4414 12.7688V8.31995H18.2137V9.34483H15.6628V10.0405H18.1529V11.0483H15.6628V11.7317H18.2137V12.7688H14.4414Z" fill="#016FD0"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1954 12.7687L20.2827 10.5417L18.1953 8.31995H19.811L21.0865 9.72998L22.3656 8.31995H23.9117V8.35495L21.8689 10.5417L23.9117 12.7056V12.7687H22.35L21.0519 11.3446L19.7671 12.7687H18.1954Z" fill="#016FD0"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2369 2.63196H16.6829L17.5421 4.58281V2.63196H20.5619L21.0827 4.09353L21.6052 2.63196H23.9111V8.33335H11.7246L14.2369 2.63196Z" fill="#FFFFFE"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.7006 3.25134L12.7266 7.69651H14.0805L14.4529 6.80635H16.4708L16.843 7.69651H18.2306L16.2648 3.25134H14.7006ZM14.8702 5.80878L15.4622 4.39371L16.0538 5.80878H14.8702Z" fill="#016FD0"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.2129 7.69569V3.25061L20.116 3.25715L21.0953 5.98988L22.0809 3.25061H23.9125V7.69569L22.7339 7.70612V4.65278L21.6213 7.69569H20.5455L19.4099 4.64235V7.69569H18.2129Z" fill="#016FD0"/>
            </svg>
        </div>
        <form @submit.prevent class="container">
    
            <!-- fields -->
            <div class="card-details-wrapper">
                <fieldset style="padding-bottom: 0">
                    <div class="form-group featured required label-transition field-input">
                        <label for="name" class=""><span>Name on Card</span></label> 
                        <div class="field-wrap">
                            <div class="wrapper">
                                <input id="name" type="text" placeholder="Name on Card *" required="required" class="form-control-fs" v-model="cardDetails.name.val" :class="{'invalid-input': cardDetails.name.error}">
                            </div>
                        </div>
                    </div>
                </fieldset>
                <!-- <base-input for-i-d="cardName" label="Name on Card" v-model="cardDetails.name"
                element-type="input" input-type="text"/> -->
                <div>
                    <fieldset>
                        <div class="card-details">
                            <div class="border" id="card-number-o" :class="{'invalid-input': cardErrorCode === 'incomplete_number'}">
                                 <label>Card Number</label>
                                <div class="" id="card-number"></div>
                            </div>
                            <div class="border" id="card-expiry-o" :class="{'invalid-input': cardErrorCode === 'incomplete_expiry'}">
                                 <label>Card Expiry</label>
                                <div class="" id="card-expiry"></div>
                            </div>
                            <div class="border" id="card-cvc-o" :class="{'invalid-input': cardErrorCode === 'incomplete_cvc'}">
                                 <label>CVC</label>
                                <div class="" id="card-cvc"></div>
                            </div>
                        </div>
                    </fieldset>
                    
                </div>
            </div>
            <!-- <legend>Billing Information:</legend> -->
            <div>
                <div>
                    <fieldset>
                        <div>
                            <div>
                                <!-- <place-autocomplete-field
                                    v-model="cardDetails.line1.val"
                                    label="Search Address"
                                    name="field2"
                                    api-key="AIzaSyCEFq_JCwQL-0UoaYPxEFiVPitVudW15lE"
                                    placeholder="Enter an an address, zipcode, or location"
                                    @autocomplete-select="autocompleteCountry"
                                    v-address-autofill:street="cardDetails.line1.val"
                                    v-address-autofill:city="cardDetails.city.val"
                                    v-address-autofill:state="cardDetails.state.val"
                                    v-address-autofill:zipcode="cardDetails.address.val">
                                </place-autocomplete-field> -->
                            </div>
                            <div>
                                <div class="form-group featured required label-transition field-input">
                                    <label for="street-address" class=""><span>Street address</span></label> 
                                    <div class="field-wrap">
                                        <div class="wrapper">
                                            <input for-i-d="line1" id="street-address" type="text" placeholder="Street address *" required="required" class="form-control-fs" v-model="cardDetails.line1.val" :class="{'invalid-input': cardDetails.line1.error}">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="lg-grid-2 lg-grid-gap-22">
                                <!-- <div class="form-group featured required label-transition field-input">
                                    <label for="city" class=""><span>City</span></label> 
                                    <div class="field-wrap">
                                        <div class="wrapper">
                                            <input for-i-d="city" id="city" type="text" placeholder="City *" required="required" class="form-control-fs" v-model="cardDetails.city.val" :class="{'invalid-input': cardDetails.city.error}">
                                        </div>
                                    </div>
                                </div> -->
                                <div class="form-group featured required label-transition field-input">
                                    <label for="country" class=""><span>Country</span></label> 
                                    <div class="field-wrap">
                                        <div class="wrapper">
                                            <select for-i-d="country" id="country" type="text" placeholder="Country *" required="required" class="form-control-fs" v-model="cardDetails.country.val"
                                            
                                            >
                                                <option value="" disabled selected hidden class="select-prompt">Country *</option>
                                                 <optgroup label="Main">
                                                    <option
                                                            v-for="country in mainCountries"
                                                            :key="country.alpha3Code"
                                                            :value="country.alpha3Code"
                                                            :selected="cardDetails.country.val === country.alpha3Code"
                                                    >{{ country.name }}
                                                    </option>
                                                </optgroup>

                                                <optgroup label="Others">
                                                    <option
                                                            v-for="country in otherCountries"
                                                            :key="country.alpha3Code"
                                                            :selected="cardDetails.country.val === country.alpha3Code"
                                                            :value="country.alpha3Code"
                                                    >{{ country.name }}
                                                    </option>
                                                </optgroup>

                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group featured required label-transition field-input">
                                    <label for="zipcode" class=""><span>Zip Code</span></label> 
                                    <div class="field-wrap">
                                        <div class="wrapper">
                                            <input for-i-d="zipcode" id="zipcode" type="text" placeholder="Zip Code *" required="required" class="form-control-fs" v-model="cardDetails.address.val" :class="{'invalid-input': cardDetails.address.error}">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <!-- <div class="form-group featured required label-transition field-input">
                                    <label for="state" class=""><span>State</span></label> 
                                    <div class="field-wrap">
                                        <div class="wrapper">
                                            <input for-i-d="state" id="state" type="text" placeholder="State *" required="required" class="form-control-fs" v-model="cardDetails.state.val" :class="{'invalid-input': cardDetails.state.error}">
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <div>
                                <div class="form-control-fs">
                                    <vue-phone-number-input 
                                    v-model="phoneCountry"
                                    :dark="true"
                                    :darkColor="'#22294E'"
                                    :no-example="true"
                                    :no-validator-state="true"
                                    :default-country-code="selectedPhoneCountry" 
                                    @update="updatePhoneCountrySelect"
                                    :class="{'invalid-input': cardDetails.phone.error}"
                                    ></vue-phone-number-input>
                                </div>
                            </div>
                        </div>

                        <div class="plan-box" style="margin-top: 32px;">
                            <div class="plan-box-details">
                                <div class="plan-box-details-top">
                                    <div class="plan-box-details-price">${{ selectedPrice }}</div>
                                    <div class="plan-box-details-period">{{ subscription }}</div>
                                </div>
                                <div class="plan-box-details-bottom">
                                    <div class="plan-box-details-summary">{{ selectedPlanName }} {{ isVAT }}</div>
                                </div>
                            </div>
                            <div class="plan-box-controls"><a href="javascript:void(0);" @click="changePlan">Change</a></div>
                        </div>

                        <div class="tsandcs-box">
                            <label class="tsandcs"><span>You agree to our <a href="javascript:void(0);" @click="openTerms">terms and conditions</a></span>
                                <input type="checkbox" v-model="cardDetails.termsAndConditions.val">
                            <span class="checkmark" :class="{'invalid-input': cardDetails.termsAndConditions.error}"></span>
                            </label>
                            <!-- <input type="checkbox" name="tsandcs" id="tcandcs" /><label for="tsandcs">You agree to our <a href="javascript:void(0);">terms and conditions</a></label> -->
                        </div>
                        
                    </fieldset>
                    


                    <!-- <div style="width: 25%;float: left;  padding-top: 5px">

                        <base-input for-i-d="line1" label="Street address" element-type="input"
                                    v-model="cardDetails.line1" input-type="text"
                                    :input-class="{invalidMessage: !cardDetails.line1 && hasErrors}"/>
                    </div>
                    <div style="width: 15%;  float: left; transform: translateX(20%); padding-top: 5px">
                        <base-input for-i-d="state" label="State/County" element-type="input"
                                    :input-class="{invalidMessage: !cardDetails.state && hasErrors}"
                                    v-model="cardDetails.state" input-type="text"/>
                    </div> -->

                </div>
                <!-- <div style="width: 100%; clear: both; padding-top: 5px"> -->
                    <!-- <div style="width: 15%;  float: left; transform: translateX(40%); padding-top: 5px">
                        <base-input for-i-d="city" label="City" element-type="input"
                                    :input-class="{invalidMessage: !cardDetails.city && hasErrors}"
                                    v-model="cardDetails.city" input-type="text"/>
                    </div>
                    <div style="width: 36%;  float: left; transform: translateX(25%); padding-top: 5px">
                        <base-input for-i-d="zipCode" label="Zip code" element-type="input"
                                    :input-class="{invalidMessage: !cardDetails.address && hasErrors}"
                                    v-model="cardDetails.address" input-type="text"/>
                    </div> -->
                <!-- </div> -->
                <!-- <div style="width: 100%; clear: both; padding-top: 5px"> -->
                    <!-- <div style="width: 50%;float: left; padding-top: 5px">
                        <base-input
                                for-i-d="country" label="Country" element-type="select"
                                :input-class="{invalidMessage: !cardDetails.country && hasErrors}"
                                v-model="cardDetails.country" input-type="text"
                                :inputClass="'flat'"
                                invalidMessage="Country not valid"
                        >
                            <optgroup label="Main">
                                <option
                                        v-for="country in mainCountries"
                                        :key="country.alpha3Code"
                                        :value="country.alpha2Code"
                                        :selected="cardDetails.country === country.alpha2Code"
                                >{{ country.name }}
                                </option>
                            </optgroup>

                            <optgroup label="Others">
                                <option
                                        v-for="country in otherCountries"
                                        :key="country.alpha3Code"
                                        :selected="cardDetails.country === country.alpha2Code"
                                        :value="country.alpha2Code"
                                >{{ country.name }}
                                </option>
                            </optgroup>


                        </base-input>
                    </div>
                    <div style="width: 47%;  float: right;padding-top: 5px">
                        <base-input for-i-d="phone" label="Phone" element-type="input"
                                    :input-class="{invalidMessage: !cardDetails.phone && hasErrors}"
                                    v-model="cardDetails.phone" input-type="text"/>
                    </div> -->
                <!-- </div> -->

            </div>
            <div id="card-error">
                <span v-for="error in errorMessages" :key="error">{{ error }}<br/></span>
            </div>
        



            <!-- fields END -->




            <div class="container">
                <div class="button-div mt-10">
                    <base-button
                    :mode="buttonMode"
                    :link="false"
                    :disabled="showSpinner"
                    :showSpinner="showSpinner"
                    @click.native="makePayment"
                    >Continue</base-button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import PaymentService from "@/services/payments/payment.service";
import Constants from "@/utility/constants"
import {formatPriceObject, precise } from "../../../utility/helper-functions";
// import {store} from "../../../store/store";
import AccountService from "../../../services/account/account.service";
import _ from "lodash";
import {createNamespacedHelpers} from "vuex";
import AppService from "../../../services/app.service";
import {AddressAutofill} from "../../../directives/PlaceAutofill";
import OnBoardMixin from '../../../mixins/onBoardMixin';
import SubscriptionMixin from '../../../mixins/SubscriptionMixin';
import PlanDetailsHandler from "../../../mixins/plan.details.handler";

import VuePhoneNumberInput from 'vue-phone-number-input';
import { eventBus } from '../../../main';
import SharedMixins from '../../../mixins/SharedMixins';

const general = createNamespacedHelpers('general');
const payments = createNamespacedHelpers('payments');

export default {
    name: "UpdateCard",
    directives: {AddressAutofill},
    components: {
        VuePhoneNumberInput
    },
    mixins: [OnBoardMixin, SubscriptionMixin, PlanDetailsHandler, SharedMixins],
    props: {
        showMakePayment: Boolean,
        closeSpinner: {
            type: Boolean,
            default: true
        },
        details: Object
    },
    data() {
        return {

            field2: '',
            userContries: Constants["USER_COUNTRIES"],
            apiKey: Constants['STRIPE_API_KEY'],
            token: null,
            style: {
                base: {
                    color: 'white',
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: 'antialiased',
                    fontSize: '14px',
                    '::placeholder': {
                        color: '#727B9A',
                    },
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a',
                },
            },
            card: {
                cardNumber: null,
                cardExpiry: null,
                cardCvc: null,
            },
            errorMessages: [],
            cardErrorCode : '',
            cardDetails: {
                termsAndConditions: {
                    val: false,
                    error: ''
                },
                name: {
                    val: '',
                    error: ''
                },
                country: {
                    val: '',
                    error: ''
                },
                // city: {
                //     val: '',
                //     error: ''
                // },
                // state: {
                //     val: '',
                //     error: ''
                // },
                address: {
                    val: '',
                    error: ''
                },
                line1: {
                    val: '',
                    error: ''
                },
                phone: {
                    val: '',
                    error: ''
                },
            },
            sms_country: '',
            sms_num: '',
            countries: [],
            phoneCountry: '',
            selectedPhoneCountry: '',
            formValid: true,
            invalidInputs: [],
            showSpinner: false,
            
        }
    },
    computed: {
        ...general.mapState(['user']),
        ...payments.mapGetters(['failedPayment']),
        stripeElements() {
            return this.$stripe.elements();
        },
        hasErrors() {
            return this.errorMessages.length > 0;
        },
        mainCountries: function () {
            return this.countries.filter(country => country.group === "main")
        },
        otherCountries: function () {
            return this.countries.filter(country => country.group !== "main")
        },
        plan() {
            // console.log(this.details)
            let comm =  Object.values(this.availableProducts).find(el => el.id === this.details.plan)?.commitments.find(el => el.id === this.details.commitment);
            // if(!comm)
            //     this.mergedPlans.forEach(el => {
            //         el.commitments.forEach(e => {
            //             if(e.id === this.details.commitment)
            //                 comm = e;
            //         });
            //     });
            return comm;
           
        },
        selectedCommitment() {
            return this.plan;
        },
        selectedPrice() {
            let price = formatPriceObject(this.plan.pricing, false);
            
            const vat = this.$store.state.general.user.sales_tax_percent;
            // console.log("this.plan.pricing", vat);
            if(this.isVAT && !vat)
                return !isNaN(price) ? precise(price * 1.2) : price
            else if(!this.isVAT && vat) {
                return !isNaN(price) ? precise(price / 1.2) : price
            }

            return price;
           
        },
        selectedPlanName() {
            if(this.plan.parent_id)
                return (Object.values(this.availableProducts).find(el => el.id === this.plan.parent_id)).name;
                
            return this.plan.name;
        },
        isVAT() {
            if(this.cardDetails.country.val === 'GBR') 
                return "Inc UK VAT";

            return "";
        },
        buttonMode() {
            const classes = " fullwidth";
            // if(this.formValid)
                return "o-blue" + classes;

            // return "o-grey" + classes;
        },
        selectedCountry: function () {
            return this.cardDetails.country.val;
        },
        
    },
    methods: {
        ...general.mapActions(['loadCurrentUserSettings']),
        ...payments.mapActions(['loadPaymentMethod','purchasePlan']),
        initializeStripeElements: function (style) {
            this.card.cardNumber = this.stripeElements.create('cardNumber', {showIcon: true, style});
            this.card.cardNumber.mount('#card-number');
            this.card.cardExpiry = this.stripeElements.create('cardExpiry', {style});
            this.card.cardExpiry.mount('#card-expiry');
            this.card.cardCvc = this.stripeElements.create('cardCvc', {style});
            this.card.cardCvc.mount('#card-cvc');

        },
        destroyStripeElements: function () {
            this.card.cardNumber.destroy();
            this.card.cardExpiry.destroy();
            this.card.cardCvc.destroy();
        },
        initializeBillingAddress: function () {
            if(this.user?.address1) {
                this.cardDetails.address.val = this.user.postal_code;
                // this.cardDetails.state.val = this.user.region;
                // this.cardDetails.city.val = this.user.city;
                this.cardDetails.country.val = this.details.country;
                this.cardDetails.phone.val = this.user.phone_number;
                this.cardDetails.line1.val = this.user.address1;
            }
        },
        validateEmptyFields: function (attributes, length) {
            let isValid = true;
            if (Object.keys(attributes).length >= length) {
                Object.values(attributes).forEach(value => {
                    if (!value) {
                        
                        isValid = false;
                    }
                })
            } else {
                isValid = false;
            }
            return isValid;
        },
        getCountrySystemCode: function () {
            return this.userContries.find(country => country.alpha2Code === this.cardDetails.country.val)?.alpha3Code || '';
        },
        getCountryStripeCode: function (value) {
            return this.countries.find(country => country.alpha3Code === value)?.alpha2Code || '';
        },
        // autocompleteCountry: function (place, geocoder) {
        //     let country = geocoder.address_components.find(address => address.types.includes('country'));
        //     if (country?.short_name) {
        //         this.cardDetails.country.val = country.short_name;
        //         console.log(country.short_name);
        //     }
            
        // },
        listCountryCodesAndCurrentCountry: function () {
            AppService.listGeneralCountryCodes().then(result => {
                this.countries = result;
                this.initializeBillingAddress();
            });
        },
        saveCard: function (token) {
            let context = this;
            return new Promise(resolve => {
                // let acceptTerms = this.cardDetails.termsAndConditions;
                // if (!acceptTerms) {
                //     context.errorMessages = ["Please accept the terms and conditions!"];
                // }
                // let phoneLength = this.cardDetails.phone?.length;
                // if (phoneLength >= 12) {
                //     context.errorMessages = ["The phone number cannot be greater than 12 characters!"];
                // }

                let valid = true;
                Object.entries(this.cardDetails).forEach(([key, value]) => {
                    if(!value.val) {
                        console.log("invalid",key);
                        this.cardDetails[key].error = true;
                        valid = false;
                    }

                });

                this.formValid = valid;

                let isValid = this.validateEmptyFields(this.cardDetails, 6) && valid;

                if (isValid && context.errorMessages.length === 0) {
                    this.$stripe.confirmCardSetup(token, {
                        payment_method: {
                            card: context.card.cardNumber,
                            billing_details: {
                                name: context.cardDetails.name.val,
                                phone: context.cardDetails.phone.val,
                                address: {
                                    postal_code: context.cardDetails.address.val,
                                    // city: context.cardDetails.city.val,
                                    country: this.getCountryStripeCode(context.cardDetails.country.val),
                                    // state: context.cardDetails.state.val,
                                    line1: context.cardDetails.line1.val
                                }
                            }
                        }
                    }).then(function (result) {
                        if (result.error) {
                            // console.log("error", result);
                            context.showSpinner = false;
                            //"incomplete_expiry", "incomplete_number", "incomplete_cvc"
                            context.errorMessages.push(result.error.message);
                            context.cardErrorCode = result.error.code;
                            context.$emit("update-step", {
                                step: "7",
                                plan: this.details.plan
                            });
    
                            
                        } else {
                            context.updateBillingAddress().then((countryChanged) => {
                                // context.$emit("card-updated");
                                // if (!context.showMakePayment) {
                                //     Swal.fire({
                                //         title: 'Payment Method Added',
                                //         html: "Success! - Your new payment card will now be used for all purchases.",
                                //         type: 'info',
                                //         confirmButtonColor: '##1d9deb',
                                //         confirmButtonText: 'OK'
                                //     });
                                // }
                                // if(countryChanged) {
                                //     removeSpinner(store, "PAYMENT_CARD");
                                // }
                                
                                resolve({success: true, changed: countryChanged});
                            })
                        }
                    }).finally(() => {
                        
                        // if (this.closeSpinner) {
                        //     removeSpinner(store, "PAYMENT_CARD");
                        // }
                    });
                } else {
                    context.errorMessages.push("Please fill in all the information!");
                    // if (this.closeSpinner) {
                    //     removeSpinner(store, "PAYMENT_CARD");
                    // }
                    resolve({success: false});
                    context.showSpinner = false;
                }
            })
        },
        saveCardIntent(callback) {
            this.showSpinner = true;
            this.errorMessages = [];
            PaymentService.getSetupIntent().then(result => {
                let clientSecret = result?.data?.data?.client_secret;
                if (clientSecret) {
                    this.saveCard(clientSecret).then((result) => {
                        if (result.success && (typeof callback) === 'function') {
                            callback(result.changed)
                        }
                    });
                }
            })
        },
        makePayment: function () {
            const context = this;
            // this.saveCardIntent((changed) => this.$emit("make-payment", changed));
            this.saveCardIntent(() => {
                // console.log("changed", changed);
                // setTimeout(() => {
                context.loadPaymentMethod().then(() => {
                    context.purchasePlan(this.plan)
                    .then(() => {
                        context.$emit("update-step", {
                            step: "6",
                            plan: this.details.plan,
                        });
                    })
                    .catch(() => {
                        context.$emit("update-step", {
                            step: "7",
                            plan: this.details.plan
                        });
                    });
                });

                // }, 1000);
            });
        },
        updateBillingAddress: function () {
            let context = this;
            let selectedCountry = context.getCountrySystemCode();
            let isCountryChanged = this.user.country_code !== selectedCountry;
            return new Promise(resolve => {
                let currentUserData = _.cloneDeep(context.user);
                let userObj = {
                    ...currentUserData,
                    phone_number: context.cardDetails.phone.val,
                    sms_notification_country: "+" + context.sms_country,
                    sms_notification_number: context.sms_num,
                    postal_code: context.cardDetails.address.val,
                    // region: context.cardDetails.state.val,
                    // city: context.cardDetails.city.val,
                    country_code: selectedCountry.val,
                    address1: context.cardDetails.line1.val
                }
                AccountService.saveUserAccountSettings(userObj).then(result => {
                    if(result.data.success) {
                        context.loadCurrentUserSettings().then(() => {
                            let hasVatChanged = currentUserData.sales_tax_percent !== context.user.sales_tax_percent;
                            resolve(isCountryChanged && hasVatChanged);
                        });
                    } else {
                        resolve();
                    }
                })
            })
        },
        // modelUpdated() {
        //     console.log(this.topModel)
        //     return;
        // },
        updatePhoneCountrySelect(data) {
            if(data.isValid) {
                this.cardDetails.phone.val = data.e164; // TEMP!! //or formattedNumber
                this.sms_country = data.countryCallingCode; // TEMP!! //or formattedNumber
                this.sms_num = data.phoneNumber; // TEMP!! //or formattedNumber
            }
            else {
                this.cardDetails.phone.val = "";
                this.sms_country = ""; // TEMP!! //or formattedNumber
                this.sms_num = "";
            }
                
        },
        updateSelectedCountry: function () {
            
            if(this.details.country) {
                this.selectedPhoneCountry = Constants.USER_COUNTRIES.find(el => el.alpha3Code === this.selectedCountry)?.alpha2Code;
            }
        },
        changePlan() {
            this.$emit("update-step", {
                step: "4",
                plan: this.details.plan,
                country: this.cardDetails.country.val
            });
        },
        submitForm() {
            return;
        },
        openTerms() {
            const modal = {
                instanceID: this.$root.instanceID,
				title: "Terms & conditions",
                componentInModal: "terms-conditions",
				mode: 'narrow',
                close: true,
                classes: 'onboarding-modal',
                panel: {}
			}

            // console.log("emitting");

			eventBus.$emit('open-modal', modal);
        }
    },
    created() {
        // console.log("!!!",this.plan);
        this.listCountryCodesAndCurrentCountry();
        // console.log("details", this.details);
        this.cardDetails.name.val = window.user.name;
        this.selectedPhoneCountry = this.getCountryStripeCode(this.details.country);
        
    },
    mounted() {
        this.initializeStripeElements(this.style);
        // document.querySelector(".autocomplete-field > div").classList.add('label-transition');
        this.cardDetails.country.val = this.details.country;
        
    },
    beforeDestroy() {
        this.destroyStripeElements();
    },
    watch: {
        selectedCountry() {
            this.updateSelectedCountry();
        },
        cardDetails: {
            deep: true,
            handler: function (val) {
                let valid = true;
                if(!this.formValid)
                    Object.entries(val).forEach(([key, value]) => {
                        if(!value.val) {
                            // console.log("invalid",key);
                            this.cardDetails[key].error = true;
                            valid = false;
                        }else{
                            this.cardDetails[key].error = false;
                        }

                    });

                    if(valid)
                        this.errorMessages = [];
                
            }
        },
        failedPayment: {
            deep: true,
            handler: function (val) {
                if(!_.isEmpty(val))
                    this.$emit("update-step", {
                        step: "7",
                        plan: this.details.plan
                    });
            }
        }
    }
}
</script>

<style lang="scss">
    #MazPhoneNumberInput .country-selector__input,
    #MazPhoneNumberInput .input-tel__input {
        height: 48px!important;
        border-color: #565E7A;
    }
    #MazPhoneNumberInput .country-selector__input {
        border-top-left-radius: 10px!important;
        border-bottom-left-radius: 10px!important;
        border-top-right-radius: 0!important;
        border-bottom-right-radius: 0!important;

        font-family: sans-serif;
    }
    #MazPhoneNumberInput .input-tel__input {
        border-top-right-radius: 10px!important;
        border-bottom-right-radius: 10px!important;
        padding-top: 0;
        cursor: unset;
    }
    #MazPhoneNumberInput .country-selector__input:hover, 
    #MazPhoneNumberInput .input-tel__input:hover {
        border-color: #565E7A!important;
    }
    #MazPhoneNumberInput .country-selector__input:focus,
    #MazPhoneNumberInput .input-tel__input:focus {
        box-shadow: none!important;
        border-color: $input-focus-color!important;
    }
    #MazPhoneNumberInput .country-selector__label,
    #MazPhoneNumberInput .input-tel__label {
        font-weight: normal!important;
        color: $input-focus-color!important;
        transform: translateY(-10px);
        background-color: $input-background-color;
        padding-left: 3px;
        padding-right: 3px;
    }

    #MazPhoneNumberInput.invalid-input .input-tel__input,
    #MazPhoneNumberInput.invalid-input .country-selector__input {
        border-color: $color-12!important;
    }


    .plan-box {
        background-color: #2D3359;
        border-radius: 10px;
        padding: 10px 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-details-top {
            display: flex;
            align-items: center;
        }
        &-details-price {
            font-size: 18px;
            font-weight: bold;
        }
        &-details-period {
            margin-left: 7.5px;
            font-size: 12px;
            color: $input-color-filter;
        }
        &-details-summary {
            color: $input-placeholder-color;
            font-size: 14px;
        }

        &-controls {
            font-size: 12px;
        }
    }

    .autocomplete-list-item a {
        color: $input-border-color;
    }

    .border.invalid-input {
        border-color: $color-12;
    }

</style>

<style lang="scss" scoped>
    .card-details {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 22px;

        @include respond(lg) {
            grid-template-columns: 2fr 1fr 1fr;
        }
        

        & label {
            position: absolute;
            top: -6px;
            left: 11px;
            font-size: 10px;
            font-weight: normal;
            letter-spacing: 0.3px;
            color: $input-focus-color;
            opacity: 1;
            background-color: $input-background-color;
            pointer-events: all;
            padding-left: 3px;
            padding-right: 3px;
            font-family: sans-serif;
        }
    }

    #card-number-o {
        grid-column: 1 / -1;

        @include respond(lg) {
            grid-column: span 1;
        }
    }

    #card-expiry-o,
    #card-cvc-o {
        grid-column: span 1;
    }

    #card-number,
    #card-expiry,
    #card-cvc {
        padding-top: 15px;
    }

    #card-number-o,
    #card-expiry-o,
    #card-cvc-o {
        position: relative;
        background-color: $input-background-color;
        border: 1px solid $input-border-color;
        border-radius: 10px;
        transition: all 0.3s ease;
        // font-family: Poppins;
        height: 48px;
        padding: 0 14px;
    }

    .tsandcs-box {
        margin: 22px 0;
        height: 1px;
    }

    .tsandcs {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-size: 12px;
        font-weight: normal;
        color: $input-color;

        & span:first-child {
            position: absolute;
            top: 3px;

            & > a {
                font-weight: normal;
                color: $input-color;
                text-decoration: underline;
            }
        }

    }

        /* Hide the browser's default checkbox */
    .tsandcs input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

        /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 24px;
        width: 24px;
        background-color: transparent;
        border: 2px solid $input-icon-color;
        border-radius: 10px;

        &.invalid-input {
            border-color: $color-12;
        }
    }

        /* On mouse-over, add a grey background color */
    // .tsandcs:hover input ~ .checkmark {
    //     background-color: transparent;
    //     border: 2px solid $input-icon-color;
    //     border-radius: 10px;
    // }

        /* When the checkbox is checked, add a blue background */
    .tsandcs input:checked ~ .checkmark {
        background-color: transparent;
        border: 2px solid $input-icon-color;
        border-radius: 10px;
    }

        /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

        /* Show the checkmark when checked */
    .tsandcs input:checked ~ .checkmark:after {
        display: block;
    }

    .tsandcs input:checked ~ .checkmark {
        border: 2px solid $checkbox-active;
    }

        /* Style the checkmark/indicator */
    .container .checkmark:after {
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid $checkbox-active;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    #card-error {
        color: $color-12;
        font-size: 12px;
        margin: 10px 0;
        text-align: center;
    }

</style>

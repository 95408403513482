<template>
    <div v-click-outside="closeAll" class="dashboard-menu">
        <!-- <v-tour name="myTour" :steps="steps" :options="myOptions" :callbacks="callbacks"></v-tour> -->
        <!-- <div

            v-if="isThereLayout"
             class="header-icon" id="widget-step">
            <VueCustomTooltip label="Analysis Tools" position="is-bottom">
                <a @click="openModal('widget-shop')">
                    <font-awesome-icon :icon="['fas', 'plus']"/>
                </a>
            </VueCustomTooltip>
            <span
                    v-if="!layoutHasWidgets"
                    class="pointer-chevron widget"
            ><font-awesome-icon :icon="['fas', 'angle-double-up']"/></span>
        </div> -->
        <div v-if="demoPageMode || isAutoPilotMode || isExternalClientMode" class="db-menu-x">
            <div class="brand-logo analyst-desk" v-if="demoPageMode">
                ANALYST DESK
            </div>
            <div class="brand-logo analyst-desk" v-else-if="!isManagerMode">
                <dynamic-logo></dynamic-logo>
            </div>
            <div v-else-if="isUserBm"
            class="header-icon"
            style="margin-left: 0;"
            >
                <a href="javascript:void(0);"
                @click="navChange('home')">
                    <span class="header-icon-description">Analyst-Desk Demo <font-awesome-icon class="arrow" :icon="['fas', 'arrow-right']"/></span>
                </a>
            </div>
        </div>
        <div v-else>
            <div class="brand-logo" v-if="!excludeUsers">
                <img class="mobile-logo" src="../../../assets/fs-white-stacked-logo.png" />
                <img class="desktop-logo" src="../../../assets/fs-white-strap-logo.svg" />
            </div>
        </div>

        <div v-if="isUserBm && isManagerMode">
            <div>
                <div >
                    <div
                    class="header-icon"
                    :class="{'active-menu-item': activeMenuItem === 'clients' }"
                    >
                        <a href="javascript:void(0);"
                        @click="navChange('clients')">
                            <span class="header-icon-description">Quantum<span class="u-from-md"> Access</span> Controls</span>
                        </a>
                    </div>
                    <span class="from-xs-to-lg-spacer"></span>        
                    <div
                    class="header-icon"
                    :class="{'active-menu-item': activeMenuItem === 'autopilot' }"
                    >
                        <a href="javascript:void(0);"
                        @click="navChange('autopilot')">
                            <span class="header-icon-description">Auto-pilot<span class="u-from-md"> Controls</span></span>
                        </a>
                    </div>
                    <span class="from-xs-to-lg-spacer"></span>  
                    <div
                    class="header-icon"
                    :class="{'active-menu-item': activeMenuItem === 'team' }"
                    >
                        <a href="javascript:void(0);"
                        @click="navChange('team')">
                            <span class="header-icon-description">Team</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        
        <div v-else>
            <div>
                <div>
                    <div
                    v-if="isUserBm"
                    class="header-icon"
                    >
                        <a href="javascript:void(0);"
                        @click="navChange('clients')" style="color: #FC5A5A; font-weight: bold;">
                            <font-awesome-icon class="arrow" :icon="['fas', 'arrow-left']"/><span class="header-icon-description u-from-md"> Back to Portal</span>
                        </a>
                    </div>
                    <span v-if="isUserBm" class="from-xs-to-lg-spacer"></span>
                    <div
                    class="header-icon"
                    v-if="terminalMenu.includes('home')"
                    :class="{'active-menu-item': activeMenuItem === 'home' }"
                    >
                        <a href="javascript:void(0);"
                        @click="navChange('home')">
                            <font-awesome-icon :icon="['fas', 'home']"  /> <span class="header-icon-description u-from-md">Home</span>
                        </a>
                </div>
                <span class="from-xs-to-lg-spacer"></span>
                <div
                    v-if="showDashboards"
                    class="header-icon u-largescreen-only"
                    :class="{'active-menu-item': activeMenuItem === 'dashboard' }"
                    >
                        <a href="javascript:void(0);"
                        @click="navChange('dashboard')">
                            <font-awesome-icon :icon="['fas', 'tachometer-alt']"  /> <span class="header-icon-description u-from-md">Dashboards</span>
                        </a>
                </div>
                <div 
                class="header-icon u-smallscreen-only"
                :class="{'active-menu-item': activeMenuItem === 'dashboard' }"
                >
                        <a @click="openDashboardMenu"
                        >
                            <font-awesome-icon :icon="['fas', 'tachometer-alt']"/> <span class="header-icon-description u-from-md">Dashboards</span>
                        </a>
                    <div class="dropdown-menu-fs accounts" v-if="dashboardMenuIsOpen">
                        <div
                        class="dashboard-mini-menu"
                        >
                            <div
                            class="dashboard-mini-menu--menu dashboard"
                            >
                                <nav>
                                    <ul>
                                        <li v-for="mobileDashboard in groupedDashboards.mobileDashboards"
                                        :key="mobileDashboard.id">
                                            <a href="javascript:void(0)"
                                            @click="changeDashboard(mobileDashboard.id)">{{ mobileDashboard.name }}</a>
                                        </li>
                                        <li v-if="!isAutoPilotMode" style="border-top: 1px solid #383A42;">
                                            <a href="javascript:void(0);" @click.prevent="logout">Log Out</a>
                                        </li>
                                        <!-- <li v-if="isDev && !externalUser">
                                            <a href="javascript:void(0);" @click="navChange('research')">Research</a>
                                        </li> -->
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="header-icon" id="dashboard-step">
                    <VueCustomTooltip label="Dashboards" position="is-bottom">
                        <a @click="openDashboardMenu">
                            <font-awesome-icon :icon="['fas', 'th-large']"/>
                        </a>
                    </VueCustomTooltip>
                    <div class="dropdown-menu-fs dashboards" v-if="dashboardMenuIsOpen">
                        <nav>
                            <ul>
                                <template v-for="key in Object.keys(groupedDashboards)">
                                    <draggable v-model="groupedDashboards[key]" :key="key+'-group'" :class="'group-' + key">
                                        <transition-group>
                                            <li class="tablinks" v-for="dashboard in groupedDashboards[key]"
                                                :key="'dashboard-'+dashboard.id"
                                                :class="'dashboard-' + key"
                                                >
                                                <span>
                                                    <a href="javascript:void(0)" class="icon-link"
                                                    :class="{isDefault: dashboard.default}"
                                                    @click="makeDefaultDashboard(dashboard.id)"><font-awesome-icon
                                                            :icon="['fas', 'star']"/></a>
                                                </span>
                                                <span class="name-grid">
                                                    <a href="javascript:void(0)"
                                                    @click="changeDashboard(dashboard.id)">{{ dashboard.name }}</a>
                                                </span>
                                                <span v-if="!dashboard.readonly">
                                                    <a href="javascript:void(0)" class="icon-link"
                                                    @click="editDashboard(dashboard.id)"><font-awesome-icon
                                                            :icon="['fas', 'pencil-alt']"/></a>
                                                    <a href="javascript:void(0)" class="icon-link"
                                                    @click="deleteDashboard(dashboard.id)"><font-awesome-icon
                                                            :icon="['fas', 'trash-alt']"/></a>
                                                </span>
                                            </li>
                                        </transition-group>
                                    </draggable>
                                    <hr v-if="groupedDashboards[key].length > 0" :key="key">
                                </template>
                                <li
                                id="add-dashboard-step"><span style="width: 26px;"></span><a href="javascript:void(0)"
                                @click="openModal('edit-dashboard')">+ New Dashboard</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <span
                            v-if="!isThereLayout"
                            class="pointer-chevron dashboard"><font-awesome-icon :icon="['fas', 'angle-double-up']"/></span>
                </div> -->
                <!-- <div

                    class="header-icon" id="shop-step">
                    <VueCustomTooltip label="Shop" position="is-bottom">
                        <a @click="openShopModal">
                            <font-awesome-icon :icon="['fas', 'shopping-cart']"/>
                            <span class="badge" v-if="newProducts > 0">{{ newProducts }}</span>
                        </a>
                    </VueCustomTooltip>
                </div> -->
                <!-- display TC -->
                <span class="from-xs-to-lg-spacer"></span>
                <div v-if="terminalMenu.includes('videos')"
                    class="header-icon"
                    :class="{'active-menu-item': activeMenuItem === 'videos' }"
                    >
                        <a href="javascript:void(0);"
                        @click="navChange('videos')">
                            <font-awesome-icon :icon="['fas', 'tv']"  /> <span class="header-icon-description u-from-md">Training Centre</span>
                        </a>
                </div>
                <!-- <div
                class="header-icon u-largescreen-only" v-if="!isExploreMode"
                :class="{'active-menu-item': activeMenuItem === 'bug-report' }"
                >
                    <a @click="openBug()"
                    >
                        <font-awesome-icon :icon="['fas', 'bug']"/> <span class="header-icon-description">Bug Reports</span>
                    </a>
                </div> -->
                <!-- <div
                class="header-icon u-largescreen-only"
                :class="{'active-menu-item': activeMenuItem === 'notification' }"
                >
                    <a class="notification" @click="openAnnouncementsMenu" style="cursor: pointer"
                    >
                        <font-awesome-icon :icon="['fas', 'flag']"/> <span class="header-icon-description">Notifications</span>
                        <span class="badge" v-if="unreadAnnouncements">{{ unreadAnnouncements }}</span>
                    </a>
                    <div
                        class="dropdown-menu-fs" v-if="announcementsMenuIsOpen">
                        <nav>
                            <announcement-menu @close-announcements="closeAll"></announcement-menu>
                        </nav>
                    </div>
                </div> -->
                <!-- <span class="from-xs-to-lg-spacer"></span>
                <div v-if="isExternalClientMode && !isUserBm"
                class="header-icon"
                >
                    <a href="javascript:void(0);" @click.prevent="logout"><font-awesome-icon :icon="['fas', 'sign-out-alt']" class="adjust-font-icon" /> <span class="header-icon-description u-from-md">Log Out</span></a>
                </div> -->
                <div v-if="terminalMenu.includes('account') && !isManagerMode"
                class="header-icon account-item"
                :class="{'active-menu-item': activeMenuItem === 'account' }"
                >
                        <a @click="logout"
                        >
                            <!-- <span :data-update="currentUser.lastUpdate" v-if="currentUser.avatar" :key="loadedId">
                                <img :src="currentUser.avatar"
                                    style="margin-top: 10px;cursor:pointer;border-radius: 50%;width: 25px; height: 25px"
                                    alt="P"/>
                            </span> -->
                            <span v-if="isExternalClientMode || isAutoPilotMode"><font-awesome-icon :icon="['fas', 'cog']" /> <span class="header-icon-description u-from-md">Settings</span></span>
                            <span v-else><font-awesome-icon :icon="['fas', 'user-circle']" /> <span class="header-icon-description u-from-md">LogOut</span></span>
                            
                            
                        </a>
                    <div class="dropdown-menu-fs accounts" v-if="accountMenuIsOpen">
                        <div
                        class="dashboard-mini-menu"
                        >
                            <div
                            class="dashboard-mini-menu--menu account"
                            >
                                <nav>
                                    <ul>
                                        <li class="u-largescreen-only">
                                            <a href="javascript:void(0);" @click="openAccount">
                                                <span v-if="isExternalClientMode || isAutoPilotMode">Time zone</span>
                                                <span v-else>Account</span>
                                                
                                            </a>
                                        </li>
                                        <!-- <li class="u-largescreen-only" v-if="userHasPurchasedProducts">
                                            <a href="javascript:void(0);" @click="openFeatureRequestModal">Feature Request</a>
                                        </li> -->
                                        <!-- <li class="u-largescreen-only">
                                            <a href="javascript:void(0);" @click="openSupport">Support</a>
                                        </li> -->
                                        <!-- <li class="u-largescreen-only">
                                            <a href="javascript:void(0);" @click="openBug()">Bug Reports</a>
                                        </li> -->
                                        <li v-if="isDev">
                                            <a href="javascript:void(0);" @click="navChange('research')">Research</a>
                                        </li>
                                        <li v-if="!isAutoPilotMode">
                                            <a href="javascript:void(0);" @click.prevent="logout">Log Out</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <!-- <nav>
                            <ul>
                                <li><span class="name-grid"><a href="javascript:void(0)" @click="openAccount">Account</a></span>
                                </li>
                                <li v-if="userHasPurchasedProducts"><span class="name-grid"><a href="javascript:void(0)" @click="openFeatureRequestModal">Feature Request</a></span></li>
                                <li><span class="name-grid"><a href="javascript:void(0)" @click="openSupport">Support</a></span>
                                </li>
                                <li v-if="!isExploreMode && false"><span class="name-grid"><a href="javascript:void(0)"
                                                                                    @click.prevent="openTourGuide">App Tour</a></span>
                                </li>
                                <li v-if="!isExploreMode"><span class="name-grid"><a href="javascript:void(0)"
                                                                                    @click.prevent="logout">Log Out</a></span>
                                </li>
                            </ul>
                        </nav> -->
                    </div>
                </div>
                </div>

            </div>
        </div>
        
        <div v-if="!isUserBm">
            <div>
                <div class="header-icon">
                    <squawk-unit
                    v-if="subscribedToSquawk && squawkAvailable && squawkOnDemo"
                    ></squawk-unit>
                </div>
            </div>
            
        </div>
        <div v-else style="display: block; position: relative; cursor: pointer;" class="">
            
            <a @click="openManagerAccountMenu" class="partner-account-link-in-header with-inital"
            >
                <div style="display: flex; align-items: center;">
                <span class="account-icon-fs-br"
                :style="{backgroundColor: initalColors.background, color: initalColors.text}">
                    {{ initial }}
                </span>
                <span class="header-icon-description u-from-lg">Account</span>
                </div>
            </a>
            
            <div style="position: absolute; right: 120px;" class="dropdown-menu-fs accounts" v-if="managerAccountMenuIsOpen">
                <div
                class="dashboard-mini-menu"
                >
                    <div
                    class="dashboard-mini-menu--menu account"
                    >
                        <nav>
                            <ul>
                                <li>
                                    <a href="javascript:void(0);" @click.prevent="logout">Log Out</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                
            </div>
        </div>

    </div>
</template>

<script>
import {eventBus} from '../../main';
import Swal from 'sweetalert2';
import FeatureRequestList from "../../pages/featurerequest/FeatureRequestList";
// import draggable from 'vuedraggable'
import {createNamespacedHelpers} from 'vuex';
import _ from "lodash"
// import AnnouncementMenu from "./announcement/AnnouncementMenu";
import SquawkUnit from "../widgets/components/SquawkUnit.vue";
import {ExplorerMode} from "../../directives/explorer-mode";
import ShopModal from "./shop/ShopModal";
import { isAutoPilotMode, isDevlServer, isExternalClientMode, isLocalHost, isMobileForDashboards } from '../../utility/helper-functions';
import DynamicLogo from './external/DynamicLogo.vue';

const dashboards = createNamespacedHelpers('dashboard');
const announcements = createNamespacedHelpers('announcements');
const general = createNamespacedHelpers('general');
const payments = createNamespacedHelpers('payments');
const squawk = createNamespacedHelpers('squawk');
const widgets = createNamespacedHelpers('widget');

export default {
    components: {SquawkUnit, DynamicLogo},
    emits: ['logout-successful'],
    props: ["layoutHasWidgets", "currentComp"],
    directives: {ExplorerMode},
    data() {
        return {
            isExploreMode: window.bootUp.isExploreMode,
            dashboardMenuIsOpen: false,
            announcementsMenuIsOpen: false,
            accountMenuIsOpen: false,
            managerAccountMenuIsOpen: false,
            currentUser: {},
            loadedId: 0,
            path: this.$router.currentRoute.path,
            bugReportsIsOpen: false,
            qnaState: 'on'
        }
    },
    watch: {
        user: {
            deep: true,
            handler: function () {
                this.currentUser = {};
                this.loadedId++;
                this.currentUser = _.cloneDeep(this.user);
                this.loadedId++;
            }
        },
        $route (to){
            this.path = to.path
            this.closeAll();
        }

        // dashboards(nVal, oVal) {
        //     if (oVal.length === 0 && nVal[0]) {
        //         this.makeDefaultDashboard(nVal[0].id, false);
        //     }
        // }
    },
    computed: {
        ...general.mapState(['user', 'activeNavItem', 'componentAfterRefresh', 'demoPageMode', 'externalUser']),
        ...general.mapGetters(['terminalMenu', 'isUserBm', 'entity', 'widgetsChoiceOnDemo', 'externalUserAutoLogin']),
        ...dashboards.mapState(['activeDashboard']),
        ...announcements.mapGetters(['unreadAnnouncements']),
        ...payments.mapGetters(['newProducts', 'userHasPurchasedProducts', 'availableWidgets']),
        ...dashboards.mapGetters(['defaultDashboards', 'dashboards', 'layouts', 'customDashboards', 'systemDashboards', 'getDashboardById', 'isThereLayoutByInstanceID', 'tabbedDashboards']),
        ...squawk.mapGetters(['subscribedToSquawk', 'squawkAvailable', 'squawkOnDemo']),
        ...widgets.mapGetters(['hasTCWidgets', 'getWidgetImpIDByCode']),
        isExternalClientMode() {
            return isExternalClientMode();
        },
        isAutoPilotMode() {
            return isAutoPilotMode();
        },
        isDev() {
            return isDevlServer() || isLocalHost();
        },
        isManagerMode() {
            return this.activeMenuItem === 'clients' || this.activeMenuItem === 'team' || this.activeMenuItem === 'autopilot';
        },
        showDashboards() {
            if(this.demoPageMode)
                return this.terminalMenu.includes('dashboard');

            return this.tabbedDashboards.length;
        },
        isThereLayout() {
            return this.isThereLayoutByInstanceID(this.$root.instanceID);
        },
        groupedDashboards: function () {
            return {
                defaultDashboards: this.defaultDashboards,
                customDashboards: this.customDashboards,
                // systemDashboards: this.systemDashboards.filter(el => !el.mobile),
                // mobileDashboards: _.orderBy(this.systemDashboards.filter(el => el.mobile), dashboard => dashboard.orderBy, ["desc"])
                systemDashboards: this.systemDashboards.filter(el => !el.mobile && !el.unique_id && !el.vidpage),
                showcaseDashboards: this.systemDashboards.filter(el => !el.mobile && el.unique_id && !el.vidpage),
                mobileDashboards: _.orderBy(this.systemDashboards.filter(el => el.mobile), dashboard => dashboard.orderBy, ["desc"]),
                vidpageDashboards: this.systemDashboards.filter(el => el.vidpage)

            }
        },
        activeMenuItem() {


            if(this.accountMenuIsOpen)
                return "account";
            else if(this.announcementsMenuIsOpen)
                return "notification";
            else if(this.bugReportsIsOpen)
                return "bug-report";
            else
                return this.componentAfterRefresh || this.activeNavItem;
        },
        // displayTC() {
        //     return true;//this.hasTCWidgets;
        // },
        excludeUsers() {
            return this.user.is_analyst;
        },
        initial() {
            return this.user?.name?.substring(0,1).toLocaleLowerCase();
        },
        initalColors() {
            if(this.initial < "d")
                return {background: "#B33C22", text: "#FFFFFF"}
            else if(this.initial < "g")
                return {background: "#FFFFFF", text: "#1C1C1C"}
            else if(this.initial < "k")
                return {background: "#3B9C83", text: "#FFFFFF"}
            else if(this.initial < "o")
                return {background: "#F178B6", text: "#FFFFFF"}
            else if(this.initial < "s")
                return {background: "#FE8001", text: "#FFFFFF"}
            else
                return {background: "#2D9CDB", text: "#FFFFFF"}
        }
    },
    created() {
        this.fetchAnnouncements();
        this.currentUser = _.cloneDeep(this.user);
        eventBus.$on("bug-report-closed", () => this.bugReportsIsOpen = false);
    },
    methods: {
        ...general.mapActions(['setComponentAfterRefresh', 'setEntity', 'setWidgetsChoiceOnDemo']),
        ...dashboards.mapActions(['setSystemDashboards', 'updateDashboardById', 'deleteDashboardById', 'setActiveDashboardAndTab', 'setActiveDashboard']),
        ...announcements.mapActions(['fetchAnnouncements']),
        updateDashboardPrecedence: function ({newIndex, oldIndex}) {
            console.log("newIndex - " + newIndex)
            console.log("oldIndex - " + oldIndex)
        },
        openModal(comp, id = null) {
            this.closeAll();
            const modal = {};

            if (comp === "widget-shop") {
                // modal.title = "Widget Shop";
                modal.instanceID = this.$root.instanceID;
                modal.header = false;
                modal.componentInModal = "widget-shop";
                modal.panel = {
                    view: "widget-list",
                    mode: "create",
                    addingMode: true

                };
                modal.mode = "wide";

            } else if (comp === "edit-dashboard") {
                if (id) {
                    modal.title = "Edit Dashboard";
                    modal.id = id;
                } else {
                    modal.title = "Create a New Dashboard";
                    // this.$tours['myTour'].nextStep();
                }

                modal.componentInModal = "edit-dashboard";
            }

            // const body = document.getElementsByTagName("body");
            // for (const b of body) {
            //     // console.log(b);
            //     b.style.overflow = "hidden";
            // }

            eventBus.$emit('open-modal', modal);

        },
        closeAll() {
            this.announcementsMenuIsOpen = false;
            this.dashboardMenuIsOpen = false;
            this.accountMenuIsOpen = false;
            this.managerAccountMenuIsOpen = false;
            this.bugReportsIsOpen = false;
        },
        openDashboardMenu() {
            if(!isMobileForDashboards() && !this.systemDashboards.length)
                Swal.fire({
                    title: 'No Dashboards',
                    html: 'You don\'t have access to any widgets. Please log in on a laptop or desktop computer to upgrade your membership.',
                    type: 'info',
                    confirmButtonColor: '##1d9deb',
                    confirmButtonText: 'OK'
                    // timer: 1500
                });

            if (this.isExploreMode) {
                Swal.fire({
                    customClass: {
                        cancelButton: 'flat'
                    },
                    title: 'Create Free Account',
                    type: 'info',
                    text: 'You’ll be able to create unlimited dashboards to build your perfect trading set up.',
                    showCancelButton: true,
                    cancelButtonText: 'No thanks',
                    confirmButtonColor: '#1d9deb',
                    confirmButtonText: 'Create Account'
                }).then((result) => {
                    if (result.value) {
                        eventBus.$emit('close-modal');
                        this.$store.dispatch('auth/setLoginFormMode', 'register');
                        eventBus.$emit('logout-successful');
                    }
                });

                return;
            } else {
                this.accountMenuIsOpen = false;
                this.managerAccountMenuIsOpen = false;
                this.announcementsMenuIsOpen = false;
                this.dashboardMenuIsOpen = !this.dashboardMenuIsOpen;
                // this.$tours['myTour'].nextStep();
            }

        },
        openAccountMenu() {
            this.closeAll();
            this.accountMenuIsOpen = !this.accountMenuIsOpen;
          
        },
        openManagerAccountMenu() {
            this.closeAll();
            this.managerAccountMenuIsOpen = !this.managerAccountMenuIsOpen;
        },
        openAnnouncementsMenu() {
            this.closeAll();
            this.announcementsMenuIsOpen = !this.announcementsMenuIsOpen;
        },
        openBug() {
            this.closeAll();
            this.bugReportsIsOpen = true;
            eventBus.$emit('open-modal', {
                instanceID : this.$root.instanceID,
                classes: "terminal-modal simplified",
                componentInModal: "bug-report",
                mode: "wide",

            });
        },
        openAccount() {
            if (this.isExploreMode) {
                Swal.fire({
                    customClass: {
                        cancelButton: 'flat'
                    },
                    title: 'Create Free Account',
                    type: 'info',
                    text: 'You’ll be able to customise your account and trial our products.',
                    showCancelButton: true,
                    cancelButtonText: 'No thanks',
                    confirmButtonColor: '#1d9deb',
                    confirmButtonText: 'Create Account'
                }).then((result) => {
                    if (result.value) {
                        eventBus.$emit('close-modal');
                        this.$store.dispatch('auth/setLoginFormMode', 'register');
                        eventBus.$emit('logout-successful');
                    }
                });

            } else {
                this.navChange('account');
            }
        },
        openSupport() {
            eventBus.$emit('open-modal', {
                instanceID: this.$root.instanceID,
                componentInModal: "terminal-support",
                classes: "terminal-modal simplified auto-height",
                mode: "wide",
            });
        },
        logout() {
            Swal.fire({
                title: 'Are you sure?',
                type: 'warning',
                text: 'Do you want to log out?',
                showCancelButton: true,
                cancelButtonColor: '#1d9deb',
                confirmButtonColor: '#ff5656',
                confirmButtonText: 'Log out'
            }).then((result) => {
                if (result.value) {

                    this.closeAll();
                    eventBus.$emit('logout-successful');
                }
            });

        },
        navChange(comp) {
            if(this.componentAfterRefresh === 'videos' && comp === 'videos') {
                eventBus.$emit("back-to-training-centre");
                return;
            }
                


            eventBus.$emit("nav-change", {
                instanceID: this.$root.instanceID,
                comp: comp
            });
            this.closeAll();
        },
        refreshDashboardMenu() {
        },
        changeDashboard(id) {
            // if(this.currentComp !== "dashboard-component")
                this.navChange('dashboard');
            this.activeMenuItem === "dashboard";
            this.$store.dispatch('dashboard/setActiveDashboardAndTab', {
                id: id,
                instanceID: this.$root.instanceID
            });


            // const poppedOut = this.$store.getters['dashboard/getPoppedoutDashboardByID'](id);
            // if (poppedOut && poppedOut.window && !poppedOut.window.closed) { //
            //     poppedOut.window.focus();
            // } else {
            //     this.$store.dispatch('dashboard/setActiveDashboardAndTab', {
            //         id: id,
            //         instanceID: this.$root.instanceID
            //     });
            // }

            this.closeAll();
        },
        editDashboard(id) {
            this.openModal('edit-dashboard', id);
        },
        deleteDashboard(id) {

            Swal.fire({
                title: 'Are you sure?',
                type: 'warning',
                text: 'You\'ll need to create a new dashboard later.',
                showCancelButton: true,
                cancelButtonColor: '#1d9deb',
                confirmButtonColor: '#ff5656',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {

                    // console.log('delete',id);
                    const deleted = this.$store.getters['dashboard/getPoppedoutDashboardByID'](id);

                    const defaultID = this.defaultDashboards[0]?.id;

                    if (deleted)
                        deleted.window.close();

                    this.$store.dispatch('dashboard/removeDashboard', id);
                    this.deleteDashboardById({
                        id: id,
                        instanceID: this.$root.instanceID
                    });

                    if (defaultID === id &&
                        this.dashboards.length > 0) {
                        this.makeDefaultDashboard(this.dashboards[0].id, false);
                    } else if(this.dashboards?.length === 0) {
                        this.setActiveDashboard({
                            target: "dashboard",
                            id: -1,
                            instanceID: this.$root.instanceID
                        });
                    }

                    this.closeAll();
                }
            });

        },
        makeDefaultDashboard(id, notify = true) {
            let dashboards = _.cloneDeep(this.dashboards);
            this.closeAll();
            if (dashboards) {

                dashboards.forEach(dashboard => {
                    if (dashboard.id === id) {
                        dashboard.default = true;
                        dashboard.tab = true;
                        const text = "Default dashboard is now <strong>" + dashboard.name + ".</strong>";

                        if (notify)
                            Swal.fire({
                                title: 'Done',
                                html: text,
                                type: 'info',
                                confirmButtonColor: '##1d9deb',
                                confirmButtonText: 'OK'
                                // timer: 1500
                            });

                    } else {
                        dashboard.default = false;
                    }
                })
            }
            this.setSystemDashboards(dashboards);
            this.setActiveDashboardAndTab({
                id: id,
                instanceID: this.$root.instanceID
            });
        },
        openFeatureRequestModal() {
            this.showModal(FeatureRequestList)
        },
        openShopModal() {
            this.showModal(ShopModal);
        },
        // switchQna() {
        //     let entity = _.cloneDeep(this.entity);
        //     const analChatImpID = this.getWidgetImpIDByCode('ANALCHAT');
        //     if(!this.widgetsChoiceOnDemo.includes(analChatImpID))
        //         this.setWidgetsChoiceOnDemo([...this.widgetsChoiceOnDemo, analChatImpID]);

        //     if(this.qnaState === 'on') {
        //         this.qnaState = 'off';
        //         this.setWidgetsChoiceOnDemo(this.widgetsChoiceOnDemo.filter(el => el !== analChatImpID));
        //     }else if(this.qnaState === 'off') {
        //         this.qnaState = 'custom';
        //     }else {
        //         this.qnaState = 'on';
        //     }
        //     entity = {
        //         ...entity,
        //         broker_qanda_enabled: this.qnaState

        //     }
        //     this.setEntity(entity);
        //     eventBus.$emit("widget-selection-change");
        // }
    }
}
</script>

<style lang="scss" scoped>

.dashboard-menu {
  width: 100%;
  text-align: right;
  display: flex;
  align-items: center;

  & a svg {
      font-size: 12px;

      @include respond(md) {
          font-size: 16px;
      }

      &.adjust-font-icon {
        font-size: 14px;
        @include respond(md) {
          font-size: 19px;
      }
      }
  }
  
  & > div:first-child {
      display: flex;
      justify-content: center;
    flex: 1;
  }
  & > div:last-child {
      display: flex;
      justify-content: center;
    flex: 1;
  }

  & > div:first-child > div {
      margin-right: auto;
  }
  & > div:last-child > div {
      margin-left: auto;
  }
}

.brand-logo {
    text-align: left;

    & .desktop-logo {
        display: none;
        width: 60%;

        @include respond(md) {
            display: inline-block;
        }
    }

    & .mobile-logo {
        height: 30px;
        margin-left: 10px;

        @include respond(md) {
            display: none;
        }
    }

    &.analyst-desk {

        font-size: 12px;
        line-height: 27.6px;
        // & .desktop-logo {
        //     display: none;
        //     width: 60%;

        //     @include respond(lg) {
        //         display: inline-block;
        //     }
        // }

        // & .mobile-logo {
        //     height: 25px;
        //     width: 25px;
        //     margin-left: 10px;
        //     display: inline-block;

        //     @include respond(lg) {
        //         display: none;
        //     }
        // }
    }
    
}
.header-icon {
  display: inline-block;
  position: relative;

    margin-left: 5vw;
    margin-right: 5vw;
    padding-bottom: 2px;

    @include respond(xs) {
        margin-left: 6px;
        margin-right: 6px;
    }
    @include respond(xl) {
        margin-left: 18px;
        margin-right: 18px;
    }

    border-bottom: 2px solid transparent;

    &.account-item {
        display: none;

        @include respond(lg) {
            display: inline-block;
        }
    }

    // &:first-child,
    // &:last-child {
    //     margin: 0;
    // }
}

.header-icon a {
  cursor: pointer;
}

.header-icon-description {
    font-size: 10px;

    @include respond(lg) {
        font-size: 13px;
    }
    @include respond(xl) {
        font-size: 16px;
    }

    margin-left: 3px;
}

div a {
  background: transparent;
  color: #FFFFFF;//$color-20;
  border: none;
  white-space: nowrap;
  text-decoration: none;
}

a.icon-link {
  display: inline-block;
}

a.icon-link:first-child {
  margin: 0 5px;
}

a.icon-link:not(:first-child) {
  margin-left: 7px;
}

a.icon-link:last-child {
  margin-right: 7px;
}

a.icon-link svg {
  font-size: 15px;
  color: $color-02;
  transition: color 0.2s ease;
}

a svg {
  font-size: 16px;
}

// .dropdown-menu {
//   position: absolute;
//   background: $color-021;
//   z-index: 1;
//   border: thin;
//   right: -26px;
//   top: 45px;
//   box-shadow: 0 0 20px #000000;
//   padding: 7px;

//   @include respond(lg) {
//     right: -5px;
//   }
// }

// .dropdown-menu nav ul {
//   padding: 0;
// }

// .dropdown-menu nav ul li {
//   list-style: none;
//   white-space: nowrap;
//   display: flex;
//   text-align: left;
//   -webkit-touch-callout: none; /* iOS Safari */
//     -webkit-user-select: none; /* Safari */
//      -khtml-user-select: none; /* Konqueror HTML */
//        -moz-user-select: none; /* Old versions of Firefox */
//         -ms-user-select: none; /* Internet Explorer/Edge */
//             user-select: none; /* Non-prefixed version, currently
//                                   supported by Chrome, Edge, Opera and Firefox */
// }

// .name-grid {
//     pointer-events: none;

//     @include respond(lg) {
//         pointer-events: all;
//     }

//     & a {
//         pointer-events: all;
//     }
// }

// .name-grid a,
// a.icon-link {
//     display: inline-block;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;

//     @include respond(lg) {
//         transition: background-color 0.2s ease;
//     }


//     //Poppins
//     padding: 8px 0px;

//     //Roboto
//     // padding: 10px 0px;
// }

// .name-grid a {
//     padding-left: 7px;
//     padding-right: 7px;

//     @include respond(lg) {
//         padding-left: 0;
//         padding-right: 0;
//     }

// }

// .dashboards .name-grid a,
// #add-dashboard-step a {
//     width: 230px;

//     @include respond(lg) {
//         width: 295px;
//     }


// }

// .accounts .name-grid a {
//     width: 155px;
//     padding-left: 7px;
//     padding-right: 7px;
// }

// .dropdown-menu nav ul a.icon-link.isDefault svg {
//   color: $color-20 !important;
// }

// @include respond(lg) {
//     .dropdown-menu nav ul li:hover {
//     background-color: $color-03;
//     }

//     .dropdown-menu nav ul li:hover a.icon-link svg {
//     color: $color-05;
//     }

//     .dropdown-menu nav ul li:hover a.icon-link svg:hover {
//     color: $color-20;
//     }

// }

#add-dashboard-step a {
    padding: 10px 0;
}

.pointer-chevron {
  position: absolute;
  top: 80px;
  font-size: 50px;
  animation: bounceUpAndDown 1.5s 8;
  animation-timing-function: ease;
  right: -5px;

   @include respond(lg) {
    right: -5px;
  }

  &.dashboard {
    color: $color-159;
  }

  &.widget {

    color: $color-135;
  }


}

.active-menu-item {
    border-color: $checkbox-active;

    & svg {
        color: $checkbox-active;
    }
}

.v-step {
  z-index: 10 !important;
}
.partner-account-link-in-header.with-inital {
    display: inline-block;
    border: 1px solid $partner-border-4;
    background-color: $onboard-survey-background;
    border-radius: 43px;
    padding: 3px;
    margin-right: 10px;

    @include respond(sm) {
        margin-right: 0;
    }


    

    & .header-icon-description {
        padding: 0 10px;
    }
}
</style>

<style lang="scss" scoped>
    //mobile settings
    .from-xs-to-lg-spacer {
        display: none;

        @include respond(xs) {
            display: inline-block;
            width: 15px;
        }

        @include respond(lg) {
            display: none;
        }
    }
    @media only screen and (max-width: 992px) {
        .group-customDashboards,
        .group-systemDashboards,
        .group-defaultDashboards {
            display: none;

        }

        .group-mobileDashboards .icon-link {
            display: none;
        }

        hr {
            display: none;
        }

        #add-dashboard-step {
            display: none;
        }
     }
     @media only screen and (min-width: 993px) {
        .group-mobileDashboards,
        .group-mobileDashboards+hr {
            display: none;
        }

     }
</style>

<style lang="scss">

.account-icon-fs-br {
        height: 32px;
        display: inline-block;
        width: 32px;
        text-align: center;
        background-color: red;
        border-radius: 32px;
        font-size: 24px;
        line-height: 32px;
        font-weight: 300;
        text-transform: uppercase;
    }
</style>
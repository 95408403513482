<template>
    <component
    :is="componentToBeRendered"
    :details="details">
    </component>
</template>

<script>
import InterestRateEmbed from './InterestRateEmbed.vue';
// import findComponent from '../../mixins/componentToBeRendered';
import InterestRate from './InterestRate.vue';

export default {
    props: ["details"],
    // mixins: [findComponent],
    components: {
        InterestRate,
        InterestRateEmbed
    },
    computed: {
        componentToBeRendered() {
            const style = window.bootUp.user?.style;
            
            if(style === 'chart')
                return "interest-rate-embed";
            
            return "interest-rate";

        },
    }
}
</script>

